<template>
    <ModalSide class="product-modal" ref="modal">
        <Scrollbar :key="refrash_key">
            <header>
                <div class="title">{{ user_info.has ? 'Product Performance by Creator' : 'Product Performance' }}
                    <span v-if="user_info.has">
                        <figure class="avatar">
                            <img :src="getUserImage(user_info.data.userProfile.avatar_full_url)" />
                        </figure>
                        {{ user_info.data.first_name }} {{ user_info.data.last_name }}
                    </span>
                </div>

                <div class="icon-box">
                    <a class="icon i-1" v-if="product" target="_blank" :href="product.url"
                        v-tippy="{ content: 'Open product source in new tab', delay: [500, 20] }">View Product
                        <SvgIcon name="share" />
                    </a>
                    <div class="icon i-2"
                        v-tippy="{ content: 'Download product report summaries in CSV format', placement: 'right', delay: [500, 20] }">
                        <SvgIcon name="download2" />
                    </div>
                    <div class="icon i-3" @click="close">
                        <SvgIcon name="close" />
                    </div>
                </div>
            </header>

            <div class="box" v-if="product">
                <div class="product-info-box">
                    <figure class="image-wrap">
                        <img class="product-img" :src="require('@/assets/images/products/' + product.image_url)" />
                    </figure>
                    <div class="product-info">
                        <div class="product-flex-box">
                            <div>
                                <div class="product-brand"
                                    v-html="product && product.brand_name ? product.brand_name : ''"></div>
                                <div class="product-name" v-html="product.name"></div>
                            </div>
                            <div class="product-btn" @click="goFullReport" v-if="user_info.has">Full Product Report
                            </div>
                        </div>
                        <div class="product-data-box">
                            <div class="product-data">
                                <div class="text">Price</div>
                                <div class="value">${{ product.on_sale === 1 ? formatMoney(product.sale_price) :
            formatMoney(product.price) }}</div>
                            </div>
                            <div class="product-data">
                                <div class="text">Category</div>
                                <div class="value">Man</div>
                            </div>
                            <div class="product-data">
                                <div class="text">Availability</div>
                                <div class="value">In stock</div>
                            </div>
                            <div class="product-data" v-if="user_info.has">
                                <div class="text">Added Date</div>
                                <div class="value">{{ $filters.momentFormat(product.created_at, 'll') }}</div>
                            </div>
                            <div class="product-data" v-if="user_info.has">
                                <div class="text">Late Update</div>
                                <div class="value"> {{ $filters.momentFormat(product.updated_at, 'll') }}</div>
                            </div>
                            <!-- <div class="report">Report</div> -->
                        </div>
                    </div>
                </div>
                <!---------------------------- chart ------------------------------------->
                <StorefrontSummary :show_date_picker="true"
                    :user_id="user_info.data && user_info.data.id ? user_info.data.id : ''"
                    :date_range="date_range_summary" :product_id="product.id" :title="summary_title" />
                <!---------------------------- for all ----------------------------------->
                <div v-if="!user_info.has">
                    <div class="report-header">
                        <div class="left">
                            <div class="title">Creators ({{ data.length }})</div>
                            <FilterDropdown :params="params" :disabled="is_empty_data" @update-filter="updateFilter" />
                        </div>
                        <div class="ui-search">
                            <Search @onenter="search" :disabled="is_empty_data" />
                        </div>
                        <div class="right">
                            <DatePicker :disabled="is_empty_data" />
                            <Dropdown :items="select_pagenum" :disabled="is_empty_data"
                                @update-dropdown="updatePagenum" />
                            <Pagenavi :disabled="is_empty_data" />
                        </div>
                    </div>
                    <div class="report-box">
                        <TopBox v-if="top_views" :data="{
                            title: 'Top Product Views',
                            user: top_views,
                            icon: 'eye'
                        }" />

                        <TopBox v-if="top_clicks" :data="{
                            title: 'Top Clicks',
                            user: top_clicks,
                            icon: 'click'
                        }" />

                        <TopBox v-if="top_sales"  :data="{
                            title: 'Top Sales',
                            user: top_sales,
                            icon: 'pig'
                        }" />

                        <TopBox v-if="top_sales_value"  :data="{
                            title: 'Top Sales Value',
                            user: top_sales_value,
                            icon: 'shopping-cart'
                        }" />

                    </div>
                    <div>
                        <FilterResults :params="params" @update-filter="updateFilter" />
                    </div>
                    <!-- table -->
                    <Loading class="static" v-if="is_loading" />
                    <Scrollbar class="data-list" v-else>
                        <Table :style="top" :class="{ 'is-loading': is_loading_data }">
                            <template #head>
                                <tr>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'name' && sort_val === 'asc' }, { 'desc': sort_key === 'name' && sort_val === 'desc' }]"
                                            @click="sort('name')">
                                            <span>Name</span>
                                            <i>
                                                <SvgIcon name="sort" />
                                            </i>
                                        </div>
                                    </th>
                                    <th><span>Socials</span></th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'anchor_status' && sort_val === 'asc' }, { 'desc': sort_key === 'anchor_status' && sort_val === 'desc' }]"
                                            @click="sort('anchor_status')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="This is the current status of each creator's storefront with the date that status updated. Please hover over the status to learn more.​">
                                                <span>Status</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'views' && sort_val === 'asc' }, { 'desc': sort_key === 'views' && sort_val === 'desc' }]"
                                            @click="sort('views')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="Cumulative product views for the time period selected.​">
                                                <span>Product Views</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'click' && sort_val === 'asc' }, { 'desc': sort_key === 'click' && sort_val === 'desc' }]"
                                            @click="sort('clicks')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="Total product link clicks for the time period selected.​">
                                                <span>Clicks</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'sales' && sort_val === 'asc' }, { 'desc': sort_key === 'sales' && sort_val === 'desc' }]"
                                            @click="sort('sales')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="Sales driven by the storefront for the time period selected.​">
                                                <span>Sales</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'sales_value' && sort_val === 'asc' }, { 'desc': sort_key === 'sales_value' && sort_val === 'desc' }]"
                                            @click="sort('sales_value')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="Gross transaction value of sales driven by storefront in the time period selected.​">
                                                <span>Sales Value</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'add_date' && sort_val === 'asc' }, { 'desc': sort_key === 'add_date' && sort_val === 'desc' }]"
                                            @click="sort('add_date')">
                                            <tippy :delay="[0, 20]" class="flex"
                                                content="Date this product was added to the creator's storefront.">
                                                <span>Added</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico question sort"
                                            :class="[{ 'asc': sort_key === 'last_update_date' && sort_val === 'asc' }, { 'desc': sort_key === 'last_update_date' && sort_val === 'desc' }]">
                                            <tippy :delay="[0, 20]"
                                                content="This date will change when a creator adds or edits products on their storefront."
                                                class="flex question">
                                                <span @click="sort('last_update_date')">Last Update</span>
                                                <i @click="sort('last_update_date')">
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                </tr>
                            </template>
                            <template #body>
                                <template v-if="!is_loading && data.length === 0">
                                    <tr>
                                        <td colspan="10">
                                            <NoData />
                                        </td>
                                    </tr>
                                </template>
                                <tr v-else v-for="(row, index) in data" :key="index"
                                    :class="{ 'selected': row.is_selected, 'tbody-tr': true, }"
                                    @click.stop="pickUser(row)">
                                    <td class="name">
                                        <div class="profile">
                                            <figure class="avatar">
                                                <img :src="getUserImage(row.avatar)" />
                                            </figure>
               
                                            <div class="name-text-box">
                                                <tippy :content="showFullText(row, 'name')"
                                                    @mouseover="isEllipsisActive($event, row)" class="name">
                                                    <strong class="text-ellipsis"
                                                        v-html="row.first_name + ' ' + row.last_name"></strong>
                                                </tippy>
                                                <div class="email-box"
                                                    v-if="$store.state.user_data.is_motom_internal_user">
                                                    <svg-icon name="email" />
                                                    <tippy :content="showFullText(row, 'email')"
                                                        @mouseover="isEllipsisActive($event, row)">
                                                        <span v-if="row.email" class="link">
                                                            <span class="text-ellipsis"
                                                                @click.stop="emailto(row.email)">{{ row.email
                                                                }}</span></span>
                                                        <span class="link" v-else> ---</span><br>
                                                    </tippy>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="social">
                                        <tippy :content="showFullText(row, 'tt')"
                                            @mouseover="isEllipsisActive($event, row)"
                                            @click.stop="GoMedia('tk', row.tiktok_handle)">
                                            <span v-if="row.tiktok_handle"
                                                class="text-ellipsis link text-ellipsis-link">
                                                <SvgIcon name="tiktok-pure" /> {{ row.tiktok_handle }} <b
                                                    v-if="row.is_tiktok_handle_updated">Updated</b>
                                            </span>
                                            <span v-else>
                                                <SvgIcon name="tiktok-pure" /> ---
                                            </span><br>
                                        </tippy>
                                        <tippy :content="showFullText(row, 'ins')"
                                            @mouseover="isEllipsisActive($event, row)"
                                            @click.stop="GoMedia('ig', row.instagram_handle)">
                                            <span v-if="row.instagram_handle"
                                                class="text-ellipsis link text-ellipsis-link">
                                                <SvgIcon name="instagram" /> @{{ row.instagram_handle }} <b
                                                    v-if="row.is_instagram_handle_updated">Updated</b>
                                            </span>
                                            <span v-else>
                                                <SvgIcon name="instagram" /> ---
                                            </span>
                                        </tippy>
                                    </td>

                                    <td>
                                        <tippy :delay="[0, 20]" class="storefront-state"
                                            :content="showStageText(row.anchor_page_status, $filters.momentFormat(row.show_status_time, 'll'))"
                                            :class="['class-' + row.anchor_page_status]">
                                            {{ row.anchor_page_status_text }}
                                        </tippy>
                                        <p class="status_text_time">{{ $filters.momentFormat(row.show_status_time, 'll')
                                            }}</p>
                                    </td>
                                    <td>
                                        <span v-if="row.total_product_views">{{ row.total_product_views.toLocaleString('en-US') }}</span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        <span v-if="row.total_product_clicks">{{ row.total_product_clicks.toLocaleString('en-US') }}</span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        <span v-if="row.total_product_sales">{{ row.total_product_sales.toLocaleString('en-US') }}</span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        <span v-if="row.total_product_sales_value">${{ row.total_product_sales_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        {{ $filters.momentFormat(row.datetime, 'll') }}
                                    </td>
                                    <td>
                                        {{ $filters.momentFormat(row.datetime, 'll') }}
                                    </td>
                                </tr>
                            </template>
                            <template #foot v-if="data.length > 0">
                                <tr>
                                    <td colspan="2">Total {{ data.length }}</td>

                                    <td>{{ data.filter(item => item.anchor_page_status_text === 'Active').length }}
                                        Active
                                        <br>
                                        {{ data.filter(item => item.anchor_page_status_text === 'Disabled').length }}
                                        Disabled
                                    </td>
                                    <td>{{ formatNumber(total_views) }}</td>
                                    <td>{{ formatNumber(total_clicks) }}</td>
                                    <td>{{ formatNumber(total_sales) }}</td>
                                    <td>${{ formatMoney(total_sales_value) }}</td>
                                    <td colspan="2"></td>
                                </tr>
                            </template>
                        </Table>
                    </Scrollbar>
                </div>
            </div>
        </Scrollbar>
    </ModalSide>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Loading from '@/components/ui/Loading.vue';
import Table from '@/components/ui/Table.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import NoData from '@/components/creator/NoData.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import Search from '@/components/ui/Search.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import FilterResults from '@/components/FilterResults.vue';
import Pagenavi from '@/components/ui/Pagenavi.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import TopBox from '@/components/product/TopBox.vue';
import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import { goMedia } from '@/utils/goMedia';
import { showStageText, makeStatusTime } from '@/utils/ShareEvent';

export default {
    name: 'ProductModalSide',

    components: {
        SvgIcon,
        ModalSide,
        Loading,
        Table,
        Scrollbar,
        NoData,
        DatePicker,
        Search,
        Dropdown,
        FilterDropdown,
        FilterResults,
        Pagenavi,
        TopBox,
        StorefrontSummary
    },

    data() {
        return {
            sort_key: '',
            sort_val: 'asc',
            product: null,
            data: [],
            top: '--top: 0px',
            is_loading: true,
            is_loading_data: false,
            search_keyword: '',
            top_views: false,
            top_sales: false,
            top_clicks: false,
            top_sales_value: false,
            total_views: 0,
            total_clicks: 0,
            total_sales: 0,
            total_sales_value: 0,
            select_pagenum: [
                { id: 50, text: '50/page' },
                { id: 20, text: '20/page' },
            ],
            is_empty_data: false,
            params: {
                profile_status: 0,
                storefront_status: 0,
                recently_added: 0,
                metric: 0,
                metric_type: 0,
                metric_amount: '',
                metric_min_amount: '',
                metric_max_amount: '',
                social: {
                    metric: 1,
                    metric_msg: '',
                    metric_min_amount_input: '',
                    metric_max_amount_input: ''
                }
            },
            has_filters: false,
            user_info: {
                has: false,
                data: null
            },
            refrash_key: 0,
        }
    },

    computed: {
        summary_title() {
            return {
                title: 'Product Summary',
                view: 'Product Views',
                click: 'Clicks',
                sale: 'Sales',
                sale_value: 'Sales Value'
            }
        }
    },

    methods: {
        GoMedia: goMedia,

        getUserImage(avatar) {
            return require(`@/assets/images/user/${avatar}`);
        },

        showFullText(row, type) {
            let text = '';
            if (row.is_ellipsis_active) {
                // return row.last_name + ' ' + row.last_name;
                switch (type) {
                    case 'name':
                        text = row.last_name + ' ' + row.last_name;
                        break;
                    case 'ins':
                        text = row.instagram;
                        break;
                    case 'tt':
                        text = row.tiktok;
                        break;
                }
            }

            return text;
        },

        isEllipsisActive(e, row) {
            row.is_ellipsis_active = (e.target.offsetWidth < e.target.scrollWidth);
            //target

        },

        showStageText(state) {
            let text = '';
            switch (state) {
                case 'active':
                    text = 'This creator’s already joined and valid social handle on Motom, the storefrot is now active and can be control throught portal.';
                    break;
                case 'declined':
                    text = 'This creator’s already join and valid social handle on Motom, the creator received and rejected invitation on Motom.';
                    break;
                case 'invited':
                    text = 'This creator’s already join and valid social handle on Motom.  It’s pending this creator to accept or reject the storefront offering on Motom.';
                    break;
                case 'not-available':
                    text = 'This creator’s pending to join and social valid handle on Motom.';
                    break;
                case 'disabled':
                    text = 'This creator’s active storefront being disabled by a portal user. It can be turned on in the create detail.';
                    break;
                case 'joined':
                    text = 'This creator joined and valid social handle on Motom.';
                    break;
                case 'pending':
                    text = 'This creator pending to join and valid social handle on Motom.';
                    break;
                case 'delete':
                    text = 'This creator joined and deleted the account on Motom.';
                    break;
            }

            return text;
        },

        formatNumber(num) {
            return Number(num).toLocaleString('en-US');
        },

        formatMoney: formatMoney,

        open() {
            this.is_loading = true;
            this.fetchData();
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        updatePagenum(obj) {
            console.log(obj)
        },

        updateFilter(obj) {
            this.params = obj;

        },

        sort(key) {
            if (key === this.sort_key) {
                this.sort_val = this.sort_val === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort_key = key;
                this.sort_val = 'asc';
            }

            this.fetchData();
        },

        goFullReport() {
            this.user_info.has = false;
            this.user_info.data = null;
            this.refrash_key += 1;
        },

        pickUser(data) {

            this.user_info.has = true;
            this.user_info.data = {
                ...data,
                userProfile: {
                    avatar_full_url: data.avatar,
                }
            }

            this.refrash_key += 1;
        },

        search(keyword) {
            this.sort_key = '';
            this.sort_val = 'asc';
            this.search_keyword = keyword;
            this.fetchData();
        },

        fetchData() {
            if (!this.is_loading) this.is_loading_data = true;
            let api = `/api/get-product-creators?product_id=${this.product.id}`;

            if (this.sort_key) {
                api = `/api/get-product-creators?product_id=${this.product.id}&sort_key=${this.sort_key}&sort_val=${this.sort_val}`;
            }

            if (this.search_keyword) {
                api = `${api}&keyword=${this.search_keyword}`;
            }

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data = res.data.list;
                console.log('res.data',res.data)
                this.total_views = res.data.total_views;
                this.total_clicks = res.data.total_clicks;
                this.total_sales = res.data.total_sales;
                this.total_sales_value = res.data.total_sales_value;
                let c_data = JSON.parse(JSON.stringify(this.data));
                if (c_data.length !== 0) {
                    this.top_views = JSON.parse(JSON.stringify(c_data)).sort((a, b) => { return b.total_product_views - a.total_product_views })[0];
                    this.top_views.total_count = this.top_views.total_product_views;

                    this.top_clicks = JSON.parse(JSON.stringify(c_data)).sort((a, b) => { return b.total_product_clicks - a.total_product_clicks })[0];
                    this.top_clicks.total_count = this.top_clicks.total_product_clicks;

                    this.top_sales = JSON.parse(JSON.stringify(c_data)).sort((a, b) => { return b.total_product_sales - a.total_product_sales })[0];
                    this.top_sales.total_count = this.top_sales.total_product_sales;

                    this.top_sales_value = JSON.parse(JSON.stringify(c_data)).sort((a, b) => { return b.total_product_sales - a.total_product_sales })[0];
                    this.top_sales_value.total_count = this.top_sales_value.total_product_sales_value;
                }
                else {
                    this.top_views = null;
                    this.top_clicks = null;
                    this.top_sales = null;
                    this.top_sales_value = null;
                }

                this.is_loading = false;
                this.is_loading_data = false;
                let has_params = this.params.profile_status !== 0 || this.params.storefront_status !== 0 || this.params.recently_added !== 0 ||
                    (((this.params.metric_type === 0 || this.params.metric_type === 1) && this.params.metric_amount !== '') ||
                        (this.params.metric_type === 2 && this.params.metric_min_amount !== '' && this.params.metric_max_amount !== ''));
                this.is_empty_data = !this.is_loading && this.data.length === 0 && this.search_keyword === '' && !has_params;
            });
        },
    },

    mounted() {
        this.EventBus.off('open-product-modal');
        this.EventBus.on('open-product-modal', (obj) => {
            console.log(obj)
            this.data = [];
            this.product = obj.product;
            if (obj.data_info) {
                this.user_info.has = true;
                this.user_info.data = obj.data_info;
            } else {
                this.user_info.has = false;
                this.user_info.data = null;
            }
            this.open();
        });
    },
}
</script>
<style lang="scss">
.product-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    header {

        .title {
            display: flex;
            align-items: center;

            span {
                border-left: solid 1px var(--black-20);
                padding: 0 6px;
                margin-left: 6px;
                display: flex;
                align-items: center;
            }
        }

        .avatar {
            padding-right: 10px;
            height: 32px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid var(--black-40);
            }
        }

    }

    .storefront-summary {
        margin-top: 16px;
    }

    .ui-modal-side--container {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 100%;

        .box {
            overflow: hidden;
            display: grid;
            grid-template-rows: auto auto auto auto 1fr;
            grid-template-columns: 100%;
        }

        .product-info-box {
            margin-top: 16px;
            padding: 16px;
            display: flex;
            background: var(--bg-02);

            .product-flex-box {
                display: flex;
                align-items: center;
                justify-content: space-between;


                .product-btn {
                    border-radius: 100px;
                    border: solid 1px var(--dark-blue);
                    background-color: #fff;
                    padding: 6px 12px;
                    font-size: 12px;
                    font-weight: 600;
                    color: var(--dark-blue);
                    cursor: pointer;
                    min-width: 124px;
                    max-height: 31.14px;
                    margin-left: 10px;
                    display: flex;
                    justify-content: center;
                }
            }

            .image-wrap {
                width: 100px;
                height: 100px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                border-radius: 8px;
                border: 1px solid rgba(224, 224, 224, 1);

                img {
                    display: block;
                    position: absolute;
                    left: 0%;
                    top: 0%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .product-info {
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;

                .product-brand {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--black-90);
                }

                .product-name {
                    font: var(--font-demi-18);
                    color: var(--black-90);
                }

                .product-data-box {
                    margin-top: 16px;
                    display: flex;

                    .product-data {
                        width: 20%;

                        .text {
                            font: var(--font-fg-12);
                            color: var(--black-60);
                        }

                        .value {
                            font: var(--font-demi-12);
                            color: var(--black-90);
                        }
                    }

                    .report {
                        flex: 1;
                        display: flex;
                        justify-content: right;
                        align-items: flex-end;
                        font: var(--font-fg-12);
                        color: var(--blue);
                        cursor: pointer;
                    }
                }
            }


        }

        .report-header {
            display: flex;
            align-items: center;
            padding: 16px 0;

            .ui-search {
                flex: 1;
                padding: 0 10px;

                .ui-search-input .input {
                    max-width: unset;
                }
            }

            .left {
                display: flex;
                align-items: center;

                .title {
                    font: var(--font-demi-16);
                    color: var(--black-90);
                }

                .ui-button {
                    display: inline-flex;
                    align-items: center;

                    .svg-icon--plus {
                        height: 10px;
                        width: 10px;
                        margin-right: 5px;
                    }
                }

                .ui-filter-dropdown {
                    margin-left: 10px;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .ui-date-picker {
                    margin-right: 10px;
                }

                .ui-dropdown {
                    margin-right: 10px;
                }

                .download-btn {
                    color: var(--black-90);
                    height: 32px;
                    width: 32px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 16px;
                        width: 16px;
                        display: block;
                    }
                }
            }

            .ui-dropdown {
                .ui-button {
                    border-color: var(--black-10);
                    background-color: var(--black-10);
                }
            }
        }

        .report-box {
            margin: 0 -8px 16px -8px;
            display: flex;

            &+div>.filter-results {
                margin: 0 0 16px;
            }


            .report-item-2 {
                width: calc(25% - 16px);
                background: white;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                margin: 0 8px;
                position: relative;

                .top {
                    padding: 12px 10px;
                    display: flex;
                    align-items: center;

                    .value {
                        font-size: 14px;
                        color: var(--dark-blue);
                        display: flex;
                        align-items: center;
                    }

                    .name {
                        font-size: 10px;
                        color: var(--black-90);
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;

                        span {
                            color: var(--black-50);
                            padding-left: 4px;
                        }
                    }


                    svg {
                        width: 16px;
                        height: 16px;
                        fill: var(--pink);
                        margin-right: 5px;
                    }
                }


                .avatar {
                    height: 32px;
                    padding-right: 10px;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px solid var(--black-40);
                    }
                }

                .info {
                    padding: 12px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

                    .text {
                        font: var(--font-fg-12);
                        color: var(--blue);
                        display: flex;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: var(--blue);
                            margin-right: 4px;
                        }
                    }

                }

            }
        }
    }

    .data-list {
        height: initial;
        padding-bottom: 20px;
    }

    .ui-table {
        thead tr {
            border-top: 1px solid var(--black-20);
        }

        .tbody-tr {
            cursor: pointer;
        }

        .text-ellipsis-link:hover {
            text-decoration: underline;
        }

        td p {
            font-size: 12px;
        }

        td .profile span {
            color: var(--black-90);
        }

        .tfoot td:nth-child(1) {
            text-align: center;
        }

    }

}
</style>