import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';

const IS_MOBILE =
    !isUndefined(window.orientation) ||
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
        navigator.userAgent
    );
const IS_TABLET = IS_MOBILE && /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(navigator.userAgent);
const IS_PHONE = IS_MOBILE && !IS_TABLET;
const IS_DESKTOP = !IS_MOBILE;

const DEVICE_TYPE = IS_DESKTOP ? 'desktop' : IS_TABLET ? 'tablet' : IS_MOBILE ? 'mobile' : '';

// console.log('IS_MOBILE', IS_MOBILE);
// console.log('IS_TABLET', IS_TABLET);
// console.log('IS_PHONE', IS_PHONE);
// console.log('IS_DESKTOP', IS_DESKTOP);

export {IS_MOBILE, IS_TABLET, IS_DESKTOP, DEVICE_TYPE};

export default function(_d) {
    if (isUndefined(_d) || !isString(_d)) {
        return false;
    }

    let result = false;

    switch (_d.toLowerCase()) {
        case 'desktop':
            result = IS_DESKTOP;
            break;
        case 'mobile':
            result = IS_PHONE;
            break;
        case 'tablet':
            result = IS_TABLET;
            break;
        default:
            result = false;
            break;
    }

    return result;
}
