import Mock from '@/utils/mock.js';
import moment from 'moment';
import { Creators, Products, Sample, Users, PostStatistic, ContentPostStatistic, PostData, ReelData, TkData } from '@/assets/data/demo1.js';
// console.log(Creators, Products, Full)

const randomDate = (min = 32, max = 45) => {
    const days = Math.ceil(Math.random() * (max - min)) + min;
    const startdate = moment();
    return startdate.subtract(days, 'days');
}

const date_format = 'MMM DD, YYYY';

const getRandomArbitrary = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
}

const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const bigNumber = (total_number, num, limit_max, limit_min, digits = 0) => {
    const min = ((total_number / num) * limit_min);
    const max = ((total_number / num) * limit_max);
    const result = [...new Uint8Array(num)].fill(min, 0, num);
    const rest_limit = max - min;

    for (let i = 0; i < num; i++) {
        const random_number = (Math.random() * rest_limit);
        result[i] += random_number;
    }

    const sum = result.reduce((partialSum, a) => partialSum + a, 0);
    // console.log(sum, total_number - sum);

    const left_number = (total_number - sum) / num;

    for (let i = 0; i < num; i++) {

        result[i] += left_number;
        result[i] = Number(result[i].toFixed(digits));
    }

    // const sum2 = result.reduce((partialSum, a) => partialSum + a, 0);
    // console.log(sum2, total_number - sum2);
    return result;
}

const smallNumber = (total_number, num, result = []) => {
    // if (result.length === 0) 
    // console.log(total_number, num, result);

    for (let i = 0; i < num; i++) {
        const random_number = Math.round(Math.random());
        // const sum = result.reduce((partialSum, a) => partialSum + a, 0);
        // console.log(i , num)

        if (!result[i]) result[i] = 0;

        if (total_number === 0) {
            // const sum = result.reduce((partialSum, a) => partialSum + a, 0);
            // console.log('done => ' + sum)
            return result;
        }

        else if (total_number !== 0) {
            // console.log(random_number)
            result[i] += random_number;
            total_number = (total_number - random_number);
        }

    }

    if (total_number !== 0) {
        return smallNumber(total_number, num, result);
    }

    // if (total_number !== 0) smallNumber(total_number, num, result);

}

const autoRandomNumber = (total_number, num, limit_max, limit_min, digits = 0) => {
    // console.log(limit_max, limit_min)
    let result = [];

    if (total_number < (num * 2)) {
        result = smallNumber(total_number, num);
    } else {
        result = bigNumber(total_number, num, limit_max, limit_min, digits = 0);
    }

    // console.log('result', smallNumber(31, num));
    return result;


}

const regUrl = (url) => {
    return RegExp(url + ".*");
}

const formatDate = (date) => {
    // 格式化日期為 YYYY-MM-DD 格式
    let day = date.getDate();
    let month = date.getMonth() + 1; // 月份從 0 開始，需要加 1
    let year = date.getFullYear();

    // 確保日和月為兩位數
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return `${year}-${month}-${day}`;
}

const calculatePreviousDates = (daysArray) => {
    const today = new Date(); // 取得今天的日期
    const results = [];

    daysArray.forEach(days => {
        const previousDate = new Date(today);
        previousDate.setDate(today.getDate() - days);
        results.push(formatDate(previousDate));
    });

    return results;
}

const IgDateRadom = [1, 4, 6, 10, 12, 19, 24, 25, 27, 35];
const previousDates = calculatePreviousDates(IgDateRadom);
const TkDateRadom = [1, 2, 4, 8, 14, 15, 18, 21, 23, 23, 45];
const previousTkDates = calculatePreviousDates(TkDateRadom);

PostData.forEach((item, index) => {
    if (index < previousDates.length) {
        item.date = previousDates[index];
    }
});

ReelData.forEach((item, index) => {
    if (index < previousDates.length) {
        item.date = previousDates[index];
    }
    if (item.id === 987654321) {
        item.date = previousDates[previousDates.length - 1];
    }
    if (item.id === 666666666) {
        let arr=calculatePreviousDates([13])
        item.date = arr[0];
    }
});

TkData.forEach((item, index) => {
    if (index < previousTkDates.length) {
        item.date = previousTkDates[index];
    }
    if (item.id === 987654321) {
        item.date = previousTkDates[previousTkDates.length - 1];
    }
});

Sample.forEach(item => {
    const date = randomDate(0, 30);
    item.added_date = moment(date).format(date_format);
    item.added_datetime = moment(date).format('YYYYMMDD');
});

Creators.forEach((item, index) => {
    const date = randomDate(32, 45);
    const clone_products = JSON.parse(JSON.stringify(Sample));
    let pv_min = 0;
    let pv_max = 0;
    let pc_min = 0;
    let pc_max = 0;
    let pt_min = 0;
    let pt_max = 0;

    item.id = 'User_' + index;
    item.products = [];
    item.first_name = item.first_name;
    item.last_name = item.last_name;
    item.name = item.first_name + ' ' + item.last_name;
    // item.avatar = `${(rb) ? '16_Male_Avatar_600x' : 'Female'}/Image-600-${r}.png`;
    item.is_new = false;
    // item.date = moment(date).format('DD.MM.YYYY');
    item.date = moment(date).format(date_format);
    item.datetime = moment(date).format('YYYYMMDD');
    item.last_store_front_update_at = moment(date).format('YYYYMMDD');
    item.last_update = moment(date).format(date_format);
    item.show_status_time = moment(date).format('YYYYMMDD');
    item.views = 0;
    item.clicks = 0;
    item.sales = 0;
    item.sales_value = 0;
    item.anchor_page_status = 100;
    item.anchor_page_status_text = 'Active';

    switch (item.tiktok) {
        case 'GlamGoddessGrace':
            pv_min = 7;
            pv_max = 942;
            pc_min = 12;
            pc_max = 26;
            pt_min = 4;
            pt_max = 23;
            break;

        case 'ChicAndCharming':
            pv_min = 423;
            pv_max = 14342;
            pc_min = 14;
            pc_max = 40;
            pt_min = 4;
            pt_max = 16;
            break;

        case 'FashionableLizzie':
            pv_min = 475;
            pv_max = 2575;
            pc_min = 18;
            pc_max = 30;
            pt_min = 4;
            pt_max = 12;
            break;

        case 'MakeupMavenFarrahva':
            pv_min = 7;
            pv_max = 1942;
            pc_min = 30;
            pc_max = 45;
            pt_min = 4;
            pt_max = 23;
            break;

        case 'SassyStylist2001':
            pv_min = 2300;
            pv_max = 15942;
            pc_min = 24;
            pc_max = 40;
            pt_min = 3;
            pt_max = 6;
            break;

        case 'BeautyBossBelle<3':
            pv_min = 50;
            pv_max = 2300;
            pc_min = 24;
            pc_max = 50;
            pt_min = 8;
            pt_max = 16;
            break;

        case 'TrendyIzz':
            pv_min = 22;
            pv_max = 1242;
            pc_min = 18;
            pc_max = 36;
            pt_min = 3;
            pt_max = 13;
            break;

        case 'GorgeousGina':
            pv_min = 5323;
            pv_max = 22942;
            pc_min = 6;
            pc_max = 11;
            pt_min = 4;
            pt_max = 8;
            break;

        case 'HauteHannah':
            pv_min = 4;
            pv_max = 2942;
            pc_min = 30;
            pc_max = 45;
            pt_min = 4;
            pt_max = 9;
            break;

        case 'FashionistaFaith':
            pv_min = 7;
            pv_max = 6423;
            pc_min = 12;
            pc_max = 48;
            pt_min = 9;
            pt_max = 16;
            break;

        case 'BeautyQueenBree':
            item.anchor_page_status = 0;
            item.anchor_page_status_text = 'Disabled';
            pv_min = 6;
            pv_max = 123;
            pc_min = 8;
            pc_max = 23;
            pt_min = 4;
            pt_max = 23;
            break;
    }

    // console.log(item.tiktok, pv_min, pv_max, pc_min, pc_max, pt_min, pt_max);
    item.status = 100;
    item.status_text = 'Joined';

    let list = clone_products.filter(p => item.tiktok === p.tiktok || item.instagram === p.instagram);

    list.forEach(p => {
        // console.log(p);
        const date = randomDate(0, 30);

        p.views = Math.round(Math.random() * (pv_max - pv_min)) + pv_min;
        p.clicks = Math.round((p.views * (Math.random() * (pc_max - pc_min) + pc_min)) / 1000);
        p.sales = Math.round(((p.clicks * (Math.random() * (pt_max - pt_min) + pt_min)) / 1000) * 3.5);
        // p.sales = Math.round(((p.clicks * (pt_max - pt_min)) / 1000) * 6);

        p.sales_value = p.sales * p.price;
        p.name = p.Product_Name;
        p.id = p.ProductID;
        // get file name by url
        // p.image_url = p.Product_Image_URL;
        let url = new URL(p.Product_Image_URL);
        let pathname = url.pathname;
        let filename = pathname.split('/').pop();
        filename = filename.replace('jpg', 'webp');
        filename = filename.replace('jpeg', 'webp');
        filename = filename.replace('png', 'webp');
        p.image_url = filename;

        p.added_date = moment(date).format(date_format);
        p.added_datetime = moment(date).format('YYYYMMDD');
        // console.log(p.views, p.clicks, p.sales, p.sales_value)


        item.products.push(p);
        item.product_count = item.products.length;
        if (p.tiktok !== 'SassyStylist2001') item.tiktok_handle = `@${p.tiktok}`;
        item.instagram_handle = p.instagram;

        // item.views = p.Creator_Storefront_Views;
        item.clicks += p.clicks;
        item.sales += p.sales;
        item.sales_value += (p.sales * p.price);
    });

    let max_views = Math.max(...item.products.map(p => p.views));
    item.views = Math.round(max_views * 1.23);
});

Products.forEach((item, index) => {
    const date = randomDate(0, 30);
    item.added_date = moment(date).format(date_format);
    item.added_datetime = moment(date).format('YYYYMMDD');
    item.views = 0;
    item.clicks = 0;
    item.sales = 0;
    item.sales_value = 0;
});

Mock.setup({
    timeout: '200-1200',
});

Mock.mock(regUrl('/api/get-creators'), (options) => {
    const sort_key = getParameterByName('sort_key', options.url);
    const sort_val = getParameterByName('sort_val', options.url);
    const keyword = getParameterByName('keyword', options.url);

    let total_views = 0;
    let total_clicks = 0;
    let total_sales = 0;
    let total_sales_value = 0;
    let total_products = 0;
    let list = Creators;

    Creators.forEach((item, index) => {
        total_sales_value += item.sales_value;
        total_views += item.views;
        total_clicks += item.clicks;
        total_sales += item.sales;
        total_products += item.products.length;
    });


    switch (sort_key) {
        case 'name':
            if (sort_val === 'asc') {
                list.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1));
            } else {
                list.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1));
            }
            break;

        case 'tikTok_follower':
            if (sort_val === 'asc') {
                list.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1));
            } else {
                list.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1));
            }
            break;

        case 'instagram_follower':
            if (sort_val === 'asc') {
                list.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1));
            } else {
                list.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1));
            }
            break;

        case 'views':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.views - a.views });
            } else {
                list.sort((a, b) => { return a.views - b.views });
            }
            break;

        case 'clicks':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.clicks - a.clicks });
            } else {
                list.sort((a, b) => { return a.clicks - b.clicks });
            }
            break;

        case 'sales':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.sales - a.sales });
            } else {
                list.sort((a, b) => { return a.sales - b.sales });
            }
            break;

        case 'sales_value':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.sales_value - a.sales_value });
            } else {
                list.sort((a, b) => { return a.sales_value - b.sales_value });
            }
            break;

        case 'product_count':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.product_count - a.product_count });
            } else {
                list.sort((a, b) => { return a.product_count - b.product_count });
            }
            break;

        case 'creator_status':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.status_text - a.status_text });
            } else {
                list.sort((a, b) => { return a.status_text - b.status_text });
            }
            break;

        case 'anchor_status':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.anchor_page_status - a.anchor_page_status });
            } else {
                list.sort((a, b) => { return a.anchor_page_status - b.anchor_page_status });
            }
            break;

        case 'last_update_date':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return Number(b.last_update_date) - Number(a.last_update_date) });
            } else {
                list.sort((a, b) => { return Number(a.last_update_date) - Number(b.last_update_date) });
            }
            break;

        default:
            list.sort((a, b) => { return Number(b.datetime) - Number(a.datetime) });
            const index = list.findIndex(p => p.first_name === 'Haley');
            const itemToMove = list[index];

            if (index !== -1) {
                list.splice(index, 1);  // Remove the item from its current position
                list.unshift(itemToMove);
            }
            break;
    }

    if (keyword) {
        list = list.filter(p => p.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
    }

    const st_count = (val) => {
        return list.filter(item => item.anchor_page_status === val).length;
    }
    return Mock.mock({
        current_page: 1,
        last_page: 1,
        status: 'success',
        total_creators: list.length,
        total_views: total_views,
        total_sales: total_sales,
        total_clicks: total_clicks,
        total_sales_value: total_sales_value,
        total_products: total_products,
        total: {
            total_creator_count: list.length,
            total_storefront_active_count: st_count(100),
            total_storefront_not_available_count: st_count(10),
            total_storefront_disable_count: st_count(0),
            total_storefront_decline_count: st_count(-10),
            total_storefront_invited_count: st_count(20),
            total_views: total_views,
            total_clicks: total_clicks,
            total_sales: total_sales,
            total_sales_value: total_sales_value,
            total_creator_product_count: total_products,
            total_product_count: total_products,
            total_creator_joined_count: list.length,
            total_creator_pending_count: 0,
        },
        list: list,
    });
});

Mock.mock(regUrl('/api/get-user-products'), (options) => {
    const user_id = getParameterByName('user_id', options.url);
    const instagram = getParameterByName('instagram', options.url);
    let user = Creators.filter(p => p.id === user_id);
    let list = [];

    list = user[0].products;

    // if (user[0] && user[0].products) {
    //     user[0].products.forEach(p => {
    //         Products.forEach(product => {
    //             if (product.id === p) list.push(product);
    //         });
    //     });
    // }

    // list.forEach(item => {
    //     Sample.forEach(p => {
    //         if (p.ProductID === item.id && instagram === p.instagram) {
    //             item.views = p.Creator_Product_Views;
    //             item.clicks = p.Creator_Product_Clicks;
    //             item.sales = p.Creator_Product_Transactions;
    //             item.sales_value = (p.Creator_Product_Transactions * p.price);
    //         }
    //     });
    // });

    return Mock.mock({
        current_page: 1,
        last_page: 1,
        status: 'success',
        list: list,
    });
});

Mock.mock('/api/get-products', (options) => {
    let list = Products;

    list.forEach(item => {
        Creators.forEach(creator => {
            creator.products.forEach(p => {
                if (p.id === item.id) {
                    item.views += p.views;
                    item.clicks += p.clicks;
                    item.sales += p.sales;
                    item.sales_value += p.sales_value;
                }
            });
        });
    });

    list.sort((a, b) => { return b.sales_value - a.sales_value });

    // if (sort_val === 'asc') {
    //     list.sort((a,b) => {return b.sales_value - a.sales_value});
    // } else {
    //     list.sort((a,b) => {return a.sales_value - b.sales_value});
    // }

    return Mock.mock({
        current_page: 1,
        last_page: 1,
        status: 'success',
        list: list,
    });
});

Mock.mock(regUrl('/api/get-product-creators'), (options) => {
    // const params = JSON.parse(options.body);
    // const pageSize = params.pageSize ? params.pageSize : 10;
    // const params = new URLSearchParams(options.url);

    let list = [];
    const sort_key = getParameterByName('sort_key', options.url);
    const sort_val = getParameterByName('sort_val', options.url);
    const keyword = getParameterByName('keyword', options.url);
    const product_id = getParameterByName('product_id', options.url);
    const clone_creators = JSON.parse(JSON.stringify(Creators));

    let total_views = 0;
    let total_clicks = 0;
    let total_sales = 0;
    let total_sales_value = 0;

    clone_creators.forEach(item => {
        // if (item.products.includes(product_id)) {
        //     list.push(item);
        // }

        item.products.forEach(p => {
            if (p.ProductID === product_id) list.push(item);
        });
    });

    list.forEach(item => {
        // 初始化各欄位
        item.total_product_sales_value = 0;
        item.total_product_views = 0;
        item.total_product_clicks = 0;
        item.total_product_sales = 0;

        item.products.forEach(p => {
            if (p.ProductID === product_id) {
                item.total_product_sales_value += p.sales_value;
                item.total_product_views += p.views;
                item.total_product_clicks += p.clicks;
                item.total_product_sales += p.sales;
            }
        })

        // 將各欄位的值加到總和變數中
        total_sales_value += item.total_product_sales_value;
        total_views += item.total_product_views;
        total_clicks += item.total_product_clicks;
        total_sales += item.total_product_sales;
    })


    switch (sort_key) {
        case 'first_name':
            if (sort_val === 'asc') {
                list.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1));
            } else {
                list.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1));
            }
            break;

        case 'last_name':
            if (sort_val === 'asc') {
                list.sort((a, b) => (a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1));
            } else {
                list.sort((a, b) => (a.last_name.toLowerCase() < b.last_name.toLowerCase() ? 1 : -1));
            }
            break;

        case 'joined':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return Number(b.datetime) - Number(a.datetime) });
            } else {
                list.sort((a, b) => { return Number(a.datetime) - Number(b.datetime) });
            }
            break;

        case 'views':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.total_product_views - a.total_product_views });
            } else {
                list.sort((a, b) => { return a.total_product_views - b.total_product_views });
            }
            break;

        case 'clicks':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.total_product_clicks - a.total_product_clicks });
            } else {
                list.sort((a, b) => { return a.total_product_clicks - b.total_product_clicks });
            }
            break;

        case 'sales':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.total_product_sales - a.total_product_sales });
            } else {
                list.sort((a, b) => { return a.total_product_sales - b.total_product_sales });
            }
            break;

        case 'sales_value':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return b.total_product_sales_value - a.total_product_sales_value });
            } else {
                list.sort((a, b) => { return a.total_product_sales_value - b.total_product_sales_value });
            }
            break;

        case 'added_date':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return Number(b.added_datetime) - Number(a.added_datetime) });
            } else {
                list.sort((a, b) => { return Number(a.added_datetime) - Number(b.added_datetime) });
            }
            break;

        case 'removed_date':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return Number(b.removed_datetime) - Number(a.removed_datetime) });
            } else {
                list.sort((a, b) => { return Number(a.removed_datetime) - Number(b.removed_datetime) });
            }
            break;

        case 'date':
            if (sort_val === 'asc') {
                list.sort((a, b) => { return Number(b.datetime) - Number(a.datetime) });
            } else {
                list.sort((a, b) => { return Number(a.datetime) - Number(b.datetime) });
            }
            break;

        default:
            list.sort((a, b) => { return Number(b.datetime) - Number(a.datetime) });
            break;
    }

    if (keyword) {
        list = list.filter(p => p.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
    }

    return Mock.mock({
        total_views: total_views,
        total_clicks: total_clicks,
        total_sales: total_sales,
        total_sales_value: total_sales_value,
        current_page: 1,
        last_page: 1,
        status: 'success',
        list: list,
    });
});

Mock.mock(regUrl('/api/get-user-products'), (options) => {
    const user_id = getParameterByName('user_id', options.url);
    const instagram = getParameterByName('instagram', options.url);
    let products = [];
    if (user_id) {
        let user = Creators.filter(p => p.id === user_id);
        products = user[0].products;
    }

    return Mock.mock({
        status: 'success',
        list: products,
    });
});

Mock.mock(regUrl('/api/get-all-data'), (options) => {
    const type = getParameterByName('type', options.url);
    const user_id = getParameterByName('user_id', options.url);
    const product_id = getParameterByName('product_id', options.url);
    let num = 0;
    let digits = 0;
    let list = [];
    let total_views = 0;
    let total_clicks = 0;
    let total_sales = 0;
    let total_transactions = 0;

    if (user_id) {
        let user = Creators.filter(p => p.id === user_id);
        let instagram = '';
        let ulist = [];

        if (user[0] && user[0].products) {
            instagram = user[0].instagram;
            ulist = user[0].products;
            // user[0].products.forEach(p => {
            //     Products.forEach(product => {
            //         if (product.id === p) ulist.push(product);
            //     });
            // });
        }

        if (product_id) {
            // console.log('ulist',ulist)
            ulist.forEach(p => {
                if (p.ProductID === product_id && instagram === p.instagram) {
                    // console.log(p)
                    total_sales = p.sales_value;
                    total_views = p.views;
                    total_clicks = p.clicks;
                    total_transactions = p.sales;
                }
            });
        } else {
            total_views = user[0].views;

            ulist.forEach(p => {
                total_sales += p.sales_value;
                // total_views = p.views;
                total_clicks += p.clicks;
                total_transactions += p.sales;
            });
        }

    } else {
        let pre_val = 0;
        let list = Products;

        if (product_id) {

            Creators.forEach(creator => {
                creator.products.forEach(p => {
                    if (p.id === product_id) {
                        total_sales += p.sales_value;
                        total_views += p.views;
                        total_clicks += p.clicks;
                        total_transactions += p.sales;
                    }
                });
            });

        } else {
            Creators.forEach(creator => {
                creator.products.forEach(p => {

                    total_sales += p.sales_value;
                    total_views += p.views;
                    total_clicks += p.clicks;
                    total_transactions += p.sales;

                });
            });
            // Sample.forEach(item => {
            //     total_sales += (item.Creator_Product_Transactions * item.price);
            //     if (pre_val !== item.Creator_Storefront_Views) {
            //         pre_val = item.Creator_Storefront_Views;
            //         total_views += item.Creator_Storefront_Views;
            //     }
            //     total_clicks += item.Creator_Product_Clicks;
            //     total_transactions += item.Creator_Product_Transactions;
            // });
        }
    }


    // console.log(data.total_sales * 65 ~ 70)
    // console.log(data.total_sales * 18 ~ 22)
    // console.log(data.total_sales * 8 ~ 10)
    // console.log(data.total_sales * 1 ~ 5)

    const source = [
        Math.round(Math.random() * (69 - 63)) + 63,
        Math.round(Math.random() * (21 - 17)) + 17,
        Math.round(Math.random() * (9 - 6)) + 6,
        Math.round(Math.random() * (3 - 0)) + 0,
        Math.round(Math.random() * (5 - 1)) + 1,
    ];

    const device = [
        Math.round(Math.random() * (80 - 72)) + 72,
        Math.round(Math.random() * (28 - 14)) + 14,
        Math.round(Math.random() * (5 - 1)) + 1,
    ];

    let sum = source.reduce((ps, a) => ps + a, 0);
    let sum2 = device.reduce((ps, a) => ps + a, 0);

    for (let index = 0; index < 10; index++) {
        const inx = Math.round(Math.random() * (4 - 0)) + 0;
        sum = source.reduce((ps, a) => ps + a, 0);
        if (source[inx] !== 0 && sum !== 100) {
            if (sum < 100) {
                source[inx]++;
            } else {
                source[inx]--;
            }
        }
    }

    for (let index = 0; index < 20; index++) {
        const inx = Math.round(Math.random() * (2 - 0)) + 0;
        sum2 = device.reduce((ps, a) => ps + a, 0);
        if (device[inx] !== 0 && sum2 !== 100) {
            if (sum2 < 100) {
                device[inx]++;
            } else {
                device[inx]--;
            }
        }
    }

    // sum2 = device.reduce((ps, a) => ps + a, 0);
    // console.log(device, sum2);

    switch (type) {
        case 'view':
            num = total_views;
            break;
        case 'click':
            num = total_clicks;
            break;
        case 'tran':
            num = total_transactions;
            break;
        case 'sales':
            num = total_sales;
            digits = 2;
            break;
    }

    // console.log(num)

    const data = autoRandomNumber(num, 30, 1.2, .8, digits)
    data.forEach((v, i) => {
        list.push({
            date: moment().subtract((30 - i), 'days').format('MMM DD'),
            value: v,
        })
    });

    let views = [];
    let clicks = [];
    let sales = [];
    let transactions = [];

    // console.log(num)

    const data1 = autoRandomNumber(total_views, 30, 1.2, .8, 0)
    data1.forEach((v, i) => {
        views.push({
            date: moment().subtract((30 - i), 'days').format('MMM DD'),
            value: v,
        })
    });

    const data2 = autoRandomNumber(total_clicks, 30, 1.15, .85, 0)
    data2.forEach((v, i) => {
        clicks.push({
            date: moment().subtract((30 - i), 'days').format('MMM DD'),
            value: v,
        })
    });

    const data3 = autoRandomNumber(total_sales, 30, 1.15, .85, 0)
    data3.forEach((v, i) => {
        sales.push({
            date: moment().subtract((30 - i), 'days').format('MMM DD'),
            value: v,
        })
    });

    const data4 = autoRandomNumber(total_transactions, 30, 1.2, .8, 0)
    // data4.forEach((v, i) => {
    //     transactions.push({
    //         date: moment().subtract((30 - i), 'days').format('MMM DD'),
    //         value: v,
    //     })
    // });

    return Mock.mock({
        status: 'success',
        total_sales: total_sales,
        total_clicks: total_clicks,
        total_transactions: total_transactions,
        total_views: total_views,
        list: list,
        views: views,
        clicks: clicks,
        sales: sales,
        transactions: transactions,
    });
});

// Mock.mock(regUrl('/api/get-source-data'), (options) => {
//     const type = getParameterByName('type', options.url);
//     // { value: 1048, name: 'Instagram' },
//     // { value: 735, name: 'TikTok' },
//     // { value: 580, name: 'Other' },
//     // { value: 484, name: 'Search' },
//     // { value: 484, name: 'YouTube' },
//     return Mock.mock({
//         status: 'success',
//         data: [
//             { value: '@integer(100, 600)', name: 'Instagram' },
//             { value: '@integer(120, 800)', name: 'TikTok' },
//             { value: '@integer(10, 300)', name: 'Other' },
//             { value: '@integer(10, 300)', name: 'Search' },
//             { value: '@integer(10, 300)', name: 'YouTube' },
//         ]
//     });
// });

// Mock.mock(regUrl('/api/get-device-data'), (options) => {
//     const type = getParameterByName('type', options.url);
//     // { value: 1048, name: 'Desktop' },
//     // { value: 735, name: 'iOS' },
//     // { value: 580, name: 'Android' },
//     return Mock.mock({
//         status: 'success',
//         data: [
//             { value: '@integer(800, 1200)', name: 'Desktop' },
//             { value: '@integer(1810, 3300)', name: 'iOS' },
//             { value: '@integer(190, 1300)', name: 'Android' },
//         ]
//     });
// });

Mock.mock(regUrl('/api/get-top-data'), () => {
    return Mock.mock({
        status: 'success',
        data: {
            top_views: {
                name: '@name',
                value: '@integer(122190, 62213000)',
                avatar: Mock.Random.image('120x120', '#f2f2f2'),
            },
            top_clicks: {
                name: '@name',
                value: '@integer(21290, 131300)',
                avatar: Mock.Random.image('120x120', '#f2f2f2'),
            },
            top_transactions: {
                name: '@name',
                value: '@integer(890, 3300)',
                avatar: Mock.Random.image('120x120', '#f2f2f2'),
            },
            top_sales: {
                name: '@name',
                value: '@float(21200, 113900, 0, 2)',
                avatar: Mock.Random.image('120x120', '#f2f2f2'),
            }
        }
    });
});

Mock.mock(regUrl('/api/get-top-creators'), (options) => {
    let list = [];
    const type = getParameterByName('type', options.url);


    switch (type) {
        case '1':
            Creators.sort((a, b) => { return b.views - a.views });
            break;

        case '2':
            Creators.sort((a, b) => { return b.clicks - a.clicks });
            break;

        case '3':
            Creators.sort((a, b) => { return b.sales - a.sales });
            break;

        case '4':
            Creators.sort((a, b) => { return b.sales_value - a.sales_value });

            break;
    }

    Creators.forEach((item, index) => {
        if (index <= 5) {
            list.push(item);
        }
    });

    return Mock.mock({
        status: 'success',
        list: list,
    });
});


Mock.mock('/api/login', (options) => {
    // Tim Trevathan tim@motom.me; 
    // Matt Diamond matt@motom.me; 
    // Wendy Wildfeuer wendy@motom.me; 
    // Geri Newman geri@motom.me; 
    // Jenna Lichtman jenna@motom.me

    // Temp user J. Doe jdoe@acmecorp.com
    const params = JSON.parse(options.body);
    let status = '';
    let name = '';
    let avatar = '';
    let user_id = '';

    if (params.password === 'Motom@123') {
        Users.forEach(item => {
            if (item.email === params.email) {
                status = 'success';
                name = item.name;
                avatar = item.avatar;
                user_id = item.id;
            }

            if (user_id === 6) {
                window.localStorage.setItem('temp_user_login', '1');
            }
        });

        if (!status) {
            status = 'error';
        }
    } else {
        status = 'error';
    }



    return Mock.mock({
        status: status,
        user: {
            name: name,
            avatar: avatar,
            user_id: user_id,
            email: params.email,
        },
    });
});


Mock.mock('/api/upload/put', (options) => {
    return Mock.mock({
        status: 'success',
    });
});

Mock.mock('/api/upload/post', (options) => {
    return Mock.mock({
        status: 'success',
    });
});

Mock.mock('/api/settings/company-list', (options) => {
    return Mock.mock({
        data: [
            {
                "id": 5,
                "name": "Motom",
                "merchant_account_id": 40
            },
            {
                "id": 6,
                "name": "Acme Corporation",
                "merchant_account_id": 41
            },
        ],
        status: 'success',
    });
});


Mock.mock('/api/settings/portal-user-list', (options) => {
    const data = [];

    Users.forEach(item => {
        data.push({
            id: item.id,
            name: item.name,
            email: item.email,
            avatar: item.avatar,
            role: 1,
            status: 100,
            last_login: '2023-04-11T04:49:29.000000Z',
            created_at: '2023-04-11T04:49:29.000000Z',
            role_name: 'admin',
            is_motom_internal_user: 0,
        });
    });

    return Mock.mock({
        data: data,
        status: 'success',
    });
});

Mock.mock('/api/settings/custom-script-config/list', (options) => {
    const data = [];

    Users.forEach(item => {
        data.push({
            "id": 34,
            "script_name": "ga4",
            "merchant_account_id": 3,
            "container_id": "G-test1234",
            "last_updated_by_portal_user_id": 13,
            "status": 0,
            "created_at": "2023-10-30T01:34:51.000000Z",
            "updated_at": "2023-10-30T01:35:30.000000Z"
        });
    });

    return Mock.mock({
        data: data,
        status: 'success',
    });
});

//add collections api
Mock.mock(regUrl('/api/creator/get-collection-list'), (options) => {
    const instagram = getParameterByName('instagram', options.url);
    let creator = Creators.filter(e => { return e.instagram === instagram });
    let list = Sample.filter((e, k) => { return e.instagram === instagram })
    list = list.filter((e, k) => { return k > 1 && k < 6 });

    let total_vis = 0;
    let total_views = 0;
    let total_clicks = 0;
    let total_transactions = 0;
    let total_sales_value = 0;

    const getRandomDateWithinTwoYears = () => {
        // 取得目前時間的毫秒數
        const now = new Date();
        const nowTime = now.getTime();

        // 計算過去兩年時間的毫秒數
        const twoYearsInMilliseconds = 2 * 365 * 24 * 60 * 60 * 1000; // 2年 * 365天 * 24小時 * 60分鐘 * 60秒 * 1000毫秒

        // 產生隨機的毫秒數，範圍在過去兩年內
        const randomTime = nowTime - Math.random() * twoYearsInMilliseconds;

        // 將隨機的毫秒數轉換回日期
        const randomDate = new Date(randomTime);

        return randomDate;
    }

    list.forEach(e => {
        let fi = Products.filter(ee => { return ee.id === e.ProductID });
        e.image_url = fi[0] ? fi[0].image_url : '';
        total_vis += Number(e.Creator_Storefront_Views);
        total_views += Number(e.Creator_Product_Views);
        total_clicks += Number(e.Creator_Product_Clicks);
        total_transactions += Number(e.Creator_Product_Transactions);
        total_sales_value += Number(e.Creator_Product_Sales);
    })

    return Mock.mock({
        data: [
            {
                userProfile: {
                    ...creator[0],
                    avatar_full_url: creator[0].avatar,
                    nickname: `${creator[0].first_name} ${creator[0].last_name}`
                },
                name: 'My Favorites',
                cover_image_url: null,
                first_4_product_thumbnails: list.map(e => { return e.image_url }),
                last_item_update_at: "2024-03-28 09:56:14",
                created_at: "2024-03-21 09:05:40",
                views: total_views,
                visit: total_vis,
                product_click: total_clicks,
                sales: total_transactions,
                sales_value: total_sales_value,
                product_count: list.length,
                is_feature: 1,
                is_publish: 1,
                deleted_at: null,
                anchor_collection_share_url: "https://www.creators.qa.motom.me/creator/@oyoyyolla/forever21/list/NmJAofCbgxG",

                products: list.map(e => {
                    return {
                        ...e,
                        views: e.Creator_Product_Views,
                        clicks: e.Creator_Product_Clicks,
                        sales: e.Creator_Product_Transactions,
                        sales_value: e.Creator_Product_Sales,
                        created_at: getRandomDateWithinTwoYears(),
                        products: {
                            images: e.image_url,
                            name: e.Product_Name,
                            merchant_name: e.brand_name,
                            price: e.price,
                        }
                    }
                })
            }
        ],
        status: 'success',
    });
});

Mock.mock('/creator/get-social-post-filter', (options) => {
    return Mock.mock({
        data: {
            "social_post_mention_texts": [
                {
                    "id": 1,
                    "text": "joannabuchanan"
                },
                {
                    "id": 2,
                    "text": "jbhome"
                },
            ],
            "social_post_hashtag_texts": [
                {
                    "id": 1,
                    "text": "sponsored"
                },
                {
                    "id": 2,
                    "text": "jbseasonal"
                },
                {
                    "id": 3,
                    "text": "promoted"
                },
            ],
            "social_post_tag_texts": [
                {
                    "id": 1,
                    "text": "Sale"
                },
                {
                    "id": 2,
                    "text": "Organic"
                },
                {
                    "id": 3,
                    "text": "Paid"
                },
                {
                    "id": 4,
                    "text": "Seasonal"
                },
            ]
        },
        status: 'success',
    });
});


Mock.mock(regUrl('/creator/get-social-account-brand-content-posts-statistic'), (options) => {
    const user_id = getParameterByName('user_id', options.url);
    const date = randomDate(0, 7);
    const last_update_time = moment(date).format(date_format);
    const user = Creators.find(p => p.id === user_id);
    let data = {};

    if (user.id === 'User_5') {
        data = {
            last_update_time: last_update_time,
            tiktok: {
                views: user.tiktok_views,
                engagements: 5254,
                engagement_rate: user.tiktok_engagement_rate,
                posts_count: user.tiktok_post_count,
            },
            instagram: {
                impressions: user.instagram_impressions,
                engagements: 4511,
                engagement_rate: user.instagram_engagement_rate,
                posts_count: user.instagram_in_feed_count,
            },
            story: {
                impressions: user.instagram_story_impressions,
                engagements: user.instagram_story_engagements,
                engagement_rate: user.instagram_story_engagement_rate,
                posts_count: user.instagram_story_count
            }
        };
    } else {
        data = {
            last_update_time: last_update_time,
            tiktok: {
                views: getRandomArbitrary(18, 888),
                engagements: getRandomArbitrary(2, 38),
                engagement_rate: getRandomArbitrary(1, 10),
                posts_count: getRandomArbitrary(8, 20),
            },
            instagram: {
                impressions: getRandomArbitrary(3, 22),
                engagements: getRandomArbitrary(2, 38),
                engagement_rate: getRandomArbitrary(1, 10),
                posts_count: getRandomArbitrary(8, 20),
            },
            story: {
                impressions: getRandomArbitrary(30, 322),
                engagements: getRandomArbitrary(2, 38),
                engagement_rate: getRandomArbitrary(1, 10),
                posts_count: getRandomArbitrary(8, 20)
            }
        };
    }

    return Mock.mock({
        data: data,
        status: 'success',
    });
});

Mock.mock(regUrl('/creator/get-social-account-posts'), (options) => {
    const user_id = getParameterByName('user_id', options.url);
    let posts = PostData;
    let reels = ReelData;
    let tkData = TkData;

    if (user_id === 'User_5') {
        tkData = TkData.filter(p => (p.id === 1049 || p.id === 1046 || p.id === 987654321));
        posts = posts.filter(p => (p.id === 10455 || p.id === 987654321));
        reels  = ReelData.filter(p => (p.id === 10455 || p.id === 987654321));
    }else{
        tkData = TkData.filter(p => { return !p.only_content });
        posts = posts.filter(p => { return !p.only_content });
        reels  = ReelData.filter(p => { return !p.only_content });
    }

    return Mock.mock({
        data: {
            "social_posts": {
                posts: posts,
                reels: reels,
                story: [],
                tkData: tkData,
            }
        },
        status: 'success',
    });
});

Mock.mock(regUrl('/creator/get-aaa-account-posts-statistic'), (options) => {
    let data = PostStatistic;
    const user_id = getParameterByName('user_id', options.url);
    const user = Creators.find(p => p.id === user_id);

    if (user_id === 'User_5') {
        data = {
            "tk": {
                "total": {
                    "total_views": user.tiktok_views,
                    "total_engagement": user.tiktok_engagements,
                    "total_engagement_rate": user.tiktok_engagement_rate / 100,
                },
                "audience_gender": [
                    {
                        "name": "Women",
                        "value": 0.588
                    },
                    {
                        "name": "Men",
                        "value": 0.408
                    },
                    {
                        "name": "Other",
                        "value": 0.004
                    }
                ],
                "audience_age": [
                    {
                        "name": "18-24",
                        "value": 0.279
                    },
                    {
                        "name": "25-34",
                        "value": 0.424
                    },
                    {
                        "name": "35+",
                        "value": 0.297
                    },
                ],
                "audience_countries": [
                    {
                        "name": "United States",
                        "value": 0.8845
                    },
                    {
                        "name": "Canada",
                        "value": 0.0216
                    },
                    {
                        "name": "United Kingdom",
                        "value": 0.0096
                    },
                    {
                        "name": "Other",
                        "value": 0.012
                    },
                    {
                        "name": "South Africa",
                        "value": 0.0012
                    },
                    {
                        "name": "Australia",
                        "value": 0.0041
                    },
                    {
                        "name": "Taiwan",
                        "value": 0.0021
                    },
                    {
                        "name": "France",
                        "value": 0.0049
                    },
                    {
                        "name": "Brazil",
                        "value": 0.0042
                    },
                    {
                        "name": "Germany",
                        "value": 0.0059
                    },
                ]
        
            },
            "ig": {
                "feed": {
                    "total_views": user.instagram_views,
                    "total_engagement": user.instagram_engagements,
                    "total_engagement_rate": user.instagram_engagement_rate / 100,
                },
                "story": {
                    "total_views": user.instagram_story_views,
                    "total_engagement": user.instagram_story_engagements,
                    "total_engagement_rate": user.instagram_story_engagement_rate / 100,
                },
                "audience_gender": [
                    {
                        "name": "Women",
                        "value": 0.627
                    },
                    {
                        "name": "Men",
                        "value": 0.369
                    },
                    {
                        "name": "Other",
                        "value": 0.004
                    }
                ],
                "audience_age": [
                    {
                        "name": "18-24",
                        "value": 0.212
                    },
                    {
                        "name": "25-34",
                        "value": 0.312
                    },
                    {
                        "name": "35-44",
                        "value": 0.258
                    },
                    {
                        "name": "45-54",
                        "value": 0.146
                    },
                    {
                        "name": "55+",
                        "value": 0.072
                    }
                ],
                "audience_countries": [
                    {
                        "name": "United States",
                        "value": 0.9345
                    },
                    {
                        "name": "Canada",
                        "value": 0.0241
                    },
                    {
                        "name": "United Kingdom",
                        "value": 0.0127
                    },
                    {
                        "name": "Other",
                        "value": 0.0147
                    },
                    {
                        "name": "South Africa",
                        "value": 0.0014
                    },
                    {
                        "name": "Australia",
                        "value": 0.0037
                    },
                    {
                        "name": "Taiwan",
                        "value": 0.0001
                    },
                    {
                        "name": "France",
                        "value": 0.0068
                    },
                    {
                        "name": "Brazil",
                        "value": 0
                    },
                    {
                        "name": "Germany",
                        "value": 0.002
                    },
                ]
            }
        }
    }

    return Mock.mock({
        data: PostStatistic,
        status: 'success',
    });
});

Mock.mock('/creator/get-contentt-posts-statistic', (options) => {
    return Mock.mock({
        data: ContentPostStatistic,
        status: 'success',
    });
});