<template>
    <div class="ui-input" :class="['ui-input--' + type, {'is-error': error_msg }, {'is-error-important':handle_err}, {'is-success': is_success || !handle_success}, {'is-ready-only': ready_only}, {'is-disabled': is_disabled}]">
        <label v-if="label" class="label-text" v-html="label" :for="k"></label>
        
        <div class="ui-input--select" v-if="type === 'select'">
            <select :name="k"  @change="onChange();" ref="select">
                <option v-if="placeholder" selected disabled>{{ placeholder }}</option>
                <template v-for="(item, key) in select_data" :value="item" :key="key">
                    <option v-if="(typeof item === 'string')" :selected="selected === key" :vlaue="item">{{item}}</option>
                    <option v-else :selected="selected === key" :vlaue="item.id">{{item.name}}</option>
                </template>
            </select>
            <SvgIcon name="arrow" />
        </div>
        <label :for="k" class="ui-input--icon-wrap" :class="{'focus': is_focus}" v-else-if="type === 'icon'" @click="focus">
            <span v-if="befter_icon" class="befter-icon"><SvgIcon :name="befter_icon" /></span>
            <span v-if="befter_text" class="befter-text" v-html="befter_text"></span>
            <input :type="input_type" :name="k" v-model="value" :placeholder="placeholder" @input="onChange();" @change="onChange();" @focus="onChange('focus')" @blur="onChange('blur')" ref="input"/>
            <span v-if="after_icon" class="after-icon"><SvgIcon :name="after_icon" /></span>
            <span v-if="after_text" class="after-text" v-html="after_text"></span>
            
        </label>
        <div class="ui-input--wrap" v-else>
            <input :type="input_type" :readonly="is_disabled" :name="k" v-model="value" :placeholder="placeholder" @input="onChange();" @change="onChange();" @focus="onChange('focus')" @blur="onChange('blur')" ref="input"/>
            <i v-if="type === 'password'" @click="switchType">
                <SvgIcon v-if="!is_show_password" name="eye-hidden" />
                <SvgIcon v-else name="eye" />
            </i>
            <i v-if="is_clear" class="clear" @click="onChange(false,true)"><SvgIcon name="close-circle-2" /></i>
        </div>
        <div class="err-msg-box" v-if="error_msg && !no_show_err_msg">
            <p class="err-msg" v-html="error_msg">
            </p>
            <i><SvgIcon name="alert" class="icon-left" /></i>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'ui-checkbox',

    props: {
        type: {
            type: String,
            default: 'text',
        },

        k: {
            type: String,
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        default_value: {
            type: String,
            default: '',
        },

        error_msg: {
            type: [String, Boolean],
            default: '',
        },

        no_show_err_msg:{
            type:Boolean,
            default:false,
        },

        handle_err:{
            type:Boolean,
            default:false,
        },

        handle_success:{
            type:Boolean,
            default:true,
        },

        ready_only: {
            type: Boolean,
            default: false,
        },

        is_success: {
            type: Boolean,
            default: false,
        },

        select_data: {
            type: Array,
            default: [],
        },

        befter_text: {
            type: String,
            default: '',
        },

        befter_icon: {
            type: String,
            default: '',
        },

        after_icon: {
            type: String,
            default: '',
        },

        after_text: {
            type: String,
            default: '',
        },

        is_clear:{
            type: Boolean,
            default: false,
        },

        is_disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        SvgIcon,
    },


    data() {
        return {
            input_type: 'text',
            is_show_password: false,
            value: '',
            is_focus: false,
        }
    },

    methods: {
        switchType() {
            this.is_show_password = !this.is_show_password;
            
            if (this.is_show_password) {
                this.input_type = 'text';
            } else {
                this.input_type = 'password';
            }
        },

        onChange(type = false,clear) {
            const obj = {};
            obj.key = this.k;

            if (this.type === 'select') {
                obj.val = this.$refs.select.value;

            } else if(clear){
                this.value='';
                obj.val ='';
                
            }else{
                obj.val = this.value;
            }

            if (type === 'focus') {
                this.is_focus = true;
            } else if (type === 'blur') {
                this.is_focus = false;
            }

            this.$emit('update', obj);
        },

        focus() {
            this.$refs.input.focus();
        },

        sync() {
            this.value = this.default_value;
        },
    },

    mounted() {
        this.input_type = this.type;
    },
}

</script>

<style lang="scss">
    .ui-input {
        margin: 16px 0;

        & > .label-text {
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
            color: var(--black-75);
        }

        &--wrap {
            position: relative;

            i {
                height: 20px;
                width: 20px;
                position: absolute;
                top: 50%;
                right: 12px;
                margin-top: -10px;
                cursor: pointer;

                svg {
                    height: 100%;
                    width: 100%;
                    display: block;
                    fill: var(--black-50);
                }

                // &:hover svg {
                //     fill: var(--black-50);
                // }
            }


            input {
                padding: 15px 12px;
                border: 1px solid var(--black-20);
                background-color: white;
                width: 100%;
                border-radius: 6px;
                color: var(--black-90);
                transition: .15s border-color ease;
                font-weight: 500;
                font-family: var(--font);

                &:hover {
                    border-color: var(--black-50);
                }
                
                &:focus {
                    outline: none;
                    border-color: var(--dark-blue);
                }

                &::placeholder {
                    color: var(--black-50);
                    font-weight: 500;
                }
            }

        }

        &--icon-wrap {
            position: relative;
            border: 1px solid var(--black-20);
            background-color: white;
            width: 100%;
            border-radius: 6px;
            color: var(--black-90);
            transition: .15s border-color ease;
            display: flex;
            align-items: center;
            overflow: hidden;

            &:hover {
                border-color: var(--black-50);
            }
            
            &.focus {
                border-color: var(--dark-blue);
            }

            span {
                cursor: text;
            }

            .after-icon,
            .befter-icon {
                position: relative;
                width: 18px;
                margin: 0 0 0 15px;

                svg {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    height: 18px;
                    width: 18px;
                    margin-top: -9px;
                }
            }

            .after-icon {
                margin: 0 15px 0 0;
            }

            .befter-text {
                padding-left: 6px;

                & + input {
                    padding-left: 0px;
                }
            }

            input {
                padding: 15px 12px;
                flex: 1;
                border: none;
                background-color: transparent;
                font-weight: 500;
                font-family: var(--font);

                &:focus {
                    outline: none;
                }
                // padding: 15px 12px;
                // border: 1px solid var(--black-20);
                // background-color: white;
                // width: 100%;
                // border-radius: 6px;
                // color: var(--black-90);
                // transition: .15s border-color ease;
                // font-weight: 500;
                // font-family: var(--font);



                &::placeholder {
                    color: var(--black-50);
                    font-weight: 500;
                }
            }

        }

        &--select {
            position: relative;

            select {
                padding: 15px 12px;
                border-radius: 6px;
                border: 1px solid var(--black-20);
                color: var(--black-90);
                transition: .15s border-color ease;
                font-weight: 500;
                font-family: var(--font);
                cursor: pointer;
                background: white;
                width: 100%;
                appearance: none;

                option {
                    font-weight: 500;
                    cursor: pointer;
                    color: var(--black-90);
                    background: white;

                    &:disabled {
                        color: var(--black-50);
                        display: none;
                    }
                }
                
                &:hover {
                    border-color: var(--black-50);
                }

                &:focus {
                    outline: none;
                    border-color: var(--dark-blue);
                }
            }

            & > svg {
                position: absolute;
                right: 15px;
                height: 12px;
                width: 12px;
                top: 50%;
                margin: -6px 0 0;
                pointer-events: none;
                z-index: 9;
                fill: var(--black-50);
            }
        }

        .err-msg-box{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            i{
                width: 14px;
                height: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
            }

            svg{
                width: 10.5px;
                height: 10.5px;
                fill:var(--alert);
            }
        }

        .err-msg {
            margin: 4px 0;
            font-size: 12px;
            font-weight: 500;
            color: var(--alert);
            font-family: var(--font-ms);
            text-align: right;
        }

        &.is-error &--wrap,
        &.is-error &--select {
            select,
            input {
                border-color: var(--alert);
            }
        }

        &.is-success &--wrap,
        &.is-success &--select {
            select,
            input {
                border-color: var(--green);
            }
        }

        &.is-error-important{
            select,
            input {
                border-color: var(--alert) !important;
            }
        }

        &.is-error &--icon-wrap {
            border-color: var(--alert);
        }

        &.is-success &--icon-wrap {
            border-color: var(--green);
        }

        &.is-disabled {
            cursor: not-allowed;

            input,
            select {
                pointer-events: none;
                opacity: .65;
            }

        }

        &.is-ready-only &--icon-wrap,
        &.is-ready-only input {
            border: none !important;
            padding: 0;
            background-color: transparent !important;
        }


                        
        .clear {
            margin-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -8px;
            cursor: pointer;

            svg {
                height: 80%;
                width: 80%;
                display: block;
                fill: var(--black-75);
            }
        }
    }
    
</style>