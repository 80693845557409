import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setup } from 'axios-cache-adapter';
import Notifications from '@kyvg/vue3-notification';
import { plugin as VueTippy } from 'vue-tippy';
import mitt from 'mitt';
import moment from 'moment';
import SlideUpDown from 'vue3-slide-up-down';
import SvgIcon from '@/components/ui/SvgIcon.vue';

import './utils/mockData';
const emitter = mitt();

const VueUploadComponent = require('vue-upload-component');
import 'tippy.js/dist/tippy.css' // optional for styling

let ajax_url = '/';
// if (process.env.NODE_ENV === 'development') ajax_url = 'http://dev.motom.com';
// else if (process.env.NODE_ENV === 'test') ajax_url = 'https://www.dev.motom.me:8443';

const ajax = setup({
    baseURL: ajax_url,
    cache: {
        maxAge: 20 * 60 * 1000, // 20min
        exclude: {
            query: false
        }
    },

    withCredentials: true,

    // headers: {
    //     'x-requested-with': 'XMLHttpRequest',
    // },
});



const app = createApp(App);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(VueTippy);

  
app.component('file-upload', VueUploadComponent);
app.component('slide-up-down', SlideUpDown);
app.component('svg-icon', SvgIcon);

// app.provide('$ajax', ajax);
app.config.globalProperties.$ajax = ajax;
app.config.globalProperties.EventBus = emitter;
app.config.globalProperties.$filters = {
    momentFormat(date, format) {
        if (date)
            return moment(date).format(format);
        else return '---';
    }
};
app.mount('#app');

