<template>
    <div class="brand-posts">
        <div class="head-info">
            <div class="title">
                <h2>
                    <span>Branded Content</span>
                    <p class="small-text" >Insights available 7 days after posting. Data updated daily.</p>
                
                    <div class="select-tags" :class="{ 'is-loading': is_loading_tags }">
                        <h3>Select Tags:</h3>
                        <div class="tags">
                            <Checkbox size="small" :key="'all'" :label_text="'All'" :checked="selected_tags.length === 0"
                                @click="selectAll" />
                            <Checkbox size="small" v-for="(v, k) in tags" :key="k" :label_text="v.text"
                                :checked="selected_tags.filter((t) => t.id === v.id).length !== 0" @click="selectTag(v)" />

                        </div>
                        <Button class="js-open-tags new-btn" color="dark-blue" size="24" @click="$refs.pop.open();"><svg-icon name="plus" /></Button>
                        <i class="open-popup js-open-tags" @click="$refs.pop.open(true);"><svg-icon name="edit" /></i>

                        <DropdownHashtag :tags="tags" :selected_tags="selected_tags" @select-all="selectAll" @select-tag="selectTag" ref="pop" />

                    </div>
                </h2>
                <div class="update-time" v-if="!stat_loading">
                    <time>Updated: {{ daysAgo(updated_time) }}</time>
                    <DatePicker class="btn" @update-date="updateDate" :date_range="date_range"
                        :maxDate="$store.state.user_data.contract_status ? $store.state.user_data.contract_end_time : null"
                        :minDate="$store.state.user_data.contract_status ? $store.state.user_data.contract_start_time : null" />
                </div>

                <Button class="import-btn" size="32" color="dark-blue" @click="is_show_import_modal = true"><svg-icon name="plus" /> Import</Button>
            </div>

        </div>

        <div class="content-box">
            <ContentSummary :chart_head="chart_head1">
                <template #head>
                    <header>
                        <h3><svg-icon name="tiktok-pure" />{{ user_data.tiktok_handle }}</h3>
                        <p><strong>Posts ({{ tiktok_posts_count }} total)</strong></p>
                    </header>
                </template>
            </ContentSummary>
            <ContentSummary :chart_head="chart_head2">
                <template #head>
                    <header>
                        <h3><svg-icon name="instagram" />@{{ user_data.instagram_handle }}</h3>
                        <p><strong>Posts ({{ instagram_posts_count }} total)</strong></p>
                    </header>
                </template>
            </ContentSummary>
            <ContentSummary :chart_head="chart_head3">
                <template #head>
                    <header>
                        <p><strong>Story ({{ story_count }} total)</strong></p>
                        <p><span>Story media metrics are only available for 24 hours.  If you add a hashtag or mention to follow after a story has run, the metrics will not be available.</span></p>
                    </header>
                </template>
            </ContentSummary>
            <TopBrandPost title="Branded Posts" :had_tag="false" :posts="top_post.data" :posts_value="top_post"
                @updateSort="updateSort" @selectTop="selectTopNavi" @callApiAgain="callApiAgain"
                :had_navi="now_tab === 0" :now_tab="top_post.top_menu_index === 0 ? 1 : 0" @changeIgTag="changeIgTag">
                <!-- <template #button v-if="now_tab !== 0 && $store.state.user_data.contract_status">
                    <Button class="import-btn" size="32" color="dark-blue"
                        @click="is_show_import_modal = true"><svg-icon name="plus" /> Import</Button>
                </template> -->
                <!-- <template #tabs v-if="top_post.top_menu_index === 1">
                    <ul class="tag-menus">
                        <li>
                            <Button :class="{'disabled': menu_tab !== 1}" @click="menu_tab = 1" :style="'border-square'" size="32" color="dark-blue">
                                Posts ({{ instagram_posts_count }})
                            </Button>
                        </li>
                        <li>
                            <Button :class="{'disabled': menu_tab !== 2}" @click="menu_tab = 2" :style="'border-square'" size="32" color="dark-blue">
                                Reels ({{ instagram_posts_count }})
                            </Button>
                        </li>
                        <li>
                            <Button :class="{'disabled': menu_tab !== 3}" @click="menu_tab = 3" :style="'border-square'" size="32" color="dark-blue">
                                Stols
                            </Button>
                        </li>
                    </ul>
                </template> -->
            </TopBrandPost>
        </div>


    </div>
    <!-- <Modal :is_show="is_show_modal" @click-bg="is_show_modal = false" class="hashtag-modal">
        <header class="tag-header">Update Tags <i @click="is_show_modal = false"><svg-icon name="close" /></i></header>
        <Hashtag :only_tag="true" />
    </Modal> -->

    <Modal :is_show="is_show_import_modal" @click-bg="is_show_import_modal = false" class="hashtag-modal import-modal">
        <header class="tag-header"><svg-icon class="ico-title" :name="soc_tab === 1 ? 'tiktok-pure' : 'instagram'" />Paste {{ soc_tab === 1 ? 'TikTok' : 'Instagram' }} Link <i @click="is_show_import_modal = false"><svg-icon name="close" /></i></header>
        <ul class="tag-menus">
            <li>
                <Button :class="{'disabled': soc_tab !== 1}" @click="soc_tab = 1" :style="'border-square'" size="32" color="dark-blue">TikTok</Button>
            </li>
            <li>
                <Button :class="{'disabled': soc_tab !== 2}" @click="soc_tab = 2" :style="'border-square'" size="32" color="dark-blue">Instagram</Button>
            </li>

        </ul>
        <ImportUrl :which_handle="soc_tab === 1 ? 'TikTok' : 'Instagram'" :anchor_creator_id="user_data.id" :type="soc_tab" @refrash="importAndRefrash" />
    </Modal>
</template>

<script>
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import Button from '@/components/ui/Button.vue';
import NoData from './creator/NoData.vue';
// import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import ContentSummary from '@/components/summary/ContentSummary.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import TopBrandPost from '@/components/TopBrandPost.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import Modal from './ui/Modal.vue';
// import Hashtag from './Hashtag.vue';
import ImportUrl from './ImportUrl.vue';
import { HandleNum, HandleNumSpec } from '@/utils/SwitchFun';
import { SwitchTopSort, get_all_post_data } from '@/utils/ContentSwitchFun.js';
import formatDateDaysAgo from '@/utils/formatDateDaysAgo';
import DropdownHashtag from '@/components/DropdownHashtag.vue';

import moment from 'moment';

export default {
    name: 'brand-posts',

    data() {
        return {
            el: null,
            now_tab: 0,
            soc_tab: 1,
            menu_tab: 1,
            sticker_tab: [
                { name: 'All Platform', index: 0 },
                { name: 'TikTok', index: 1, svg: 'tiktok-pure' },
                { name: 'Instagram', index: 2, svg: 'instagram' },
            ],
            is_show_import_modal: false,
            is_load_nextpage: false,
            is_end: false,
            posts: [],
            tags: [],
            selected_tags: [],
            is_loading_tags: true,
            top_post: JSON.parse(JSON.stringify(get_all_post_data)),
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
            stat_loading:false,
            updated_time: '',
            chart_head1: [
                { name: 'Views', value: '---', field: 'total_view', trend: 'page_views_trend', show: true, content: `Total views for the creator’s matched posts. <br><br>TikTok views are counted when a video plays, including autoplays and loops. <br><br>Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.` },
                { name: 'Engagements', value: '---', field: 'engagements', trend: 'page_views_trend', show: true, content: `Number of likes, comments, saves, and shares, compared to overall reach, for the creator's matched posts.` },
                { name: 'Engagement Rate', value: '---', field: 'engagement_rate', trend: 'page_views_trend', show: true, content: `Total number of reactions, comments, and saves of a matched post / number of followers. This counts all reactions and comments, including ones that were removed or deleted.` },
            ],
            chart_head2: [
                { name: 'Impressions', value: '---', field: 'impressions', trend: 'page_views_trend', show: true, content: `Total views for the creator’s matched posts. <br><br>TikTok views are counted when a video plays, including autoplays and loops. <br><br>Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.` },
                { name: 'Engagements', value: '---', field: 'engagements', trend: 'page_views_trend', show: true, content: `Number of likes, comments, saves, and shares, compared to overall reach, for the creator's matched posts.` },
                { name: 'Engagement Rate', value: '---', field: 'engagement_rate', trend: 'page_views_trend', show: true, content: `Total number of reactions, comments, and saves of a matched post / number of followers. This counts all reactions and comments, including ones that were removed or deleted.` },
            ],
            chart_head3: [
                { name: 'Impressions', value: '---', field: 'impressions', trend: 'page_views_trend', show: true, content: `Total views for the creator’s matched posts. <br><br>TikTok views are counted when a video plays, including autoplays and loops. <br><br>Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.` },
                { name: 'Engagements', value: '---', field: 'engagements', trend: 'page_views_trend', show: true, content: `Number of likes, comments, saves, and shares, compared to overall reach, for the creator's matched posts.` },
                { name: 'Engagement Rate', value: '---', field: 'engagement_rate', trend: 'page_views_trend', show: true, content: `Total number of reactions, comments, and saves of a matched post / number of followers. This counts all reactions and comments, including ones that were removed or deleted.` },
            ],
            tiktok_posts_count: 0,
            instagram_posts_count: 0,
            story_count: 0,
        };
    },

    components: {
        CreatorSmallCard,
        NoData,
        Button,
        ContentSummary,
        TopBrandPost,
        DatePicker,
        Checkbox,
        Modal,
        // Hashtag,
        ImportUrl,
        DropdownHashtag,
    },


    props: {
        user_data: {
            type: Object,
            default: null,
        },
        date_range: {
            type: Object,
            default: null,
        },
    },

    methods: {
        daysAgo(date) {
            return formatDateDaysAgo(date);
        },

        // handleChartHead() {
        //     if (this.now_tab === 0) {
        //         this.chart_head[0].content = `Total views for the creator’s matched posts. <br><br>TikTok views are counted when a video plays, including autoplays and loops. <br><br>Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.`;
        //         this.chart_head[1].show = true;
        //         this.chart_head[1].name = 'TikTok Engagement';
        //         this.chart_head[2].show = true;
        //         this.chart_head[2].name = 'Instagram Engagement';
        //     } else if (this.now_tab === 1) {
        //         this.chart_head[0].content = `TikTok views are counted when a video plays, including autoplays and loops.`
        //         this.chart_head[1].show = true;
        //         this.chart_head[2].show = false;
        //         this.chart_head[1].name = 'Engagement';
        //     } else {
        //         this.chart_head[0].content = `Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.`
        //         this.chart_head[1].show = false;
        //         this.chart_head[2].show = true;
        //         this.chart_head[2].name = 'Engagement';
        //     }
        // },

        changeIgTag(index) {
            this.top_post.ig_post_type = index;
            if (index === 0) {
                this.top_post.data = this.top_post.all_data.posts;
            } else if (index === 1) {
                this.top_post.data = this.top_post.all_data.reels;
            } else {
                this.top_post.data = this.top_post.all_data.story;
            }
        },

        clickTab(index) {
            this.now_tab = index;
            // this.handleChartHead();

            switch (index) {
                case 1:
                    this.top_post.top_menu_index = 0;
                    break;
                case 2:
                    this.top_post.top_menu_index = 1;
                    break;
            }

            this.top_post.top_menu_index = index === 1 ? 0 : index === 2 ? 1 : this.top_post.top_menu_index;
            this.getStatistic();
            this.getPosts();
        },

        selectAll() {
            this.selected_tags = [];
            this.getStatistic();
            this.getPosts();
        },

        selectTag(tag) {
            const index = this.selected_tags.findIndex((t) => t.id === tag.id);
            if (index === -1) {
                this.selected_tags.push(tag);
            } else {
                this.selected_tags.splice(index, 1);
            }

            this.getStatistic();
            this.getPosts();
        },

        getTags() {
            //client api not yet
            console.log('getTags')
            if(this.$store.state.user_data){
                this.$ajax(`creator/get-social-post-filter`, {
                    method: 'get',
                }).then(res => {
                    this.is_loading = false;
                    console.log(res)

                    const data = res.data.data;
                    if (!data) return;

                    this.tags = data.social_post_tag_texts;
                    this.top_post.tag_select = this.tags;
                    this.is_loading_tags = false;

                }).catch(err => {
                    console.log(err)
                })
            }
            this.is_loading_tags = false;
        },

        selectTopNavi(index) {
            this.top_post.top_menu_index = index;
            this.getPosts();
        },

        updateSort(id) {
            let getVal = SwitchTopSort(id.id);
            this.top_post.sort.sort_key = getVal.key;
            this.top_post.sort.sort_val = getVal.val;
            this.top_post.sort_index = this.top_post.sort_select.findIndex(obj => obj.id === id.id);

            this.getPosts();
        },

        getStatistic() {
            this.stat_loading=true;
            let sp = '';

            switch (this.now_tab) {
                case 1:
                    sp = 'tikTok';
                    break;
                case 2:
                    sp = 'instagram';
                    break;
            }

            const tag_ids = [];
            this.selected_tags.forEach((tag) => {
                tag_ids.push(tag.id);
            });

            const params_data = {
                anchor_creator_id: this.user_data.id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                social_platform: sp,
                tag_ids: tag_ids,
            }

            //client api not yet
            this.$ajax(`/creator/get-social-account-brand-content-posts-statistic?user_id=${this.user_data.id}`, {
                method: 'get',
                // params: params_data,
                withCredentials: true,
            }).then(res => {
                let vals = res.data.data;
                this.updated_time = vals.last_update_time;
                // console.log('vals', vals)

                this.chart_head1.forEach(item => {
                    switch (item.field) {
                        case 'total_view':
                            item.value = HandleNum(vals.tiktok.views);
                            break;
                        case 'engagements':
                            item.value = HandleNum(vals.tiktok.engagements);
                            break;
                        case 'engagement_rate':
                            item.value = vals.tiktok.engagement_rate ? vals.tiktok.engagement_rate + '%' : '---';
                            break;
                    }
                });

                this.tiktok_posts_count = 3;

                this.chart_head2.forEach(item => {
                    switch (item.field) {
                        case 'impressions':
                            item.value = HandleNum(vals.instagram.impressions);
                            break;
                        case 'engagements':
                            item.value = HandleNum(vals.instagram.engagements);
                            break;
                        case 'engagement_rate':
                            item.value =  vals.instagram.engagement_rate ? vals.instagram.engagement_rate + '%' : '---';
                            break;
                    }
                });

                this.instagram_posts_count = 2;

                this.chart_head3.forEach(item => {
                    switch (item.field) {
                        case 'impressions':
                            item.value = HandleNum(vals.story.impressions);
                            break;
                        case 'engagements':
                            item.value = HandleNum(vals.story.engagements);
                            break;
                        case 'engagement_rate':
                            item.value =  vals.story.engagement_rate ? vals.story.engagement_rate + '%' : '---';
                            break;
                    }
                });

                this.story_count = vals.story.posts_count;

                // this.chart_head.forEach(item => {
                //     if (item.field === 'total_engagement' || item.field === 'tiktok_total_engagement' || item.field === 'instagram_total_engagement') {
                //         item.value = HandleNumSpec(vals[item.field]) !== '---' ? `${HandleNumSpec(vals[item.field])}%` : HandleNumSpec(vals[item.field]);
                //     } else {
                //         item.value = HandleNum(vals[item.field]);
                //     }

                // });
                this.stat_loading = false;
            }).catch(err=>{
                this.stat_loading = false;
            })
            // this.stat_loading=false;
        },

        callApiAgain() {
            this.getPosts();
        },

        updateDate(date) {
            console.log('u', date)
            this.start_date = moment(date.start_date).format('YYYY-MM-DD');
            this.end_date = moment(date.end_date).format('YYYY-MM-DD');
            this.getStatistic();
            this.getPosts();
        },

        getPosts(is_next = false) {
            console.log('is_next => ' + is_next)
            this.top_post.loading = true;
            const sp = this.top_post.top_menu_index === 0 ? 'tikTok' : 'instagram';
            const tag_ids = [];
            this.selected_tags.forEach((tag) => {
                tag_ids.push(tag.id);
            });

            if (is_next) {
                this.top_post.sort.page += 1;
            } else {
                this.top_post.sort.page = 1;
            }

            const params_data = {
                anchor_creator_id: this.user_data.id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                sort_key: this.top_post.sort.sort_key,
                sort_val: this.top_post.sort.sort_val,
                page: this.top_post.sort.page,
                per_page: this.top_post.sort.per_page,
                with_extra_count: '',
                social_platform: sp,
                tag_ids: tag_ids,
                brand_post_only: 1,
            }

            //client api not yet
            this.$ajax(`creator/get-social-account-posts?user_id=${this.user_data.id}`, {
                //params: params_data,
                method: 'get',
            }).then(res => {
                let post_data = res.data.data.social_posts;
                this.top_post.all_data = post_data;
                
                if (this.top_post.top_menu_index === 0) {
                    this.top_post.data = post_data.tkData;
                } else {
                    this.top_post.data = post_data.posts;
                }

                this.top_post.ig_menu[0].value = post_data.posts.length;
                this.top_post.ig_menu[1].value = post_data.reels.length;
                this.top_post.ig_menu[2].value = post_data.story.length;
                //this.instagram_posts_count = post_data.posts.length;

                this.top_post.loading = false;
                this.is_load_nextpage = false;
                this.is_end = true;
            });
            this.top_post.loading = false;
                this.is_load_nextpage = false;
                this.is_end = true;
        },

        goSetting() {
            window.location.href = '/settings/content-listening';
        },

        importAndRefrash() {
            this.getPosts();
            this.is_show_import_modal = false;
        }

    },

    mounted() {
        if (this.date_range) {
            this.start_date = this.date_range.start_date;
            this.end_date = this.date_range.end_date;
        }

        this.EventBus.on('updated-all-post-tag', (tags) => {
            if (this.selected_tags) this.selected_tags.forEach((tag, tag_index) => {
                const index = tags.findIndex((o) => o.id === tag.id);
                if (index === -1) {
                    // remove this tag
                    this.selected_tags.splice(tag_index, 1);
                }
            });

            this.tags = tags;
        });

        this.getTags();
        this.getStatistic();
        this.getPosts();
        // console.log('$store.state.user_data', this.$store.state.user_data)
    },


};
</script>

<style lang="scss">
.brand-posts {
    margin-top: 16px;

    .mx-datepicker-content {
        margin-left: 0px;
    }

    .js-open-tags svg {
        pointer-events: none;
    }

    .tag-menus {
        display: flex;
    }

    .sticker-tab-box-inside {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .sticker-tab-box {
        margin: 0;
        flex: 1;
    }

    .new-btn {
        position: relative;
        border-radius: 6px !important;
        height: 24px;
        width: 24px;
        padding: 0;
        margin-right: 8px;
        max-width: 24px;
        border: none;

        svg {
            fill: white;
            height: 12px;
            width: 12px;
            margin-right: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .import-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 6px !important;
        margin: 0 0 0 10px;
        font: var(--font-med-12);

        svg {
            fill: white;
            height: 10px;
            width: 10px;
            margin-right: 6px;
        }
    }

    .head-info {
        display: block;
        margin: 0 0 16px;

        .title {
            margin-bottom: 16px;
            display: flex;
            align-items: center;

            & > h2 {
                flex: 1;

                & > span {
                    font: var(--font-demi-24);
                    color: var(--black-90);
                    margin: 0;
                    flex: 1;
                    display: block;
                }

                & > p {
                    font: var(--font-med-10);
                    font-family: var(--font-ms);
                    color: var(--black-75);
                    margin: 0 0 8px;
                }
            }


        }

        // .bot {
        //     display: flex;
        //     align-items: center;
        // }

        .update-time {
            display: flex;
            align-items: center;
            height: 32px;

            time {
                font-size: 12px;
                color: var(--black-75);
                padding-right: 8px;
            }
        }


        .select-tags {
            display: inline-flex;
            align-items: center;
            margin: 0;
            position: relative;

            &.is-loading {
                pointer-events: none;
                opacity: 0.5;
            }

            & > h3 {
                padding: 0 4px;
                font: var(--font-med-12);
                color: var(--black-90);
            }

            & > .tags {
                display: flex;
                align-items: center;
                padding-right: 12px;

                .ui-checkbox {
                    margin: 0 0 0 16px;
                    font: var(--font-med-12);
                    color: var(--black-90);
                }
            }

            & > .open-popup {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                border-radius: 6px;
                cursor: pointer;
                margin-left: 4px;

                svg {
                    fill: var(--black-90);
                    height: 14px;
                    width: 14px;
                }

                &:hover {
                    background-color: var(--bg-02);

                    svg {
                        fill: var(--dark-blue);
                    }
                }
            }
        }



        // .userhandle {
        //     font: var(--font-demi-20);
        //     line-height: 32px;
        //     color: var(--black-90);
        //     flex: 1;
        //     margin: 16px 0;
        // }
    }

    .content-box {
        display: block;

        // .small-text {
        //     font: var(--font-med-10);
        //     font-family: var(--font-ms);
        //     color: var(--black-75);
        //     margin: 0 0 8px;

        //     .link {
        //         color: var(--blue);
        //         cursor: pointer;
        //     }
        // }

        .top-brand-post-box .post-list-box {
            background-color: white;
            padding: 0;
        }

    }

    // .creator-no-data {
    //     max-width: 480px;
    // }

    // .post-list {
    //     background-color: var(--bg-02);
    // }
}
</style>
