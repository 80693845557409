<template>
  <ModalSide class="collect-product-page" ref="modal">
    <Loading class="static" v-if="is_loading" />

    <div class="product-data-wrap" v-else :show_bar="true">

      <header>
        <figure class="img-box img-box-white" v-if="item.cover_image_url">
          <img :src="item.cover_image_url" @error="imageError($event)" :alt="item.name" />
        </figure>
        <figure class="img-box" v-else>
          <i><img v-if="item.first_4_product_thumbnails && item.first_4_product_thumbnails[0]"
              :src="require('@/assets/images/products/' + item.first_4_product_thumbnails[0])" :alt="item.name" /></i>
          <i><img v-if="item.first_4_product_thumbnails && item.first_4_product_thumbnails[1]"
              :src="require('@/assets/images/products/' + item.first_4_product_thumbnails[1])" :alt="item.name" /></i>
          <i><img v-if="item.first_4_product_thumbnails && item.first_4_product_thumbnails[2]"
              :src="require('@/assets/images/products/' + item.first_4_product_thumbnails[2])" :alt="item.name" /></i>
          <i><img v-if="item.first_4_product_thumbnails && item.first_4_product_thumbnails[3]"
              :src="require('@/assets/images/products/' + item.first_4_product_thumbnails[3])" :alt="item.name" /></i>
        </figure>

        <div class="middle">
          <tippy class="name" :delay="[0, 20]" placement="right" :content="item.name">{{ item.name }}</tippy>
          <tippy class="desc" :delay="[0, 20]" placement="right" :content="desc_text">{{ desc_text }}</tippy>
          <div class="line">
            By
            <figure class="avatar">
              <img :src="getUserImage(item.userProfile.avatar_full_url)" />
            </figure>
            <tippy class="creat-name">{{ item.userProfile.nickname }}</tippy>
            <span class="row row-count">
              <div class="big">{{ item.product_count }}</div>
              <div class="row-name"> {{ item.product_count < 2 ? 'product' : 'products' }}</div>
            </span>
            <span class="row row-date">
              <div class="row-name">Last update:</div>{{ $filters.momentFormat(item.last_item_update_at, 'll') }}
            </span>
          </div>
          <div class="hint" v-if="is_show_hint">
            <SvgIcon name="alert" class="disabled-alert" />
            This collection isn't available in the storefront anymore. It no longer generate any sales or analytics.
          </div>
          <div class="hint" v-else-if="item.deleted_at">
            <SvgIcon name="alert" class="disabled-alert" />
            This Collection was deleted by the creator and cannot generate any new performance metrics including sales.
          </div>
          <div class="hint hint-blue" v-else-if="!item.is_publish">
            <SvgIcon name="alert" class="disabled-alert" />
            This Collection is not currently active and cannot generate any performance metrics.
          </div>
        </div>
        <div class="icon-box">
          <SvgIcon name="close" @click.native="$refs.modal.close();" />
        </div>
      </header>

      <div class="collect-product-page-title">
        <h3>All Products&ensp;({{ data.length > 0 ? item.product_count : 0 }})</h3>
        <div class="right">
          <DatePicker v-show="!is_loading" @update-date="updateDate" :date_range="date_range" ref="date_picker" />
          <Dropdown v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'"
            :default_value="sort_index" @update-dropdown="updateSort" :key="drop_key" />
        </div>
      </div>

      <Scrollbar :show_bar="true" ref="tableScrollBox">

        <NoData v-if="data.length === 0 && !this.isFilter" :image="require('@/assets/images/data.png')" :title="''"
          :text="'This creator has not added any products to their Collection yet.'" />

        <Table v-else>
          <template #head>
            <tr>
              <th class="title"><span>Product</span></th>
              <th><span>Added Date</span></th>
              <th>
                <CollectionTh :name="'views'" :title="'Product Views'" :sort_value="sort" @sort="sortFun"
                  :content="'Cumulative product views in the collection for the time period selected.'" />
              </th>
              <th>
                <CollectionTh :name="'clicks'" :title="'Clicks'" :sort_value="sort" @sort="sortFun"
                  :content="'Cumulative link clicks on products in the Collection during the time period selected.'" />
              </th>
              <th>
                <CollectionTh :name="'sales'" :title="'Sales'" :sort_value="sort" @sort="sortFun"
                  :content="'Total number of sales driven by the Collection during the time period selected.'" />
              </th>
              <th class="w-100-p">
                <CollectionTh :name="'sales_value'" :title="'Sales Value'" :sort_value="sort" @sort="sortFun"
                  :content="'Gross transaction value driven by the Collection during the time period selected.'" />
              </th>

            </tr>
          </template>

          <template #body>
            <template v-if="data.length === 0 && this.isFilter">
              <tr>
                <td colspan="13">
                  <NoData />
                </td>
              </tr>
            </template>

            <tr v-else v-for="(row, index) in data" :key="index" :class="{ selected: row.is_selected }">
              <td class="title">
                <div class="title-content">
                  <img :src="require('@/assets/images/products/' + row.client_img)" @error="imageError($event)">
                  <div class="right">
                    <tippy class="name" :delay="[0, 20]" placement="right" :content="showFullText(row, 'name')"
                      @mouseover="isEllipsisActive($event, row)"><span>{{ handleName(row.products &&
      row.products.name
      ? row.products.name : null) }}</span>
                    </tippy>
                    <tippy class="small" :delay="[0, 20]" placement="right" :content="showFullText(row, 'brand')"
                      @mouseover="isEllipsisActive($event, row)"><span>{{ handleName(row.products &&
      row.products.merchant_name
      ? row.products.merchant_name : null) }}</span>
                    </tippy>
                    <div class="price">${{ row.products.price }}</div>
                  </div>
                </div>
              </td>

              <td>
                <span>{{ row.created_at }}</span><br />
                <span class="del" v-if="row.deleted_at !== '---'">Removed: {{ row.deleted_at }}</span>
              </td>

              <td><span>{{ row.views }}</span></td>
              <td><span>{{ row.clicks }}</span></td>
              <td><span>{{ row.sales }}</span></td>
              <td class="w-100-p">
                <span>${{ row.sales_value }}</span><br />
                <span class="small" v-if="row.cid">{{ row.products && row.products.currency ? row.products.currency :
      "USD" }}</span>
              </td>
            </tr>

            <td colspan="13" v-if="is_next_loading">
              <Loading class="static" />
            </td>
          </template>

          <template #foot>
            <tr v-if="data.length > 0">
              <td :colspan="2"> Total products: {{ total ? total.total_product_count : 0 }} </td>

              <td>{{ total.total_views }}</td>
              <td>{{ total.total_clicks}}</td>
              <td>{{ total.total_sales }}</td>
              <td>{{ total.total_sale_value }}</td>
            </tr>
          </template>
        </Table>
      </Scrollbar>
    </div>
  </ModalSide>
</template>

<script>
import ModalSide from '@/components/ui/ModalSide.vue';
import Content from "@/components/ui/Content.vue";
import Scrollbar from "@/components/ui/Scrollbar.vue";
import Table from "@/components/ui/Table.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import Loading from "@/components/ui/Loading.vue";
import NoData from "@/components/creator/NoData.vue";
import moment from "moment";
import Layout from "@/components/creator/CollectionImage.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import Dropdown from '@/components/ui/Dropdown.vue';
import CollectionTh from '@/components/creator/CollectionProductTh.vue';
import { Sort_select_coll_pod_list, SwitchSort, ImageError, HandleNum, HandleName, PickSortIndex } from '@/utils/SwitchFun';
import formatMoney from '@/utils/formatMoney';

export default {
  name: "Collections-List",

  data() {
    return {
      item: {},
      data_info: null,
      is_loading: true,
      sort_select: Sort_select_coll_pod_list,
      sort_index: 0,
      drop_key: 0,
      date_range: {
        start_date: moment().add(-30, "days").format("YYYY-MM-DD"),
        end_date: moment().add(-1, "days").format("YYYY-MM-DD"),
      },
      sort: {
        sort_key: 'views',
        sort_val: 'desc',
        page: 1,
        per_page: 50
      },
      data: [],
      total: {},
      isFilter: false,
      is_show_hint: false,
      is_end: false,
      is_next_loading: false,
    };
  },

  props: {
  },

  computed: {
    desc_text() {
      return this.item.description ? this.item.description : `The creator did not include a description for this Collection.`;
    }
  },

  components: {
    Content,
    Scrollbar,
    Table,
    NoData,
    Loading,
    SvgIcon,
    Layout,
    DatePicker,
    ModalSide,
    Dropdown,
    CollectionTh
  },

  watch: {

  },

  methods: {
    imageError: ImageError,
    handleNum: HandleNum,
    handleName: HandleName,

    getUserImage(avatar) {
      return require(`@/assets/images/user/${avatar}`);
    },

    openModal() {
      let vi = this;
      this.EventBus.off('open-collect-product-modal');
      this.EventBus.on('open-collect-product-modal', (obj) => {

        obj.data.products.forEach(item => {
          item.client_img = item.products.images;
          item.created_at = vi.$filters.momentFormat(item.created_at, "ll");
          item.deleted_at = vi.$filters.momentFormat(item.deleted_at, "ll");
          item.views = vi.handleNum(item.views);
          item.clicks = vi.handleNum(item.clicks);
          item.sales = vi.handleNum(item.sales);
          item.sales_value = item.sales_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        });
        this.data = obj.data.products;
        this.total = {
          total_product_count: obj.data.product_count,
          total_sales: obj.data.sales,
          total_views: obj.data.views,
          total_clicks: obj.data.product_click,
          total_sale_value: obj.data.sales_value,
        }
        this.item = obj.data;
        this.item.product_count = !this.item.product_count ? 0 : this.item.product_count;
        this.data_info = obj.data_info;
        this.date_range = obj.date_range;
        this.is_show_hint = obj.is_show_hint;
        this.sort.page = 1;
        //this.fetchData();
        this.$refs.modal.open();
        this.handleFakeLoad();
      });
    },

    handleFakeLoad() {
      this.is_loading = true;
      setTimeout(e => {
        this.is_loading = false;
      }, 150);
    },

    updateSort(id) {
      let getVal = SwitchSort(id.id);
      this.sort.sort_key = getVal.key;
      this.sort.sort_val = getVal.val;
      this.sort.page = 1;

      this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
      this.drop_key += 1;
      this.handleFakeLoad();
    },

    sortFun(key) {
      if (key === this.sort.sort_key) {
        this.sort.sort_val = this.sort.sort_val === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.sort_key = key;
        this.sort.sort_val = 'asc';
      }

      //(sort the table can chnage dropdown)
      this.sort.page = 1;
      this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
      this.drop_key += 1;
      this.handleFakeLoad();
    },

    showFullText(row, type) {
      let text = '';
      if (row.is_ellipsis_active) {
        switch (type) {
          case 'name':
            text = row.products.name;
            break;
          case 'brand':
            text = row.products.merchant_name;
            break;
        }
      }
      return text;
    },

    isEllipsisActive(e, row) {
      row.is_ellipsis_active = (e.target.offsetWidth < e.target.scrollWidth);
    },

    updateDate(obj) {
      this.date_range = {
        start_date: moment(obj.start_date).format('YYYY-MM-DD'),
        end_date: moment(obj.end_date).format('YYYY-MM-DD'),
      }
      //resrt sort value
      this.sort.sort_key = '';
      this.sort.sort_val = 'asc';
      this.sort_index = 0;
      this.drop_key += 0;
      this.sort.page = 1;
      //call api
      this.handleFakeLoad();
    },

  },

  mounted() {
    this.openModal();

  },

};
</script>

<style lang="scss">
@mixin textOver() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
}

.collect-product-page {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  font-weight: 400 !important;

  header {
    display: flex;
    color: var(--black-90);
    overflow: hidden;

    .img-box {
      position: relative;
      width: 170px;
      height: 170px;
      min-width: 170px;
      background-color: var(--bg-03);
      border-radius: 2px;

      &.img-box-white {
        background-color: var(--bg-01);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      i {
        width: 50%;
        height: 50%;
        display: block;
        position: absolute;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        &:nth-child(1) {
          top: 0;
          left: 0;
          border-bottom: 1px solid var(--bg-01);
          border-right: 1px solid var(--bg-01);
        }

        &:nth-child(2) {
          top: 0;
          right: 0;
          border-bottom: 1px solid var(--bg-01);
        }

        &:nth-child(3) {
          bottom: 0;
          left: 0;
          border-right: 1px solid var(--bg-01);
        }

        &:nth-child(4) {
          bottom: 0;
          right: 0;
        }
      }
    }

    .middle {
      margin: 0 16px;
      overflow: hidden;
      flex: 1;

      .name {
        font: var(--font-fg-30);
        @include textOver();
      }

      .desc {
        font-size: 16px;
        margin-top: 6px;
        @include textOver();
      }

      .line {
        margin: 20px 0;
        color: var(--dark-blue);
        font: var(--font-med-14);
        display: flex;
        align-items: center;

        .avatar {
          margin: 0 5px 0 8px;
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            object-fit: contain;
            overflow: hidden;
            border-radius: 50%;
          }
        }

        .creat-name {
          color: var(--black-90);
          @include textOver();
          width: unset;
        }

        .row {
          display: flex;

          .big {
            margin-right: 4px;
          }

          &.row-count {
            margin: 0 11px;

          }

          &.row-date {
            width: 200px;
            white-space: nowrap;
            color: var(--black-90);

            .row-name {
              margin-right: 4px;
              font-family: var(--font);
            }
          }

          .row-name {
            font-family: var(--font);
            color: var(--black-60);
          }
        }

      }
    }

    .hint {
      margin-top: 16px;
      padding: 8px;
      font: var(--font-med-12);
      background: var(--bg-11);
      color: var(--black-75);
      display: flex;
      align-items: center;

      .disabled-alert {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }

      &.hint-blue {
        background: var(--light-blue);
      }
    }

    .icon-box {
      .svg-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }

  .collect-product-page-title {
    font: var(--font-demi-16);
    line-height: 32px;
    color: var(--black-90);
    display: flex;
    align-items: center;
    margin: 16px 0;

    h3 {
      flex: 1;
    }

    .right {
      display: flex;
      align-items: center;

      .ui-date-picker {
        margin-right: 10px;
      }
    }


  }

  .loading.static {
    margin-top: 48px;
  }

  .ui-table {
    th {
      white-space: nowrap;

      .title-content {
        max-width: 380px;
      }
    }

    td {
      .title-content {
        max-width: 380px;
        display: flex;
        align-items: center;

        img {
          width: 59px;
          height: 59px;
          min-width: 59px;
          object-fit: contain;
          margin-right: 14px;
        }

        .right {
          overflow: hidden;

          .name {
            span {
              font: var(--font-med-16);
              font-weight: 400;
              @include textOver();
              color: var(--black-90);
            }
          }

          .small {
            span {
              font: var(--font-med-14);
              font-weight: 400;
              color: var(--black-75);
            }
          }

          .price {
            font: var(--font-demi-14);
            font-weight: normal;
            color: var(--black-75);
            margin-top: 4px;
          }
        }
      }

      .del {
        color: var(--red);
        font-size: 12px;
        font-weight: 400;
      }

      .small {
        color: var(--black-50);
        @include textOver();

        span {
          @include textOver();
        }
      }
    }
  }
}
</style>