<template>
    <div class="dropdown-hashtag" ref="el" :class="{'show': is_show}">
        <div class="label">
            <h3>
                {{ is_edit ? 'Edit Content Tags' : 'Select of Create Content Tags' }}
            </h3>
        </div>
        <div class="input" v-if="!is_edit">
            <input type="text" v-model="new_tag.text" placeholder="Add new tag" @keyup.enter="add(0)" />
            <label @click="add(0)">Add</label>
            <p v-if="is_error">Content tag limit has been reached.</p>
        </div>
        <ul v-if="is_edit">
            <li v-for="(v, k) in tags">
                <Checkbox size="small" :key="k" :label_text="v.text" :checked="delete_tags.filter((t) => t.id === v.id).length !== 0" @click="selectDeleteTag(v)" />
            </li>
        </ul>
        <ul v-else>
            <li>
                <Checkbox size="small" :key="'all'" :label_text="'All'" :checked="selected_tags.length === 0" @click="$emit('select-all')" />
            </li>
            <li v-for="(v, k) in tags">
                <Checkbox size="small" :key="k" :label_text="v.text" :checked="selected_tags.filter((t) => t.id === v.id).length !== 0" @click="$emit('select-tag', v)" />
            </li>
        </ul>
        <footer>
            <span v-if="!is_edit" @click="is_edit = true"><svg-icon name="edit" /> Edit</span>
            <ul>
                <li><span v-if="is_edit" class="cancel" @click="is_edit = false">Cancel</span></li>
                <li><span v-if="is_edit" class="remove" @click="is_show_remove_modal = true">Delete <svg-icon name="remove" /></span></li>
            </ul>
        </footer>
    </div>

    <Modal :is_show="is_show_remove_modal" class="alert-modal" @click-bg="is_show_remove_modal = false">
        <div class="box">
            <header class="delete-header">{{ remove_text.title }}</header>
            <p>{{ remove_text.content }}</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click="remove(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click="remove('remove')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';
import Checkbox from './ui/Checkbox.vue';

export default {
    name: 'DropdownHashtag',

    components: {
        Loading,
        Button,
        Modal,
        Checkbox,
    },

    data() {
        return {
            is_show: false,
            is_updateing: false,
            is_show_remove_modal: false,
            is_edit: false,
            is_error: false,
            is_timer: false,
            delete_tags: [],
            new_tag: {
                id: 0,
                text: '',
            },
            remove_item: {
                tag: 0,
                list: 0,
                index: 0,
            },
        }
    },

    props: {
        tags: {
            type: Array,
            default: () => [],
        },

        selected_tags: {
            type: Array,
            default: () => [],
        },

    },

    computed: {
        hide_edit() {
            return !this.$store.state.user_data.contract_status;
        },

        remove_text() {

            return {
                title: `Remove tsgs?`,
                content: `This will stop tracking content on creator profiles.`
            }
        },

    },

    methods: {
        validateAddTag(tag) {
            let regex = /^[a-zA-Z0-9_-]+$/;
            let list = false;
            console.log('tag', tag)
            if (tag.key === 'hashtag') {
                regex = /^[@#][a-zA-Z0-9_\-\.]+$/;///^[@#][a-zA-Z0-9_-]+$/;
            }

            if (!tag.new_tag.text) {
                tag.error = true;
                tag.error_text = 'Keyword is required';
                return;
            }

            if (tag.key === 'hashtag' && (tag.new_tag.text[0] !== '#' && tag.new_tag.text[0] !== '@')) {
                tag.error = true;
                tag.error_text = 'The first character must be a # or @.';
                return;
            }


            if (!regex.test(tag.new_tag.text)) {
                tag.error = true;
                if (tag.key === 'hashtag') {
                    tag.error_text = `Entries may not include any spaces or special characters (@mentions may include '.' and '_').`;
                } else {
                    tag.error_text = `Entries may not include any spaces or special characters except '-' and '_'. `
                }
                return;
            }


            if (tag.key === 'hashtag' && tag.new_tag.text[0] === '@') {
                list = tag.hashtags[1];
            } else {
                list = tag.hashtags[0];
            }

            let tempText = tag.new_tag.text.replace('#', '').replace('@', '');

            if (list.value.find(item => item.text.toLocaleLowerCase() === tempText.toLocaleLowerCase())) {
                let ti = tag.new_tag.text[0] === '@' ? 'mention' : tag.new_tag.text[0] === '#' ? 'hashtag' : 'tag';
                tag.error = true;
                tag.error_text = `This ${ti} already exists.`;
                return;
            } else if (list.max && list.value.length >= list.max) {
                tag.error = true;
                tag.error_text = 'Limit reached. Remove tags to add new ones.';
                return;
            } else {
                tag.new_tag.text = tag.new_tag.text.replace(/[@#]/g, '');
                list.value.push(tag.new_tag);
                //this.update();
                tag.error = false;
                tag.new_tag = {
                    id: 0,
                    text: '',
                };
            }
        },

        add(index) {
            this.validateAddTag(this.tags[index]);
        },

        update() {
            this.is_updateing = true;
            const hashtags = [];
            const mentions = [];
            const tags = [];
            this.tags.forEach((tag, index) => {
                tag.hashtags.forEach((list, list_index) => {
                    list.value.forEach((item, item_index) => {
                        if (index === 0 && list_index === 0) {
                            hashtags.push(item.text);
                        } else if (index === 0 && list_index === 1) {
                            mentions.push(item.text);
                        } else if (index === 1 && list_index === 0) {
                            tags.push(item.text);
                        }
                    });
                });
            });

            hashtags.forEach((item, index) => {
                item = item.replace('#', '');
            });

            mentions.forEach((item, index) => {
                item = item.replace('@', '');
            });

            const data = {
                hashtags: hashtags,
                mentions: mentions,
                tags: tags,
            };

            this.$ajax('/creator/update-social-post-custom-filter', {
                method: 'post',
                withCredentials: true,
                data: data,
            }).then(res => {
                // this.$emit('update', data);
                const tags = res.data.data.social_post_tag_texts;
                // console.log(tags)
                this.EventBus.emit('updated-all-post-tag', tags);
                this.is_updateing = false;
            }).catch(err => {
                if (err && err.response && err.response.data.data) {
                    if (tags.length > 0) {
                        this.tags[1].error_text = err.response.data.data.message;
                        this.tags[1].error = true;
                    } else {
                        this.tags[0].error_text = err.response.data.data.message;
                        this.tags[0].error = true;
                    }

                }

            })
        },

        remove(tagIndex, listIndex, index) {
            if (typeof index === 'number') {
                this.is_show_remove_modal = true;
                this.remove_item.tag = tagIndex;
                this.remove_item.list = listIndex;
                this.remove_item.index = index;

            } else if (tagIndex === 'remove') {
                this.is_show_remove_modal = false;
                this.tags[this.remove_item.tag].hashtags[this.remove_item.list].value.splice(this.remove_item.index, 1);
                //this.update();
            } else {
                this.is_show_remove_modal = false;
                this.remove_item.tag = 0;
                this.remove_item.list = 0;
                this.remove_item.index = 0;
            }
        },


        open(is_edit = false) {
            this.is_edit = is_edit;
            this.is_show = true;
            this.is_timer = true;

            setTimeout(() => {
                this.is_timer = false;
            }, 100);
        },

        close() {
            this.is_show = false;
        },

        selectDeleteTag(tag) {
            const index = this.delete_tags_tags.findIndex((t) => t.id === tag.id);
            if (index === -1) {
                this.delete_tags_tags.push(tag);
            } else {
                this.delete_tags_tags.splice(index, 1);
            }

            this.getStatistic();
            this.getPosts();
        },

        handleClick(e) {
            if (this.$refs.el && !this.$refs.el.contains(e.target) && !this.is_timer) {
                this.close();
            }
            // if (!this.$el.contains(e.target)) {
            //     console.log(2)
            //     this.$emit('close');
            // }
        },
    },

    created() {
        window.addEventListener('click', this.handleClick);
    },

    destroyed() {
        window.removeEventListener('click', this.handleClick);
    },
}
</script>
<style lang="scss">
.dropdown-hashtag {
    position: absolute;
    background-color: white;
    top: 100%;
    right: 0;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 9;
    width: 220px;
    display: none;

    &.show {
        display: block;
    }

    .label {
        padding: 12px;
        border-bottom: 1px solid var(--black-20);

        h3 {
            font-size: 12px;
            font-weight: 500;
            color: var(--black-75);
        }
    }

    .input {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        padding: 12px;
        border-bottom: 1px solid var(--black-20);

        input {
            border-radius: 6px;
            display: block;
            color: var(--black-90);
            border: 1px solid var(--black-40);
            font-size: 12px;
            height: 26px;
            padding: 0 8px;
            width: 150px;

            &:focus {
                border: 1px solid var(--dark-blue);
            }
        }

        label {
            cursor: pointer;
            height: 26px;
            width: 42px;
            min-width: 42px;
            background: var(--dark-blue);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 6px;
            margin-left: 8px;

            &.disabled {
                background: var(--black-20);
                cursor: not-allowed;
                pointer-events: none;

                svg {
                    fill: var(--black-40);
                }
            }
        }
    }

    .error-text {
        color: var(--red) !important;
        font-size: 12px;
    }

    & > ul {
        padding: 12px 12px 8px;

        li {
            margin: 4px 0 0;
        }
    }



    .ui-checkbox {
        margin: 0;
        color: var(--black-90);
    }

    footer {
        padding: 12px;
        border-top: 1px solid var(--black-20);
        display: flex;

        ul {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
                padding-left: 8px;
            }
        }

        span {
            border: 1px solid var(--border);
            border-radius: 6px;
            height: 24px;
            padding: 0 6px;
            font-size: 12px;
            color: var(--black-75);
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &.cancel {
                border-color: white;
            }

            &.remove {
                color: var(--red);
            }

            svg {
                height: 12px;
                width: 12px;
                
                &.svg-icon--edit {
                    margin-right: 4px;
                }
                
                &.svg-icon--remove {
                    margin-left: 4px;
                }
            }
        }
    }

}
</style>