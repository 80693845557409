<template>
    <Content class="social-list-content">

        <Loading class="static" v-if="(post.view_value_loading && !is_social_head) || start_loading" />
        <div class="sticker-big-box" v-if="!post.view_value_loading && is_social_head && !start_loading"
            :key="is_social_head">
            <div class="sticker-tab-box-spec">
                <Button type="button" color="dark-blue" size="40" v-for="(v, k) in sticker_tab" :key="k" v-show="v.show"
                    :style="'no-border-square'" @click="clickTab(v.index)" :class="{ 'actived': now_tab === v.index }">
                    <SvgIcon v-if="v.svg" :name="v.svg" />
                    <div class="right">
                        {{ v.name }}
                        <div class="han">{{ v.value }}</div>
                    </div>
                </Button>
            </div>
        </div>
        <time>Updated: {{ update_date }}</time>

        <div class="main">

            <div class="main-box" v-if="!no_state">
                <Loading class="static" v-if="(post.loading && !is_social_head) || start_loading" />
                <div class="title on-top-title">
                    <h2>Social Insights</h2>
                    <p class="small-text">Insights available 7 days after posting. Data updated daily.</p>
                </div>

                <div class="title-list-box" v-if="!post.loading && is_social_head && !start_loading"
                    :key="is_social_head">
                    <div class="title"></div>
                    <time>{{ now_tab === 0 ? 'Last 30 days' : 'Last 10 posts' }}</time>
                </div>

                <!-- Statistic -->
                <Loading class="static" v-if="post.view_value_loading || start_loading" />

                <div class="ig-view" v-if="now_tab === 0 && !start_loading">
                    <div class="title-list-box">
                        <div class="title">In-Feed (10 total)
                        </div>
                    </div>
                    <ValueBox :key="post.view_value_loading" :view_value="igVeiwValue.feed" />
                    <div class="title-list-box title-list-box-last">
                        <div class="title">Story ({{ post.all_data.story ? post.all_data.story.length : 0 }} total)
                        </div>
                    </div>
                    <ValueBox :key="post.view_value_loading" :view_value="igVeiwValue.story" />
                </div>

                <div class="tk-view" v-if="now_tab === 1 && !start_loading">
                    <div class="title-list-box">
                        <div class="title">Video ({{ post.all_data.tkData.length }} total)</div>
                    </div>
                    <ValueBox :show="!post.view_value_loading && !start_loading" :key="post.view_value_loading"
                        :view_value="post.view_value" />
                </div>
            </div>
            <!-- && !no_audience -->
            <div class="main-box" v-if="!post.view_value_loading && !start_loading">
                <Audience :is_one_line="true" :title="now_tab === 0 ? 'Reached Audience' : 'Audience'"
                    :is_one_audience="true" :data="audience_data" />
            </div>

            <!-- top 10 post -->
            <TopBrandPost v-if="!start_loading" :title="'Most Recent 10 Posts'" :posts="post.data" :posts_value="post"
                @clickPost="clickPost" :had_navi="false" @updateSort="updateSort" @updateTag="updateTag"
                @callApiAgain="callApiAgain" :selected_tags="selected_tags" :tag_text="default_text" :no_plus="true"
                :is_soc="true" @changeIgTag="changeIgTag" :now_tab="now_tab" />

        </div>
    </Content>
</template>

<script>

import Content from '@/components/ui/Content.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import DatePicker from '@/components/ui/DatePicker.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import Audience from '@/components/Audience.vue';
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import Button from '@/components/ui/Button.vue';
import TopBrandPost from '@/components/TopBrandPost.vue';
import { HandleNum, HandleName, HandleNumSpec, HandleKNum } from '@/utils/SwitchFun';
import { SwitchTopSort, get_all_post_data, handleSelectText, handleSelectArray } from '@/utils/ContentSwitchFun.js';
import formatDateDaysAgo from '@/utils/formatDateDaysAgo';
import ValueBox from '@/components/ui/SocialValueBox.vue';

export default {
    name: 'Collections-List',

    data() {
        return {
            start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
            end_date: moment().add(-1, 'days').format('YYYY-MM-DD'),
            sticker_tab: [
                { name: 'Instagram', index: 0, svg: 'instagram', show: false, value: '' },
                { name: 'TikTok', index: 1, svg: 'tiktok-pure', show: false, value: '' },
            ],
            is_social_head: false,
            now_tab: 0,
            now_user: '',
            user_select: [],
            post: JSON.parse(JSON.stringify(get_all_post_data)),
            no_state: false,
            no_audience: true,
            audience_data: {
                gender: [],
                age: [],
                countries: []
            },
            default_text: 'Tags: All',
            tags: [],
            selected_tags: [],
            start_loading: false,
            igVeiwValue: {
                feed: [],
                story: []
            },
            update_date: moment().add(-1, 'days').format('MMM DD, YYYY'),
        }
    },

    props: {
        date_range: {
            type: Object,
            default: null,
        },
        data_info: {
            type: Object,
            default: null,
        },
        is_show_hint: {
            type: Boolean,
            default: false,
        },
        last_update: {
            type: String,
            default: '',
        },
        user_data: {
            type: Object,
            default: {},
        },
    },

    computed: {

    },

    components: {
        Content,
        Scrollbar,
        NoData,
        Loading,
        SvgIcon,
        DatePicker,
        Dropdown,
        Audience,
        CreatorSmallCard,
        Button,
        TopBrandPost,
        ValueBox,
    },

    watch: {
        data_info: {
            handler(val) {
                if (val) {
                    this.handleHan();
                }
            },
            deep: true
        }
    },

    methods: {
        handleNum: HandleNum,
        handleName: HandleName,
        HandleKNum: HandleKNum,

        daysAgo(date) {
            return formatDateDaysAgo(date);
        },

        clickTab(v) {
            this.now_tab = v;
            this.getStateData();
            this.getTopData();
        },

        callApiAgain() {
            this.getTopData();
        },

        //not use now
        updateUserHandle(id) {

        },

        updateSort(id) {
            let getVal = SwitchTopSort(id.id);
            this.post.sort.sort_key = getVal.key;
            this.post.sort.sort_val = getVal.val;
            this.post.sort_index = this.post.sort_select.findIndex(obj => obj.id === id.id);
            this.post.drop_key += 1;
            this.getTopData();
        },

        updateTag(tag) {
            if (this.selected_tags.findIndex(o => o.id === tag.id) === -1) {
                this.selected_tags.push(tag);
            } else {
                this.selected_tags = this.selected_tags.filter(o => o.id !== tag.id);
            }

            if (this.selected_tags.length === 0) {
                this.default_text = 'Tags: All';
            } else {
                this.default_text = 'Tags: ';
                this.selected_tags.forEach((v, k) => {
                    this.default_text += v.text + (k === this.selected_tags.length - 1 ? '' : ', ');
                });
            }

            this.getTopData()
        },

        reqParams() {
            return {
                anchor_creator_id: this.$store.state.pick_creator_user_id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                social_platform: this.now_tab === 0 ? 'instagram ' : 'tikTok',
            }
        },

        handleHan() {

            if (this.data_info && this.data_info.instagram_handle) {
                this.sticker_tab[0].show = true;
                this.sticker_tab[0].value = this.data_info.instagram_handle;
                this.sticker_tab[0].name = this.data_info.instagram_follower_count ? HandleKNum(Number(this.data_info.instagram_follower_count)) : '---';
            }

            if (this.data_info && this.data_info.tiktok_handle) {
                this.sticker_tab[1].show = true;
                this.sticker_tab[1].value = this.data_info.tiktok_handle;
                this.sticker_tab[1].name = this.data_info.tiktok_follower_count ? HandleKNum(Number(this.data_info.tiktok_follower_count)) : '---'

            }


            this.is_social_head = true;
        },

        getStateData() {
            this.post.view_value_loading = true;
            let req_par = this.reqParams();
            let params_data = {
                ...req_par,
            }
            return new Promise((resolve, reject) => {
                //client api not yet
                this.$ajax(`creator/get-aaa-account-posts-statistic?user_id=${this.user_data.id}`, {
                    //params: params_data,
                    method: 'get',
                }).then(res => {
                    let get_value = this.now_tab === 0 ? res.data.data.ig : res.data.data.tk;
                    console.log(res.data.data, get_value)

                    if (this.now_tab === 0) {
                        this.igVeiwValue.feed = JSON.parse(JSON.stringify(this.post.ig_view_value));
                        this.igVeiwValue.story = JSON.parse(JSON.stringify(this.post.ig_view_value));

                        this.igVeiwValue.feed.forEach(item => {
                            if (item.field === 'total_engagement_rate') {
                                item.value = HandleNumSpec(get_value.feed[item.field]) !== '---' ? `${HandleNumSpec(get_value.feed[item.field])}%` : HandleNumSpec(get_value.feed[item.field]);
                            } else {
                                item.value = this.handleNum(get_value.feed[item.field]);
                            }

                        });
                    } else {
                        this.post.view_value.forEach(item => {
                            if (item.field === 'total_engagement_rate') {
                                item.value = HandleNumSpec(get_value.total[item.field]) !== '---' ? `${HandleNumSpec(get_value.total[item.field])}%` : HandleNumSpec(get_value.total[item.field]);
                            } else {
                                item.value = this.handleNum(get_value.total[item.field]);
                            }
                        });
                    }

                    this.no_state = this.post.view_value.every(e => e.value === '---' || e.value === '0%');

                    //audience
                    this.audience_data = {
                        gender: get_value.audience_gender,
                        age: get_value.audience_age,
                        countries: get_value.audience_countries
                    }
                    this.no_audience = get_value.audience_gender.length === 0 && get_value.audience_age.length === 0 && get_value.audience_countries.length === 0
                    this.post.view_value_loading = false;
                    resolve(this.no_state);
                }).catch(err => {
                    console.log(err)
                    resolve(this.no_state);
                    this.post.view_value_loading = false;
                })


            })

        },

        handleSocArr(arr) {
            return arr.filter((e, k) => {
                return e.id !== 987654321
            });
        },

        getTopData() {
            this.post.loading = true;
            let req_par = this.reqParams();

            let params_data = {
                ...req_par,
                sort_key: this.post.sort.sort_key,
                sort_val: this.post.sort.sort_val,
                page: this.post.sort.page,
                per_page: this.post.sort.per_page,
                with_extra_count: '',
                social_insight_only: 1
            }

            if (this.selected_tags.length > 0) {
                params_data.tag_ids = this.selected_tags.map(e => { return e.id })
            }
            return new Promise((resolve, reject) => {
                //client api not yet
                this.$ajax(`creator/get-social-account-posts`, {
                    //params: params_data,
                    method: 'get',
                }).then(res => {
                    let post_data = res.data.data.social_posts;
                    this.post.all_data = post_data;
                    if (this.now_tab === 0) {
                        this.post.data = this.handleSocArr(post_data.posts);

                    } else {
                        this.post.data = this.handleSocArr(post_data.tkData);
                    }

                    this.post.ig_menu[0].value = post_data.posts.length;
                    this.post.ig_menu[1].value = post_data.reels.length;
                    this.post.ig_menu[2].value = post_data.story.length;
                    this.post.loading = false;
                    resolve(this.post.data);
                }).catch(err => {
                    resolve([]);
                    this.post.loading = false;
                })

                resolve(this.post.data);
                this.post.loading = false;
            })
        },

        changeIgTag(index) {
            this.post.ig_post_type = index;
            if (index === 0) {
                this.post.data = this.handleSocArr(this.post.all_data.posts);
            } else if (index === 1) {
                this.post.data = this.handleSocArr(this.post.all_data.reels);
            } else {
                this.post.data = this.handleSocArr(this.post.all_data.story);
            }
        },

        clickPost(v) {

        }

    },

    async mounted() {
        // this.getFilterList();
        this.handleHan();
        this.now_tab = 0;
        //---------------------------------------------------------------------------------------
        //If TikTok have no data, but Instagram does, default should show Instagram tab
        //---------------------------------------------------------------------------------------
        try {
            this.start_loading = true;
            await this.getTopData();
            await this.getStateData();
            //demo not use
            // let result1 = await this.getTopData();
            // let result2 = await this.getStateData();
            //if (result1.length === 0 && result2 && (this.now_tab === 0 && this.data_info && this.data_info.tiktok_handle)) {
            //this.now_tab = 1;

            // let result1 = await this.getTopData();
            // let result2 = await this.getStateData();
            // //---------------------------------------------------------------------------------------
            // //If Ig have no data, back to Tk tab
            // //---------------------------------------------------------------------------------------
            // if (result1.length === 0 && result2 && this.data_info && this.data_info.instagram_handle) {
            //     this.now_tab = 0;
            //     this.getTopData();
            //     this.getStateData();
            // }
            //}
            this.start_loading = false;
        } catch (error) {
            this.start_loading = false;
            console.error('Error:', error);
        }

    },

}
</script>

<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.social-list-content {

    .sticker-tab-box-spec {
        @include flexCenter();
        margin-top: 0px; //24px;
        margin-bottom: 16px;

        .ui-button {
            margin-right: 16px;
            border-radius: 6px !important;
            display: inline-flex;
            align-items: center;
            line-height: 1;
            box-sizing: border-box;
            text-wrap: nowrap;
            padding: 20px 40px;
            line-height: unset;
            height: unset;
            border: 1px solid var(--dark-blue);
            font: var(--font-fg-24);

            .han {
                font: var(--font-fg-14);
            }

            &:hover {
                background-color: var(--bg-02);
                border-color: var(--bg-02) !important;
            }

            &.actived,
            &.actived:hover {
                color: var(--white);
                background-color: var(--dark-blue);

                svg {
                    fill: var(--white);
                }
            }


            svg {
                margin-right: 12px;
                height: 45px;
                width: 45px;
            }
        }

    }

    .main {
        margin-top: 16px;
    }

    .on-top-title {
        h2 {
            font: var(--font-demi-24);
            color: var(--black-90);
            margin: 0;
            flex: 1;
        }

        .small-text {
            font: var(--font-med-10);
            font-family: var(--font-ms);
            color: var(--black-75);
            margin-top: 8px;
        }

    }

    .sticker-big-box {
        @include flexCenter();
        justify-content: space-between;

        .sticker-tab-box {
            margin-top: 0;
        }
    }

    .main {
        height: calc(100% - 120px);

        .main-box {
            padding: 16px 12px;
            border-radius: 8px;
            margin-bottom: 16px;
            background-color: var(--light-neutral);
        }

        .title {
            font: var(--font-demi-20);
        }

        .title-list-box {
            @include flexCenter();
            margin-bottom: 8px;
            justify-content: space-between;

            .title {
                margin-top: 0px;
            }

            time {
                font: var(--font-fg-14);
                color: var(--black-75);
            }

            .handle-drop {
                .ui-button {
                    border: unset;
                    background-color: unset;
                    justify-content: flex-start;
                    padding: 0 !important;

                    span {
                        font: var(--font-demi-20);
                        color: var(--black-90);
                    }
                }
            }

        }

        .report-box {
            grid-template-columns: repeat(3, 1fr);
        }

        .ig-view {

            .title-list-box-last {
                margin-top: 12px;
            }
        }

        .top-brand-post-box {
            background-color: var(--light-neutral);

            .title {
                padding: 16px 12px 0;
            }

            .tab-box {
                margin-top: -28px;
                padding: 0 12px;
                margin-bottom: unset;
            }
        }
    }

}
</style>