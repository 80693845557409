<template>
    <div class="ui-dropdown" ref="item">
        <div class="ui-dropdown--inner">
            <Button size="32" :color="'black-60'" :style="'border-square'" :class="{'disabled': disabled}" @click.stop="toggle">
                <SvgIcon v-if="icon_direction_type === 'left'" :name="icon" class="icon-left" />
                <span v-if="selected_index === -1 && ( default_text || is_select_dropdown )">{{ default_text }}</span>
                <span v-else>{{ items[selected_index]? items[selected_index].text :''}}</span>
                <SvgIcon v-if="icon_direction_type === 'right'" :name="icon" class="icon-right" />
                <tippy :delay="[0, 20]" class="tooltip" v-if="tooltip_text" :content="tooltip_text" trigger="mouseenter click"/>
            </Button>
            <ul v-if="is_show_select" class="item-box" :class="['direction--' + direction_type, {'direction--top': on_bottom}]">
                <li v-for="(item, index) in items" :key="index" :class="['item', no_icon && 'icon-display-none', item.disabled && 'disabled', item.hide && 'hide']" @click.stop="selectType(index)">
                    <SvgIcon name="yes" v-if="selected_index === index && is_update_index" />
                    <i v-else-if="is_update_index" class="select-icon" />
                    <span>{{ item.text }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';

export default {
    name: 'Dropdown',

    components: {
        SvgIcon,
        Button,
    },

    data() {
        return {
            is_show_select: false,
            selected_index: 0,
            on_bottom: false,
        }
    },

    props: {
        icon: {
            type: String,
            default: 'arrow',
        },
        direction_type: {
            type: String,
            default: 'left',
        },
        icon_direction_type: {
            type: String,
            default: 'left',
        },
        items: {
            type: Array,
            default: [],
        },
        default_text: {
            type: String,
            default: '',
        },
        tooltip_text: {
            type: String,
            default: '',
        },
        default_value: {
            type: Number,
            default: 0,
        },
        is_update_index: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        is_select_dropdown:{
            type: Boolean,
            default: false,
        },
        array_key:{
            type:Number,
            default: null,
        },
        no_icon:{
            type:Boolean,
            default: false,
        }
    },

    methods: {
        toggle() {
            this.is_show_select = (this.disabled) ? false : !this.is_show_select;

            const rect = this.$refs.item.getBoundingClientRect();
            this.on_bottom = rect.bottom > (window.innerHeight - 180);


            this.$emit('toggle', 
                this.items[this.selected_index],
            );
        },
        selectType(index) {
            if (this.items[index].disabled) return;
            this.is_show_select = false;
            if (this.is_update_index) this.selected_index = index;
            if (this.array_key!== null ) this.items[index].the_key=this.array_key;
            this.items[index].old_value_index=this.default_value;
            this.$emit('update-dropdown', 
                this.items[index],
                
            );
        },

        handleClick(e) {
            this.is_show_select = false;
        },
    },

    created () {
        window.addEventListener('click', this.handleClick);
    },

    destroyed () {
        window.removeEventListener('click', this.handleClick);
    },

    mounted() {
        if (this.default_value !== 0) 
            this.selected_index = this.default_value;
        if(!this.items[this.selected_index])
            this.selected_index=0

        //handle if need default Text,will hard code default_value==-100
        if(this.default_value === -100)
            this.selected_index=-1;

    },
}
</script>
<style lang="scss">
    .ui-dropdown {
        &--inner {
            position: relative;

            .ui-button {
                margin: 0 !important;
                padding: 0 8px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;

                .tooltip {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 3;
                    cursor: pointer;
                }

                span {
                    // margin-left: 5px;
                    font: var(--font-fg-12);
                    color: var(--black-60);
                }

                svg {
                    width: 8px !important;
                    height: 8px !important;
                    color: black !important;

                    &.icon-left {
                        margin-right: 6px !important;
                    }

                    &.icon-right {
                        margin-left: 6px !important;
                    }
                }

                &.disabled {
                    border: 1px solid var(--black-10);
                    cursor: not-allowed;

                    .tooltip {
                        cursor: not-allowed;
                    }

                    span {
                        color: var(--black-40);
                    }
                    
                    svg {
                        fill: var(--black-40);
                    }
                }
            }

            .item-box {
                padding: 8px 0;
                // width: 238px;
                width: max-content;
                background: white;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                position: absolute;
                z-index: 9;

                &.direction--right {
                    right: 0px;
                }

                &.direction--top {
                    bottom: 100%;
                }


                .item {
                    padding: 9px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: var(--black-90);
                    font: unset;
                    font-family: var(--font);
                    font-size: 14px;

                    &:hover {
                        background: var(--bg-02);
                    }

                    &.disabled {
                        color: var(--black-40);
                        cursor: not-allowed;

                        .select-icon {
                            border: 1px solid var(--black-40);
                        }
                    }

                    &.hide {
                        display: none;
                    }

                    &.icon-display-none{
                        svg, .select-icon{
                            display: none;
                        }
                    }

                    svg {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        fill: var(--dark-blue);
                    }

                    .select-icon {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        border: 1px solid var(--black-50);
                        border-radius: 50%;
                        background-color: white;
                    }
                }
            }
        }
    }
</style>

