import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },

    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
    },
    
    {
        path: '/onboard',
        name: 'onboard',
        component: () => import(/* webpackChunkName: "onboard" */ '../views/Onboard.vue')
    },

        
    {
        path: '/landing',
        name: 'landing',
        component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue')
    },

    {
        path: '/creator/get-started',
        name: 'creator--get-started',
        component: () => import(/* webpackChunkName: "creator-get-started" */ '../views/creator/GetStarted.vue')
    },

    {
        path: '/creator/list',
        name: 'creator--list',
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/creator/List.vue')
    },

    {
        path: '/analytics/overview',
        name: 'analytics--overview',
        component: () => import(/* webpackChunkName: "analytics-overview" */ '../views/analytics/Overview.vue')
    },

    {
        path: '/analytics/product',
        name: 'analytics--product',
        component: () => import(/* webpackChunkName: "analytics-product" */ '../views/analytics/Product.vue')
    },

    {
        path: '/analytics/creator',
        name: 'analytics--creator',
        component: () => import(/* webpackChunkName: "analytics-creator" */ '../views/analytics/Creator.vue')
    },

    {
        path: '/analytics/content',
        name: 'analytics--content',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-content" */ '../views/analytics/Content.vue')
    },

    {
        path: '/request-a-demo',
        name: 'request-for-demo',
        component: () => import(/* webpackChunkName: "request-for-demo" */ '../views/RequestForDemo.vue')
    },


    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
    },

    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "help" */ '../views/Profile.vue')
    },

    {
        path: '/account-billing',
        name: 'account-billing',
        component: () => import(/* webpackChunkName: "help" */ '../views/AccountBilling.vue')
    },

    {
        path: '/setting',
        name: 'setting',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting" */ '../views/Settings.vue')
    },


    {
        path: '/settings/custom',
        name: 'setting-custom',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting-desjgn" */ '../views/SettingsDesign.vue')
    },

    {
        path: '/settings/storefront',
        name: 'setting-storefront',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting-desjgn" */ '../views/SettingsDesign.vue')
    },

    {
        path: '/settings/:type',
        name: 'settings',
        meta: { title: 'Settings | Anchor' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
    },

    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
    },
]

let base_url = '/';
  
const router = createRouter({
  history: createWebHistory(base_url),
  routes
});

export default router;
