<template>
    <div class="likeboard-layout-wrap" v-if="inited" :class="{'is-empty': !item.layout && images.length === 0}">
        <div v-if="is_only_layout" class="likeboard-layout" :class="['image' + layout]">
            <i class="img" v-for="index in Number(layout_number)" :key="index"></i>
        </div>
        <div v-else class="likeboard-layout" :class="['image'+layout_number+'-'+layout_index]">
            <i class="img" v-for="index in Number(layout_number)" :key="index" :class="{'show-image': item.cover_photos[index-1]}">
                <span v-if="need_number">{{index}}</span>
                <img v-if="item.cover_photos[index-1]" :key="index" :src="item.cover_photos[index-1].replace('product_photos/200x200/', 'product_photos/400x400/')" @error="error">
            </i>
        </div>
    </div>
    
</template>

<script>
import isJson from '@/utils/IsJson';

export default {
    name: 'ImagesLayout',

    props: {
        item: Object,
        is_edit: {
            type: Boolean,
            default: false
        },
        need_number:{
            type: Boolean,
            default: false
        },
        is_only_layout: {
            type: Boolean,
            default: false
        },
        layout: String,
    },

    data() {
        return {
            images: [],
            layout_number: 1,
            layout_index: 1,
            inited: false,
        }
    },

    methods: {

        getAllImages() {
            if (this.item && this.item.likes) {
                if (this.item.likes.products) {
                    this.item.likes.products.forEach(item => {
                        let image = '';
                        if (item.images && item.images.img_400x400 && item.images.img_400x400 !== 'no-image.png') {
                            image = this.$Get_Image(item.images.img_400x400, '/product_photos/400x400/');
                            if (!this.images.includes(image)) this.images.push(image);
                        }
                    });
                }

                if (this.item.likes.posts) {
                    this.item.likes.posts.forEach(item => {
                        switch (item.post_type) {
                            case 'IMAGE':
                                item.image_list.forEach(image => {
                                    let image_src = this.$Get_Image(image.url + image.dir + '400x400/' + image.file_name);
                                    if (image_src) {
                                        this.images.push(image_src);
                                    }
                                });
                                break;
                        }
                    });
                }
            }


            

            // console.log(this.images)

        },

        setCoverImages() {
            const loadImage = (url) => new Promise((resolve, reject) => {
                const img = new Image();
                img.addEventListener('load', () => resolve(img));
                img.addEventListener('error', (err) => reject(err));
                img.src = url;
            });

            if (this.is_edit) this.getAllImages();
            let check_images = [];
            let clone_images = JSON.parse(JSON.stringify(this.images));

            // this.images.forEach(img => {
            //     check_images.push(this.$ajax.get(img));
            // });
            this.images.forEach(url => {
                check_images.push(loadImage(url));
            });


            Promise.allSettled(check_images)
                .then((res) => {
                    // console.log(res)
                    res.forEach((r, index) => {
                        if (r.status !== 'fulfilled') {
                            let remove_image = clone_images[index]
                            this.images = this.images.filter(item => item !== remove_image);
                        }

                    });
                })

                .finally(() => {
                    let photos = '';
                    
                    if (this.item.layout && typeof this.item.cover_photos === 'string' && (this.item.cover_photos === '' || this.item.cover_photos === '[]' || this.item.cover_photos === '""' || JSON.parse(this.item.cover_photos) === '""')) {
                        this.item.cover_photos = '';
                        this.item.layout = '';
                    }
                    
                    if (typeof this.item.cover_photos === 'object' && this.item.cover_photos.length !== 0) {
                        photos = this.item.cover_photos;
                    } else if (isJson(this.item.cover_photos)) {
                        if (typeof this.item.cover_photos === 'string') photos = JSON.parse(this.item.cover_photos);
                    }

                    if (this.item.layout && this.item.cover_photos) {
                        this.item.cover_photos = photos;
                    } else {
                        if (!this.item.layout && this.images.length === 0) {
                            this.layout_number = 4;
                            this.layout_index = 6;
                        }

                        else if (!this.item.layout && this.images.length !== 0) {
                            this.layout_number = this.images.length > 5 ? 5 : this.images.length;
                            this.layout_index = 1;
                        }
                        
                        this.item.cover_photos = this.images;
                    }

                    if (this.layout) {
                        let arr = this.layout.split('-');
                        this.layout_number = Number(arr[0]);
                        this.layout_index = Number(arr[1]);
                    } else if (typeof this.item.layout !== 'undefined' && this.item.layout.split('-').length === 2) {
                        let arr = this.item.layout.split('-');
                        this.layout_number = Number(arr[0]);
                        this.layout_index = Number(arr[1]);
                    }

                    this.inited = true;

                });



        },

        error($event) {
            if ($event.target.src.indexOf('.webp') > -1) {
                $event.target.src = $event.target.src.replace('.webp','.jpg');
                $event.target.onerror = () => {
                    $event.target.src = require('@/assets/images/no-image.png');
                };
            }
            else
                $event.target.src = require('@/assets/images/no-image.png');
        },
    },

    mounted() {

        setTimeout(() => {
            this.setCoverImages();
        }, 1);

        setTimeout(() => {
            this.setCoverImages();
        }, 1500);

    },
}
</script>

<style lang="scss">
    $border_color: var(--Black-00);

    .likeboard-layout {
        position: relative;
        display: block;
        overflow: hidden;
        background-color: var(--Black-05);
        

        &.image0-1 {
            
        }

        &::after {
            content: '';
            display: block;
            padding: 0 0 100%;
        }

        &::before {
            content: '';
            position: absolute;
            pointer-events: none;
            background: $border_color;
            z-index: 9;
        }

        img {
            position: absolute;
            object-fit: cover;
            background-color: transparent !important;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        i.img {
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            box-sizing: border-box;
            border:solid 2px var(--Border-light);

            &::before {
                content: '';
                position: absolute;
                pointer-events: none;
                background: $border_color;
                z-index: 9;
            }

            span {
                background: rgba(0, 0, 0, 0.25);
                width: 20px;
                height: 20px;
                border-radius: 0px 0px 8px 0px;
                text-align: center;
                color: var(--Text-invert);
                line-height: 20px;
                font-weight: var(--font-light);
                z-index: 3;
                position: absolute;
                left: 0;
                top: 0;
            }

        }

        &.image5-1 {
            &::before {
                width: 2px;
                height: 100%;
                left: 66.66%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 66.66%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 66.66%;
                width: 33.33%;
                
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                left: 33.33%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(5) {
                left: 66.66%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }
        }

        &.image5-2 {
            &::before {
                width: 2px;
                height: 100%;
                left: 66.66%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 66.66%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(3) {
                right: 0;
                top: 33.33%;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 66.66%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(5) {
                left: 66.66%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }
        }

        &.image5-3 {
            &::before {
                width: 2px;
                height: 100%;
                left: 33.33%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                 height: 66.66%;
                width: 67%;
                
            }

            .img:nth-child(3) {
                left: 0;
                top: 33.33%;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(5) {
                left: 33.33%;
                bottom: 0;
                height: 33.33%;
                width: 66.66%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }
        }

        &.image5-4 {
            &::before {
                width: 2px;
                height: 100%;
                left: 33.33%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    bottom: -1px;
                }
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 66.66%;
                width: 66.66%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    bottom: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(4) {
                left: 33.33%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                
            }
        }


        &.image5-5 {
            &::before {
                width: 100%;
                height: 2px;
                top: 66.66%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 66.66%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(4) {
                left: 33.33%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }
        }

        &.image5-6 {
            &::before {
                width: 100%;
                height: 2px;
                top: 33.33%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(2) {
                left: 33.33%;
                top: 0;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(4) {
                left: 0;
                bottom: 0;
                height: 66.66%;
                width: 50%;
                
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 66.66%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }
        }

        &.image5-7 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 33.33%;
                
                
            }

            .img:nth-child(2) {
                left: 33.33%;
                top: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                right: 0;
                top: 0;
                height: 50%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(4) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }
        }

        &.image5-8 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                left: 50%;
                top: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 33.33%;
                
            }

            .img:nth-child(4) {
                left: 33.33%;
                bottom: 0;
                height: 50%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }
        }

        &.image5-9 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 50%;
                
                
            }

            .img:nth-child(3) {
                right: 0;
                top: 33.33%;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(5) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }
        }

        &.image5-10 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(3) {
                left: 0;
                top: 33.33%;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                top: 50%;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(5) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }
        }

        &.image4-1 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                top:  50%;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

        }

        &.image4-2 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 66.66%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 33.33%;
                
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 66.66%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

        }

        &.image4-3 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }


            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 33.33%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width:66.66%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 66.66%;
                
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

        }


        &.image4-4 {
            &::before {
                width: 100%;
                height: 2px;
                top: 66.66%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 100%;
                
            }

            .img:nth-child(2) {
                left: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(3) {
                left: 33.33%;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.4%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

        }

        &.image4-5 {
            &::before {
                width: 100%;
                height: 2px;
                top: 33.33%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(2) {
                left: 33.33%;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(3) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 66.66%;
                width: 100%;
                


            }

        }

        &.image4-6 {
            &::before {
                width: 2px;
                height: 100%;
                left: 66.66%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 100%;
                width: 66.66%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
            }

            .img:nth-child(3) {
                right: 0;
                top: 33.33%;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

        }

        &.image4-7 {
            &::before {
                width: 2px;
                height: 100%;
                left: 33.33%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 33.33%;
                
                
            }

            .img:nth-child(2) {
                left: 0;
                top: 33.33%;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(3) {
                left: 0;
                top: 66.66%;
                height: 33.33%;
                width: 33.33%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 100%;
                width: 66.7%;
                
            }

        }

        &.image4-8 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 66.66%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 33.33%;
                width: 50%;
                
            }

            .img:nth-child(3) {
                left: 0;
                top: 66.66%;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 66.66%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

        }

        &.image4-9 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
                
            }
            
            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 33.33%;
                width: 50%;
                
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 66.66%;
                width: 50%;
                
                
            }

            .img:nth-child(3) {
                left: 0;
                top: 33.33%;
                height: 66.66%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

            .img:nth-child(4) {
                right: 0;
                bottom: 0;
                height: 33.33%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }
            }

        }







        &.image3-1 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 100%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(3) {
                right: 0;
                top: 50%;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 100%;
                    height: 2px;
                    top: -1px;
                }

            }
        }

        &.image3-2 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }


            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }

            }

            .img:nth-child(3) {
                right: 0;
                top: 50%;
                height: 50%;
                width: 100%;
                
            }
        }

        &.image3-3 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }


            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 100%;
                
            }

            .img:nth-child(2) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                
            }

            .img:nth-child(3) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    width: 2px;
                    height: 100%;
                    left: -1px;
                }
            }
        }

        &.image3-4 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-left: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 50%;
                
                
            }

            .img:nth-child(2) {
                right: 0;
                bottom: 0;
                height: 100%;
                width: 50%;
                


            }

            .img:nth-child(3) {
                left: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                

                &::before {
                    height: 2px;
                    width: 100%;
                    top: -1px;
                }
            }
        }

        &.image2-1 {
            &::before {
                width: 2px;
                height: 100%;
                left: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 100%;
                width: 50%;
                
            }

            .img:nth-child(2) {
                right: 0;
                top: 0;
                height: 100%;
                width: 50%;
                
            }
        }

        &.image2-2 {
            &::before {
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
            }

            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 50%;
                width: 100%;
                
            }

            .img:nth-child(2) {
                right: 0;
                bottom: 0;
                height: 50%;
                width: 100%;
                
            }
        }

        &.image1-1 {
            .img:nth-child(1) {
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                
            }
        }
    }
</style>
