<template>
    <div class="product-item">
        <figure class="image-wrap">
            <img :src="require('@/assets/images/products/' + data.image_url)" />
        </figure>
        <div class="product-name"  v-tippy="{ content: data.name, delay: [500, 20] }">{{ data.name }}</div>
        <div class="product-price">${{ formatMoney(data.price) }}</div>
        <div class="product-data">
            <div class="data-item">
                <div class="text">Product Views</div>
                <div class="value">{{ formatNumber(data.views) }}</div>
            </div>
            <div class="data-item">
                <div class="text">Clicks</div>
                <div class="value">{{ formatNumber(data.clicks) }}</div>
            </div>
            <div class="data-item">
                <div class="text">Sales</div>
                <div class="value">{{ formatNumber(data.sales) }}</div>
            </div>
            <div class="data-item">
                <div class="text">Sales value</div>
                <div class="value">${{ formatMoney(data.sales_value) }}</div>
            </div>
        </div>
        <div class="product-date">
            <span>Added: {{ data.added_date }}</span>
            <a class="icon" v-tippy="{ content: 'Open product source in new tab', delay: [500, 20] }" target="_blank" :href="data.url" @click.stop><SvgIcon name="share" /></a>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import formatMoney from '@/utils/formatMoney';

export default {
    name: 'ProductItem',

    components: {
        SvgIcon,
    },

    // data() {
    //     return {
            
    //     }
    // },

    props: {
        data: {
            type: Object,
            default: {},
        },
    },

    methods: {
        formatNumber(num) {
            return num.toLocaleString('en-US');
        },

        share() {
            const obj = {};
            obj.url = 'test';
            this.EventBus.emit('open-share', obj);
        },
        formatMoney: formatMoney,
    },
}
</script>
<style lang="scss">
.product-item {
    background: white;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    cursor: pointer;

    .image-wrap {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        display: block;

        &:before {
            content: "";
            display: block;
            padding: 0 0 100%;
        }

        img {
            display: block;
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .product-name {
        height: 42px;
        padding: 10px 8px 0 8px;
        font: var(--font-fg-12);
        color: var(--black-90);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .product-price {
        padding: 0 8px 10px 8px;
        font: var(--font-demi-12);
    }

    .product-data {
        padding: 8px;
        background: var(--bg-10);
        // display: inline-block;

        .data-item {
            width: 50%;
            display: inline-block;

            &:nth-child(3),
            &:nth-child(4) {
                padding-top: 8px;
            }

            .text {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: var(--black-60);
            }

            .value {
                font: var(--font-demi-12);
                color: var(--black-90);
            }
        }
    }

    .product-date {
        padding: 14px 8px;
        font: var(--font-fg-12);
        color: var(--black-50);
        display: flex;

        &>span {
            flex: 1;
        }

        .icon {
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 12px;
                height: 12px;
                fill: var(--black-60);
            }
        }
    }
}
</style>

