<template>
    <Loading class="static" v-if="is_loading" />
    <div class="ui-hashtag" v-else>
        <Loading class="absolute" v-if="is_updateing" />
        <div class="tag-list" :class="{ 'hide': only_tag && index === 0 }" v-for="(tag, index) in tags" :key="index">
            <div class="left-side">
                <h2 v-html="tag.name"></h2>
                <p class="desc" v-html="tag.desc"></p>
            </div>
            <div class="right-side" v-if="!hide_edit">
                <div>
                    <label class="label" :for="'input-tag-' + index" v-html="tag.label"></label>
                    <div class="input">
                        <input v-model="tag.new_tag.text" :name="'input-tag-' + index" :id="'input-tag-' + index"
                            type="text" :placeholder="tag.placeholder" @keyup.enter="add(index)" />
                        <label :class="{ 'disabled': !tag.new_tag.text }" @click="add(index)"><svg-icon
                                name="plus" /></label>
                    </div>
                    <p v-if="tag.error" class="error-text" v-html="tag.error_text"></p>
                </div>
            </div>
            <div class="hashtags">
                <div class="list" :class="'col-' + tag.hashtags.length" v-for="(list, listIndex) in tag.hashtags"
                    :key="listIndex">
                    <div class="label">
                        <h3>
                            <span>{{ list.label }}</span>
                            <span v-if="list.max">{{ list.value.length }}/{{ list.max }}</span>
                        </h3>
                        <i @click="list.is_edit = !list.is_edit"
                            v-if="!list.is_edit && !hide_edit && list.value.length > 0"><svg-icon name="pen" /></i>
                        <span class="btn" @click="list.is_edit = !list.is_edit"
                            v-if="list.is_edit && !hide_edit"><svg-icon name="yes-2" /> Save</span>
                    </div>
                    <ul>
                        <li v-for="(item, tagIndex) in list.value" :key="tagIndex">
                            <Button size="32" color="light-neutral" :class="[
        { 'hashtag': list.label === 'Hashtag' },
        { 'mention': list.label === '@mentions' }
    ]">{{ item.text }} <i v-if="list.is_edit" @click="remove(index, listIndex, tagIndex)"><svg-icon
                                        name="remove" /></i></Button>
                        </li>
                        <li v-if="!is_loading && !list.value.length">
                            <p>{{ list.no_data }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <Modal :is_show="is_show_remove_modal" class="alert-modal" @click-bg="is_show_remove_modal = false">
        <div class="box">
            <header class="delete-header">{{ remove_text.title }}</header>
            <p>{{ remove_text.content }}</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click="remove(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click="remove('remove')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';

export default {
    name: 'Hashtag',

    components: {
        Loading,
        Button,
        Modal,
    },

    data() {
        return {
            is_loading: true,
            is_updateing: false,
            is_show_remove_modal: false,
            remove_item: {
                tag: 0,
                list: 0,
                index: 0,
            },
            tags: [
                {
                    name: 'Tracked #Hashtags and @Mentions',
                    desc: `Any content from storefront creators which include these @mentions and #hashtags will automatically appear in your Content Dashboard and on the creator's Content page. The creator must connect their socials for the content to appear.`,
                    placeholder: 'Enter #hashtag or @mention to track',
                    label: 'Add @mention or #hashtag',
                    key: 'hashtag',
                    new_tag: {
                        id: 0,
                        text: '',
                    },
                    error: false,
                    error_text: '',
                    hashtags: [
                        {
                            label: 'Hashtag',
                            is_edit: false,
                            max: false,
                            value: [],
                            no_data: 'Added Hashtag keywords will show up here.',
                        },
                        {
                            label: '@mentions',
                            is_edit: false,
                            max: false,
                            value: [],
                            no_data: 'Added @mentions keywords will show up here.',
                        },
                    ],

                },
                {
                    name: 'Content Tags',
                    desc: `Custom tags can be used to segment creator content and filter by advanced fields. Please do not include any special characters besides '-' and '_'. (Max: 5 custom tags.)`,
                    placeholder: 'Enter a new custom tag',
                    label: 'Add new tag',
                    key: 'tags',
                    new_tag: {
                        id: 0,
                        text: '',
                    },
                    error: false,
                    error_text: '',
                    hashtags: [
                        {
                            label: 'Tags',
                            is_edit: false,
                            max: 5,
                            value: [],
                            no_data: 'Added Tags keywords will show up here.',
                        },
                    ],
                },
            ],
        }
    },

    props: {
        only_tag: {
            type: Boolean,
            default: false,
        },

    },

    computed: {
        hide_edit() {
            return !this.$store.state.user_data.contract_status;
        },

        remove_text() {
            let keyword = '';
            let content = '';
            let tit = '';
            let text = this.tags[this.remove_item.tag].hashtags[this.remove_item.list].value[this.remove_item.index].text;
            if (this.remove_item.tag === 0) {
                if (this.remove_item.list === 0) {
                    keyword = 'keyword';
                    tit = '#';
                } else {
                    keyword = 'mentions';
                    tit = '@';
                }
                content = `This will stop tracking content on ${tit}${text} creator profiles.`
            } else {
                keyword = 'tag';
                content = `This will remove tags from ${tit}${text} content items.`
            }

            return {
                title: `Remove ${keyword}?`,
                content: content
            }
        }
    },

    methods: {
        validateAddTag(tag) {
            let regex = /^[a-zA-Z0-9_-]+$/;
            let list = false;
            console.log('tag', tag)
            if (tag.key === 'hashtag') {
                regex = /^[@#][a-zA-Z0-9_\-\.]+$/;///^[@#][a-zA-Z0-9_-]+$/;
            }

            if (!tag.new_tag.text) {
                tag.error = true;
                tag.error_text = 'Keyword is required';
                return;
            }

            if (tag.key === 'hashtag' && (tag.new_tag.text[0] !== '#' && tag.new_tag.text[0] !== '@')) {
                tag.error = true;
                tag.error_text = 'The first character must be a # or @.';
                return;
            }


            if (!regex.test(tag.new_tag.text)) {
                tag.error = true;
                if (tag.key === 'hashtag') {
                    tag.error_text = `Entries may not include any spaces or special characters (@mentions may include '.' and '_').`;
                } else {
                    tag.error_text = `Entries may not include any spaces or special characters except '-' and '_'. `
                }
                return;
            }


            if (tag.key === 'hashtag' && tag.new_tag.text[0] === '@') {
                list = tag.hashtags[1];
            } else {
                list = tag.hashtags[0];
            }

            let tempText = tag.new_tag.text.replace('#', '').replace('@', '');

            if (list.value.find(item => item.text.toLocaleLowerCase() === tempText.toLocaleLowerCase())) {
                let ti = tag.new_tag.text[0] === '@' ? 'mention' : tag.new_tag.text[0] === '#' ? 'hashtag' : 'tag';
                tag.error = true;
                tag.error_text = `This ${ti} already exists.`;
                return;
            } else if (list.max && list.value.length >= list.max) {
                tag.error = true;
                tag.error_text = 'Limit reached. Remove tags to add new ones.';
                return;
            } else {
                tag.new_tag.text = tag.new_tag.text.replace(/[@#]/g, '');
                list.value.push(tag.new_tag);
                //this.update();
                tag.error = false;
                tag.new_tag = {
                    id: 0,
                    text: '',
                };
            }
        },

        add(index) {
            this.validateAddTag(this.tags[index]);
        },

        update() {
            this.is_updateing = true;
            const hashtags = [];
            const mentions = [];
            const tags = [];
            this.tags.forEach((tag, index) => {
                tag.hashtags.forEach((list, list_index) => {
                    list.value.forEach((item, item_index) => {
                        if (index === 0 && list_index === 0) {
                            hashtags.push(item.text);
                        } else if (index === 0 && list_index === 1) {
                            mentions.push(item.text);
                        } else if (index === 1 && list_index === 0) {
                            tags.push(item.text);
                        }
                    });
                });
            });

            hashtags.forEach((item, index) => {
                item = item.replace('#', '');
            });

            mentions.forEach((item, index) => {
                item = item.replace('@', '');
            });

            const data = {
                hashtags: hashtags,
                mentions: mentions,
                tags: tags,
            };

            this.$ajax('/creator/update-social-post-custom-filter', {
                method: 'post',
                withCredentials: true,
                data: data,
            }).then(res => {
                // this.$emit('update', data);
                const tags = res.data.data.social_post_tag_texts;
                // console.log(tags)
                this.EventBus.emit('updated-all-post-tag', tags);
                this.is_updateing = false;
            }).catch(err => {
                if (err && err.response && err.response.data.data) {
                    if (tags.length > 0) {
                        this.tags[1].error_text = err.response.data.data.message;
                        this.tags[1].error = true;
                    } else {
                        this.tags[0].error_text = err.response.data.data.message;
                        this.tags[0].error = true;
                    }

                }

            })
        },

        remove(tagIndex, listIndex, index) {
            if (typeof index === 'number') {
                this.is_show_remove_modal = true;
                this.remove_item.tag = tagIndex;
                this.remove_item.list = listIndex;
                this.remove_item.index = index;

            } else if (tagIndex === 'remove') {
                this.is_show_remove_modal = false;
                this.tags[this.remove_item.tag].hashtags[this.remove_item.list].value.splice(this.remove_item.index, 1);
                //this.update();
            } else {
                this.is_show_remove_modal = false;
                this.remove_item.tag = 0;
                this.remove_item.list = 0;
                this.remove_item.index = 0;
            }
        },
    },

    mounted() {
        if (this.$store.state.user_data) {
            this.$ajax('/creator/get-social-post-filter', {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.is_loading = false;
                const data = res.data.data;
                if (!data) return;
                this.tags[0].hashtags[0].value = data.social_post_hashtag_texts;
                this.tags[0].hashtags[1].value = data.social_post_mention_texts;
                this.tags[1].hashtags[0].value = data.social_post_tag_texts;
            });
        }
        this.is_loading = false;
    },
}
</script>
<style lang="scss">
.ui-hashtag {
    position: relative;

    .tag-list {
        display: flex;
        margin: 24px 0 32px;
        flex-wrap: wrap;
        max-width: 780px;

        &.hide {
            display: none;
        }
    }

    .left-side {
        width: 50%;
        padding-right: 12px;

        h2 {
            font: var(--font-med-20);
            color: var(--black-90);
            margin: 0 0 8px;
        }

        .desc {
            color: var(--black-75);
        }
    }

    .right-side {
        width: 50%;
        padding-left: 12px;
        display: flex;
        align-items: flex-end;

        &>div {
            flex: 1;
        }

        .label {
            font: var(--font-demi-12);
            color: var(--black-75);
            margin-bottom: 6px;
            display: block;
        }
    }

    .input {
        position: relative;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        flex: 1;

        input {
            width: 100%;
            padding: 9px 12px;
            // border-radius: 4px;
            font-size: 14px;
            color: var(--black-90);
            outline: none;
            height: 40px;
            border: 1px solid var(--black-20);

            &:focus {
                border: 1px solid var(--dark-blue);
            }
        }

        label {
            cursor: pointer;
            height: 40px;
            width: 40px;
            min-width: 40px;
            background: var(--dark-blue);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 16px;
                width: 16px;
                fill: white;
            }

            &.disabled {
                background: var(--black-20);
                cursor: not-allowed;
                pointer-events: none;

                svg {
                    fill: var(--black-40);
                }
            }
        }
    }

    .error-text {
        color: var(--red) !important;
        font-size: 12px;
    }

    .hashtags {
        display: flex;
        width: 100%;

        .list {
            padding: 8px 12px;
            border-radius: 4px;
            border: 2px solid var(--light-neutral);
            margin: 16px 0 0;
            width: 100%;

            &.col-1 {
                width: 100%;
            }

            &.col-2 {
                width: calc(50% - 8px);

                &:nth-child(1) {
                    margin-right: 8px;
                }

                &:nth-child(2) {
                    margin-left: 8px;
                }
            }
        }

        .label {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            h3 {
                flex: 1;
                font: var(--font-med-12);
                color: var(--black-50);

                span {
                    padding-right: 8px;
                }
            }

            .btn,
            i {
                height: 24px;
                width: 24px;
                border-radius: 4px;
                border: 1px solid #CBD4E1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 14px;
                    width: 14px;
                    fill: var(--black-90);
                }

                &:hover {
                    border-color: var(--dark-blue);

                    svg {
                        fill: var(--dark-blue);
                    }
                }
            }

            .btn {
                width: 60px;

                svg {
                    margin-right: 4px;
                    height: 12px;
                    width: 12px;
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            span.mention {
                &::before {
                    content: '@';
                }
            }

            span.hashtag {
                &::before {
                    content: '#';
                }
            }

            p {
                color: var(--black-50);
                font-size: 12px;
                margin: 0;
            }
        }

        .ui-button {
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            display: inline-flex;
            align-items: center;
            padding-left: 8px;
            padding-right: 8px;

            i {
                height: 14px;
                width: 14px;
                margin-left: 4px;
                cursor: pointer;

                svg {
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    fill: var(--red);
                }
            }
        }
    }
}
</style>