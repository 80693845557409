<template>
    <div :class="classnames">
        {{ value }}
    </div>
</template>

<script>

export default {
    name: 'my-badges-iden',

    props: {
        value: {
            type: [String, Number],
            default: 'Label',
        },
        type: {
            type: String,
            default: 'normal',//
        },
        size: {
            type: String,
            default: 'small',//large
        },

        fonts:{
            type: String,
            default: 'intial',
        },
        border:{
            type: String,
            default: 'normal',
        }
    },

    data() {
        return {

        }
    },

    computed: {
        classnames() {
            let arr = ['my-badges-iden', `type-${this.type}`, `size-${this.size}`,`font-${this.fonts}`,`border-${this.border}`];
            return arr;
        }
    },

    methods: {

    },

    mounted() {
    },
}

</script>

<style lang="scss">
.my-badges-iden {
    color: var(--black-90);
    display: inline-block;

    &.type {

        &-normal {
            background-color: var(--bg-02);
        }

        &-yellow {
            background-color: var(--light-yellow);
        }

        &-green {
            background-color: var(--light-green);
        }

        &-red {
            background-color: var(--bg-11);
        }

        &-gray{
            background-color: var(--bg-03);
        }

        &-black {
            background-color: var(--black-100);
            color:var(--white);
        }

    }

    &.border{
        &-4 {
            border-radius: 4px;
        }
    }

    &.size {
        &-small {
            padding: 4px 5px;
        }
    }

    &.font{
        &-intial{
            font:var(--font-med-12);
        }

        &-fgm{
            font:var(--font-fg-12);
        }

    }


}
</style>