<template>
    <div class="ui-filter-dropdown">
        <div class="ui-filter-dropdown--inner" :class="{ 'hide-popup': is_hide_popup }">
            <Button size="32" :color="'black-60'" :style="'border-square'" :class="{ 'disabled': disabled }"
                @click="is_show_select = (disabled) ? false : !is_show_select">
                <SvgIcon name="arrow" />
                <span>Filter</span>
            </Button>

            <ul v-if="is_show_select" class="item-box" :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <li class="item" @mouseover="main_index = 0" @click="selectFilter('default', null)">
                        <span>Default</span>
                    </li>
                    <!-- <li class="item" :class="{ 'selected': main_index === 1 }" @mouseover="main_index = 1">
                        <span>Onboard Status</span>
                        <SvgIcon name="next" />
                    </li> -->
                    <li class="item" :class="{ 'selected': main_index === 2 }" @mouseover="main_index = 2">
                        <span>Storefront Status</span>
                        <SvgIcon name="next" />
                    </li>
                    <li class="item" :class="{ 'selected': main_index === 3 }" @mouseover="main_index = 3">
                        <span>Added</span>
                        <SvgIcon name="next" />
                    </li>
                    <li class="item metric" :class="{ 'selected': main_index === 4 }" @mouseover="main_index = 4">
                        <span>Metric</span>
                        <SvgIcon name="next" />
                    </li>

                    <!-- main_index==4 -->
                    <li class="item sub" v-if="main_index === 4" :class="{ 'selected': metric === 0 }"
                        @click="metric = 0" @mouseover="main_index = 4">
                        <span>Views</span>
                    </li>
                    <li class="item sub" v-if="main_index === 4" :class="{ 'selected': metric === 3 }"
                        @click="metric = 3" @mouseover="main_index = 4">
                        <span>Clicks</span>
                    </li>
                    <li class="item sub" v-if="main_index === 4" :class="{ 'selected': metric === 2 }"
                        @click="metric = 2" @mouseover="main_index = 4">
                        <span>Sales</span>
                    </li>
                    <li class="item sub" v-if="main_index === 4" :class="{ 'selected': metric === 1 }"
                        @click="metric = 1" @mouseover="main_index = 4">
                        <span>Sales Value</span>
                    </li>

                    <!-- main_index==5 -->
                    <li class="item metric" :class="{ 'selected': main_index === 5 }" @mouseover="main_index = 5" v-if="$store.state.user_data.is_motom_internal_user">
                        <span>Social Followers</span>
                        <SvgIcon name="next" />
                    </li>
                    <!-- sub item / main_index==5 -->
                    <li class="item sub" v-if="main_index === 5" :class="{ 'selected': params.social.metric === 1 }"
                        @click="params.social.metric = 1">
                        <span>TikTok</span>
                    </li>
                    <li class="item sub" v-if="main_index === 5" :class="{ 'selected': params.social.metric === 2 }"
                        @click="params.social.metric = 2">
                        <span>Instagram</span>
                    </li>
                </Scrollbar>
            </ul>

            <ul v-if="is_show_select && main_index === 1" class="item-box sub" :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <li class="item" @click="selectFilter('profile_status', 0)">
                        <SvgIcon name="yes" v-if="params.profile_status === 0" /><i v-else
                            class="select-icon" /><span>All</span>
                    </li>
                    <li class="item" @click="selectFilter('profile_status', 1)">
                        <SvgIcon name="yes" v-if="params.profile_status === 1" /><i v-else
                            class="select-icon" /><span>Joined</span>
                    </li>
                    <li class="item" @click="selectFilter('profile_status', 2)">
                        <SvgIcon name="yes" v-if="params.profile_status === 2" /><i v-else
                            class="select-icon" /><span>Pending</span>
                    </li>
                    <!-- <li class="item" @click="selectFilter('profile_status', 3)">
                        <SvgIcon name="yes" v-if="params.profile_status === 3" /><i v-else class="select-icon" /><span>Deleted</span>
                    </li> -->
                </Scrollbar>
            </ul>

            <ul v-if="is_show_select && main_index === 2" class="item-box sub" :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <li class="item" @click="selectFilter('storefront_status', 0)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 0" /><i v-else
                            class="select-icon" /><span>All</span>
                    </li>
                    <li class="item" @click="selectFilter('storefront_status', 1)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 1" /><i v-else
                            class="select-icon" /><span>Active</span>
                    </li>
                    <li class="item" @click="selectFilter('storefront_status', 2)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 2" /><i v-else
                            class="select-icon" /><span>Unavailable</span>
                    </li>
                    <li class="item" @click="selectFilter('storefront_status', 3)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 3" /><i v-else
                            class="select-icon" /><span>Disabled</span>
                    </li>
                    <li class="item" @click="selectFilter('storefront_status', 4)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 4" /><i v-else
                            class="select-icon" /><span>Declined</span>
                    </li>
                    <li class="item" @click="selectFilter('storefront_status', 5)">
                        <SvgIcon name="yes" v-if="params.storefront_status === 5" /><i v-else
                            class="select-icon" /><span>Invited</span>
                    </li>
                </Scrollbar>
            </ul>

            <ul v-if="is_show_select && main_index === 3" class="item-box sub" :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <li class="item" @click="selectFilter('recently_added', 0)">
                        <SvgIcon name="yes" v-if="params.recently_added === 0" /><i v-else
                            class="select-icon" /><span>All</span>
                    </li>
                    <li class="item" @click="selectFilter('recently_added', 1)">
                        <SvgIcon name="yes" v-if="params.recently_added === 1" /><i v-else
                            class="select-icon" /><span>Recently
                            Added</span>
                    </li>
                    <!-- <li class="item" @click="selectFilter('recently_added', 2)">
                    <SvgIcon name="yes" v-if="params.recently_added === 2" /><i v-else class="select-icon" /><span>Earliest added</span>
                </li> -->
                </Scrollbar>
            </ul>

            <div v-if="is_show_select && main_index === 4" class="item-box sub metric"
                :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <div class="text">{{
            metric === 3 ? 'Clicks' :
                metric === 2 ? 'Sales' :
                    metric === 1 ? 'Sales Value' :
                        'Views' }}</div>
                    <div class="list">
                        <div class="item" @click="metric_type = 0">
                            <SvgIcon name="yes" v-if="metric_type === 0" /><i v-else class="select-icon" /><span>Greater
                                than</span>
                        </div>
                        <div class="item" @click="metric_type = 1">
                            <SvgIcon name="yes" v-if="metric_type === 1" /><i v-else class="select-icon" /><span>Less
                                than</span>
                        </div>
                        <div class="item" @click="metric_type = 2">
                            <SvgIcon name="yes" v-if="metric_type === 2" /><i v-else
                                class="select-icon" /><span>Custom</span>
                        </div>
                    </div>
                    <div class="amount-box" v-if="metric_type === 2">
                        <input placeholder="Least" v-model="metric_min_amount_input"
                            :class="{ 'alert': metric_msg !== '' }">
                        <span>——</span>
                        <input placeholder="Greatest" v-model="metric_max_amount_input"
                            :class="{ 'alert': metric_msg !== '' }">
                    </div>
                    <input placeholder="Number" v-model="metric_amount_input" :class="{ 'alert': metric_msg !== '' }"
                        v-else>
                    <div class="footer">
                        <Button type="button" color="dark-blue" size="32" @click="selectFilter('clear')">Clear</Button>
                        <Button type="button" color="dark-blue" size="32" @click="selectFilter('apply')">Apply</Button>
                    </div>
                    <p>{{ metric_msg }}</p>
                </Scrollbar>
            </div>

            <div v-if="is_show_select && main_index === 5" class="item-box sub metric"
                :style="`height:${item_box_height}px`">
                <Scrollbar class="scroll-inner">
                    <div class="text">{{ params.social.metric === 1 ? 'TikTok followers' : 'Instagram followers' }}
                    </div>

                    <div class="amount-box">
                        <div class="input-box"><span>Minimum</span> <input placeholder="Minimum"
                                v-model="social.metric_min_amount_input"
                                :class="{ 'alert': params.social.metric_msg !== '' }">
                        </div>

                        <span>——</span>
                        <div class="input-box"><span>Maximum</span>
                            <input placeholder="Maximum" v-model="social.metric_max_amount_input"
                                :class="{ 'alert': params.social.metric_msg !== '' }">
                        </div>
                    </div>

                    <div class="footer">
                        <Button type="button" color="dark-blue" size="32"
                            @click="selectFilter('clear-follow')">Clear</Button>
                        <Button type="button" color="dark-blue" size="32"
                            @click="selectFilter('apply-follow')">Apply</Button>
                    </div>
                    <p>{{ params.social.metric_msg }}</p>
                </Scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default {
    name: 'Dropdown',

    components: {
        SvgIcon,
        Button,
        Scrollbar
    },

    data() {
        return {
            is_show_select: false,
            selected_index: 0,

            is_hide_popup: false,
            main_index: 0,
            metric_msg: '',
            metric: 0,
            metric_type: 0,
            metric_amount_input: '',
            metric_min_amount_input: '',
            metric_max_amount_input: '',
            social: {
                metric: 1,
                metric_min_amount_input: '',
                metric_max_amount_input: ''
            }
        }
    },

    props: {
        params: {
            type: Object,
        },
        direction_type: {
            type: String,
            default: 'left',
        },
        items: {
            type: Array,
            default: [],
        },
        default_text: {
            type: String,
            default: '',
        },
        default_value: {
            type: Number,
            default: 0,
        },
        is_update_index: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        item_box_height() {
            //1 item == 34px,there is 5 item and 4 sub item / 2 sub item
            if(this.$store.state.user_data.is_motom_internal_user){
                let sub_four = 4 * 34;
                let sub_five = 2 * 34;
                let open = this.main_index == 4 ? sub_four : this.main_index === 5 ? sub_five : 0;
                let items_height = 5 * 34 + open;
                return items_height + 16;
            }else{
                let open = this.main_index == 4 ? 4 * 34 : 0;
                let items_height = 4 * 34 + open;
                return items_height + 16;
            }
        }
    },

    methods: {
        // selectType(index) {
        //     if (this.items[index].disabled) return;
        //     this.is_show_select = false;
        //     if (this.is_update_index) this.selected_index = index;
        //     this.$emit('update-dropdown', 
        //         this.items[index],
        //     );
        // },

        selectFilter(type, index) {
            this.is_hide_popup = true;
            this.is_show_select = false

            setTimeout(() => {
                this.is_hide_popup = false;
            }, 100);

            if (type === 'default') {
                this.metric_amount_input = '';
                this.metric_min_amount_input = '';
                this.metric_max_amount_input = '';
                let d = {
                    profile_status: 0, storefront_status: 0,
                    recently_added: 0, metric: 0,
                    metric_type: 0, metric_amount: '', metric_min_amount: '', metric_max_amount: '',
                    social: {
                        metric: 1,
                        metric_msg: '',
                        metric_min_amount_input: '',
                        metric_max_amount_input: ''
                    }
                };
                this.$emit('update-filter', d);
            }
            else {
                if (type === 'profile_status') this.params.profile_status = index;
                else if (type === 'storefront_status') this.params.storefront_status = index;
                else if (type === 'recently_added') this.params.recently_added = index;
                else if (type === 'clear') {
                    this.params.metric_amount = '';
                    this.params.metric_min_amount = '';
                    this.params.metric_max_amount = '';
                    this.metric_amount_input = '';
                    this.metric_min_amount_input = '';
                    this.metric_max_amount_input = '';
                }
                else if (type === 'apply') {
                    if (((this.params.metric_type === 0 || this.params.metric_type === 1) && isNaN(this.metric_amount_input)) ||
                        (this.params.metric_type === 2 && (isNaN(this.metric_min_amount_input) || isNaN(this.metric_max_amount_input)))) {
                        this.metric_msg = '* Please input amount';
                        return;
                    }
                    else {
                        this.metric_msg = '';
                        this.params.metric = this.metric;
                        this.params.metric_type = this.metric_type;
                        this.params.metric_amount = this.metric_amount_input;
                        this.params.metric_min_amount = this.metric_min_amount_input;
                        this.params.metric_max_amount = this.metric_max_amount_input;
                    }
                }
                else if (type === 'clear-follow') {
                    this.params.social.metric_min_amount_input = '';
                    this.params.social.metric_max_amount_input = '';
                    this.social.metric_min_amount_input = '';
                    this.social.metric_max_amount_input = '';

                } else if (type === 'apply-follow') {
                    if (isNaN(this.social.metric_min_amount_input) || isNaN(this.social.metric_max_amount_input) || !this.social.metric_min_amount_input || !this.social.metric_max_amount_input) {
                        this.params.social.metric_msg = '* Please input amount';
                        return;
                    } else {
                        this.params.social.metric_msg = '';
                        this.params.social.metric_min_amount_input = this.social.metric_min_amount_input;
                        this.params.social.metric_max_amount_input = this.social.metric_max_amount_input;
                    }
                }
                this.$emit('update-filter', this.params);
            }
        },

        handleClick(e) {
            if (!this.is_show_select) return;
            let root_parent = document.body;
            let is_select_el = false;

            if (e.target.classList.contains('ui-filter-dropdown')) {
                is_select_el = true;
            } else {
                let p = e.target.parentNode;

                while (p !== root_parent) {
                    var o = p;
                    if (o.parentNode) p = o.parentNode;
                    else return;

                    if (o.classList.contains('ui-filter-dropdown')) {
                        is_select_el = true;
                        // const els = document.querySelectorAll('.ui-filter-dropdown');
                        // if (els && els.length !== 0) els.forEach(element => {
                        //     if (o !== element)
                        //         element.classList.remove('open'); // close others select
                        // });
                    }
                }
            }

            if (!is_select_el) this.is_show_select = false;
        },
    },

    created() {
        window.addEventListener('click', this.handleClick);
    },

    destroyed() {
        window.removeEventListener('click', this.handleClick);
    },

    mounted() {
    },
}
</script>
<style lang="scss">
.inner {
    //height: 70vh;
}

.ui-filter-dropdown {
    &--inner {
        position: relative;

        .ui-button {
            margin: 0 !important;
            padding: 0 10px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-color: var(--black-10) !important;
            background-color: var(--black-10);

            span {
                // margin-left: 5px;
                font: var(--font-fg-14);
                color: var(--black-60);
            }

            svg {
                width: 8px !important;
                height: 8px !important;
                margin-right: 6px !important;
                // margin-right: 5px;
                color: black !important;
            }

            &.disabled {
                border: 1px solid var(--black-10);
                cursor: not-allowed;

                span {
                    color: var(--black-40);
                }

                svg {
                    fill: var(--black-40);
                }
            }
        }


        &.hide-popup>ul,
        &.hide-popup>div {
            display: none !important;
        }

        ul {
            //min-height: 188px;
        }

        div.metric {
            width: 310px;
            height: 325px;

            .text {
                padding: 4px 10px;
                color: var(--dark-blue);
                font-size: 16px;
            }

            .list {
                display: flex;
            }


            input {
                width: calc(100% - 20px);
                height: 32px;
                margin: 9px 10px;
                padding: 12px 10px;
                border: 1px solid #E0E0E0;
                border-radius: 6px;
                font: var(--font-fg-14);

                &.alert {
                    border-color: var(--alert);
                }
            }

            .input-box {
                font: var(--font-med-10);
                color: var(--black-50);
                margin: 9px 0;


                span {
                    margin-bottom: 2px;
                    margin: 9px 10px;
                }

                input {
                    margin: 0 10px;
                }
            }

            .amount-box {
                display: flex;
                align-items: center;
            }

            p {
                margin: 0 10px;
                color: var(--alert);
            }

            .footer {
                position: absolute;
                bottom: 8px;
                right: 10px;

                .ui-button {
                    color: var(--black-10) !important;
                    background: var(--dark-blue) !important;

                    &:first-child {
                        margin: 8px !important;
                        color: var(--dark-blue) !important;
                        background: unset !important;
                    }
                }


            }
        }

        .scroll-inner {
            height: 100%;
            overflow: auto;
        }

        .item-box {
            padding: 8px 0;
            // width: 238px;
            width: 150px;
            background: white;
            border: 1px solid #F2F2F2;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 6px 0 0 6px;
            position: absolute;
            z-index: 9;
            max-height: 40vh;

            &.sub {
                left: 150px;
                border-radius: 0 6px 6px 0;

                .item>svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px !important;
                    fill: var(--dark-blue);
                }
            }

            &.direction--right {
                right: 0px;
            }

            .item {
                padding: 9px 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                color: var(--black-60);
                font: var(--font-fg-12);

                &.sub {
                    padding-left: 20px;
                }

                span {
                    flex: 1;
                }

                &:hover,
                &.selected {
                    background: var(--bg-02);
                    color: var(--dark-blue);
                    font: var(--font-fg-12);

                    &.metric {
                        svg {
                            transform: rotate(90deg);
                        }
                    }
                }

                &.disabled {
                    color: var(--black-40);
                    cursor: not-allowed;

                    .select-icon {
                        border: 1px solid var(--black-40);
                    }
                }

                svg {
                    width: 8px;
                    height: 8px;
                    margin-right: unset !important;
                    fill: var(--dark-blue);
                }

                .select-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                    border: 1px solid var(--black-50);
                    border-radius: 50%;
                    background-color: white;
                }
            }
        }
    }
}
</style>
