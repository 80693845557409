<template>
    <router-link :to="to" :class="classnames" v-if="to && !is_external">
        <slot />
    </router-link>
    <a :href="to" target="_blank" :class="classnames" v-else-if="to && is_external">
        <slot />
    </a>
    <button :type="type" :class="classnames" v-else-if="type === 'submit' || type === 'button'">
        <slot />
    </button>
    <span :class="classnames" v-else>
        <slot />
    </span>
</template>

<script>
export default {
    name: 'UiButton',

    props: {
        type: {
            type: String,
            default: 'bg'
        },

        style: {
            type: String,
            default: 'bg'
        },

        color: {
            type: String,
            default: ''
        },

        size: {
            type: String,
            default: '32'
        },

        to: {
            type: String,
            default: ''
        },

        is_external: {
            type: Boolean,
            default: false
        },

        is_disabled: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            classnames: ['ui-button'],
        }
    },

    watch: {
        is_disabled(val) {
            if (val) {
                this.classnames.push('disabled');
            } else {
                const index = this.classnames.indexOf('disabled');
                if (index !== -1) this.classnames.splice(index, 1);
            }
        },
        style(newValue, oldValue) {
            this.$nextTick(() => {
                if (oldValue) {
                    const oldClass = 'type-' + oldValue;
                    const index = this.classnames.indexOf(oldClass);
                    if (index !== -1) {
                        this.classnames.splice(index, 1);
                    }
                }

                // add new class
                if (newValue) {
                    this.classnames.push('type-' + newValue);
                }
            });
        },
    },

    mounted() {
        this.classnames.push('type-' + this.style)
        this.classnames.push('size-' + this.size)
        this.classnames.push('color-' + this.color)

        if (this.is_disabled) {
            this.classnames.push('disabled')
        } else {
            if (this.classnames.indexOf('disabled') > -1) {
                this.classnames.splice(this.classnames.indexOf('disabled'), 1)
            }
        }
    }
}
</script>

<style lang="scss">
.ui-button {
    display: inline-block;
    // font: var(--font-demi-14);
    font: var(--font-fg-14);

    &.type-bg {
        color: white;
        cursor: pointer;
        border: none;

        &.size-40 {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 20px;
        }

        &.size-32 {
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
            border-radius: 16px;

        }

        &.color-dark-blue {
            background-color: var(--dark-blue);
            color: white;

            &:hover {
                color: white;
            }
        }

        &.color-white {
            background-color: white;
            color: var(--dark-blue);

            &:hover {
                color: var(--dark-blue);
            }
        }

        &.color-light-neutral {
            background-color: var(--light-neutral);
            color: var(--black-90);
        }

        &.color-transparent {
            background-color: transparent;
            color: var(--dark-red);

            &:hover {
                color: var(--red);
            }
        }

        &.disabled {
            background-color: var(--black-10);
            color: var(--black-40);

            &:hover {
                color: var(--black-40);
            }
        }
    }

    &.type-border {
        background: white;
        color: white;
        cursor: pointer;
        clear: both;
        border: 1px solid black;
        box-sizing: content-box;

        &.size-40 {
            height: 38px;
            line-height: 38px;
            padding: 0 20px;
            border-radius: 20px;
        }

        &.size-24 {
            height: 22px;
            font-size: 12px;
            line-height: 22px;
            padding: 3px 8px;
            border-radius: 100px;

            svg {
                height: 10px;
                width: 10px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 16px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-56 {
            height: 54px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 20px;
            border-radius: 100px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

        &.color-black-60 {
            border-color: var(--black-60);
            color: var(--black-60);
        }

        &.color-dark-blue {
            border-color: var(--dark-blue);
            color: var(--dark-blue);
        }

        &.color-light-neutral {
            border-color: var(--light-neutral);
            color: var(--black-90);
        }


        &.color-alert {
            border-color: var(--alert);
            color: var(--alert);
        }

        &.disabled {
            border-color: var(--black-20);
            color: var(--black-20);
        }
    }

    &.type-border-square {
        background: white;
        color: white;
        cursor: pointer;
        clear: both;
        border: 1px solid black;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        transition: .3s;

        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 6px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-40 {
            height: 40px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 20px;
            border-radius: 100px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

        &.color-black-60 {
            border-color: var(--black-60);
            color: var(--black-60);
        }

        &.color-black-20 {
            border-color: var(--black-20);
            color: var(--black-20);
        }

        &.color-dark-blue {
            border-color: var(--dark-blue);
            color: var(--dark-blue);
        }

        &.disabled {
            border-color: var(--black-20);
            color: var(--black-20);
        }
    }

    &.type-no-border-square {
        background: white;
        cursor: pointer;
        clear: both;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        transition: .3s;

        &:hover{
            background-color: var(--bg-02);
        }


        &.size-32 {
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 16px;
            border-radius: 6px;

            svg {
                height: 16px;
                width: 16px;
                margin-right: 1px;
                top: 3px;
            }
        }

        &.size-40 {
            height: 40px;
            font-size: 16px;
            line-height: 54px;
            padding: 0 16px;
            border: none;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &.color-black-90 {
            border-color: var(--black-90);
            color: var(--black-90);
        }

    }

    &.color-no-color {
        background-color: transparent;
        color: var(--black-50);
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
    }
}
</style>
