<template>
    <div class="ui-dropdown js-ui-dropdown" ref="item">
        <div class="ui-dropdown--inner">
            <Button size="32" :color="'black-60'" :style="'border-square'" :class="{ 'disabled': disabled }"
                @click.stop="toggle">
                <svg-icon v-if="icon_direction_type === 'left'" :name="icon" class="icon-left" />
                <span>{{ default_text }}</span>
                <svg-icon v-if="icon_direction_type === 'right'" :name="icon" class="icon-right" />
                <svg-icon v-if="icon_direction_type === 'center'" :name="icon" class="icon-center" />
                <tippy :delay="[0, 20]" class="tooltip" v-if="tooltip_text" :content="tooltip_text" trigger="mouseenter click"/>
            </Button>

            <div
                class="js-tags-list"
                :data-id="id"
                :class="['item-box', 'direction--' + direction_type, { 'direction--top': on_bottom }, { 'item-none-box': no_item }, {'is-show': is_show_select}]">
                <div class="the-name">Select Custom Tags</div>
                <ul v-if="!no_item">
                    <li v-for="(item, index) in items" :key="index"
                        :class="['item', item.disabled && 'disabled', item.hide && 'hide']"
                        @click.stop="selectType(item)">
                        <svg-icon name="yes" v-if="selected_items.filter((o) => o.text === item.text).length !== 0" />
                        <i v-else class="select-icon" />
                        <span>{{ item.text }}</span>
                    </li>
                </ul>
                <div v-else class="tag-button tag-button-none">
                    <Button :style="'border'" size="32" :color="'black-90'" @click.stop="$emit('editTag')">
                        <svg-icon name="plus" /> Add first Tag
                    </Button>
                </div>
                <slot />
            </div>

        </div>
    </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
    name: 'Dropdown',

    components: {
        Button,
    },

    data() {
        return {
            id: '',
            is_show_select: false,
            selected_index: 0,
            on_bottom: false,
            no_item: false,
        }
    },

    props: {
        icon: {
            type: String,
            default: 'arrow',
        },
        direction_type: {
            type: String,
            default: 'left',
        },
        icon_direction_type: {
            type: String,
            default: 'left',
        },
        items: {
            type: Array,
            default: [],
        },
        selected_items: {
            type: Array,
            default: [],
        },
        default_text: {
            type: String,
            default: '',
        },
        tooltip_text: {
            type: String,
            default: '',
        },
        default_value: {
            type: Number,
            default: 0,
        },
        is_update_index: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        is_select_dropdown: {
            type: Boolean,
            default: false,
        },
        array_key: {
            type: Number,
            default: null,
        },
        no_icon: {
            type: Boolean,
            default: false,
        },
        // no_item: {
        //     type: Boolean,
        //     default: false,
        // },
    },

    methods: {
        createUUID() {
            return 'xxxxxxxx'.replace(/[x]/g, function(c) {
                var r = Math.random() * 16 | 0;
                return r.toString(16);
            });
        },
        toggle() {

            this.is_show_select = (this.disabled) ? false : !this.is_show_select;

            const rect = this.$refs.item.getBoundingClientRect();
            this.on_bottom = rect.bottom > (window.innerHeight - 180);

            this.$emit('toggle',
                this.items[this.selected_index],
            );

            const lists = document.querySelectorAll('.js-tags-list');
            lists.forEach((list) => {
                if (list.dataset.id !== this.id) {
                    list.classList.remove('is-show');
                }
            });
        },

        selectType(item) {
            if (item.disabled) return;
            this.$emit('update-select', item);
        },

        handleClick(e) {
            this.is_show_select = false;


        },
    },

    created() {
        window.addEventListener('click', this.handleClick);
    },

    destroyed() {
        window.removeEventListener('click', this.handleClick);
    },

    mounted() {
        this.no_item = this.items.length === 0;
        this.id = 'ui-dropdown-' + this.createUUID();
    },
}
</script>
<style lang="scss" scoped>
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.ui-dropdown {
    &--inner {
        position: relative;

        .ui-button {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            max-width: 240px;

            .tooltip {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 3;
                cursor: pointer;
            }

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            svg {
                width: 8px;
                height: 8px;
                color: black !important;

                &.icon-left {
                    margin-right: 6px !important;
                }

                &.icon-right {
                    margin-left: 6px !important;
                }

            }

            &.disabled {
                border: 1px solid var(--black-10);
                cursor: not-allowed;

                .tooltip {
                    cursor: not-allowed;
                }

                span {
                    color: var(--black-40);
                }

                svg {
                    fill: var(--black-40);
                }
            }
        }

        .item-box {
            padding: 8px 0;
            // width: 238px;
            width: max-content;
            background: white;
            border: 1px solid #F2F2F2;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            position: absolute;
            z-index: 999;
            min-width: 180px;
            display: none;

            &.is-show {
                display: block;
            }

            &.item-none-box {
                min-height: 200px;

                .tag-button-none {
                    min-height: 150px;
                    @include flexCenter();
                    justify-content: center;
                }
            }

            .the-name {
                padding: 4px 8px;
                font: var(--font-med-12);
                color: var(--black-50);
            }

            &.direction--right {
                right: 0px;
            }

            &.direction--top {
                bottom: 100%;
            }


            .item {
                padding: 6px 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                color: var(--black-90);
                font: var(--font-med-12);

                &:hover {
                    background: var(--bg-02);
                }

                &.disabled {
                    color: var(--black-40);
                    cursor: not-allowed;

                    .select-icon {
                        border: 1px solid var(--black-40);
                    }
                }

                &.hide {
                    display: none;
                }

                &.icon-display-none {

                    svg,
                    .select-icon {
                        display: none;
                    }
                }

                svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                    fill: var(--dark-blue);
                }

                .select-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                    border: 1px solid var(--black-50);
                    border-radius: 50%;
                    background-color: white;
                }
            }
        }

    }


}
</style>
<style lang="scss">
.ui-dropdown {

    .hr {
        border-top: solid 1px var(--black-40);
        width: 100%;
        overflow: hidden;
        height: 0px;
        border-bottom: 0;
    }

    .tag-button {
        margin: 6px 8px 0;

        .ui-button {
            border: solid 1px var(--black-40);
            border-radius: 6px;
            width: fit-content;

            .svg-icon {
                width: 13px !important;
                height: 13px !important;
                margin-left: 4px;
            }
        }
    }


    .tag-button.tag-button-none {
        .ui-button {
            font: var(--font-med-12);

            .svg-icon {
                margin-right: 4px;
                height: 10px !important;
                width: 10px !important;
            }
        }
    }

}
</style>