<template>
    <ModalSide class="creator-modal" ref="modal" @creator-next-page-left="nextProductListPage(0)"
        @creator-next-page-right="nextProductListPage(0)">
        <header v-if="data_info">
            <div class="title temp-css-in-title">
                <span>{{ data_info.first_name + ' ' + data_info.last_name }}</span>
            </div>
            <div class="icon-box">
                <div class="icon" v-if="$store.state.user_data.is_motom_internal_user" @click="tempBtnForFb()">
                    <SvgIcon name="refrash" />
                </div>
                <div class="icon" v-if="$store.state.user_data.is_motom_internal_user && data_info.email"
                    @click="emailTo(data_info.email)" v-tippy="{ content: data_info.email, delay: [500, 20] }">
                    <SvgIcon name="email" />
                </div>
                <div class="icon" v-if="data_info.anchor_page_status === 100"
                    @click="viewStorefront(data_info.share_link)">
                    <SvgIcon name="storefront" />
                </div>
                <div class="icon" v-if="data_info.status_text !== 'joined' && $store.state.role_id < 3"
                    :class="['edit']" @click="editInfo">
                    <SvgIcon name="pen" />
                </div>
                <div class="icon" v-if="data_info.anchor_page_status === 100" @click="share">
                    <SvgIcon name="share-2" />
                </div>
                <div class="icon close" @click="close">
                    <SvgIcon name="close" />
                </div>
            </div>
        </header>

        <div class="box">
            <Loading class="static" v-if="!data_info" />
            <div v-if="data_info" class="creator-info-box">
                <figure class="avatar">
                    <img :src="getUserImage(data_info.userProfile.avatar_full_url)" />
                </figure>
                <div class="creator-info">
                    <div
                        :class="['creator-data-box', user_handle_value.only_one && 'creator-data-box-only-one', user_handle_value.no_one && 'creator-data-box-no-one']">
                        <!--Storefront-->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="'Storefront'"
                            :content_value="header_content_value.storefront" :had_slot="true"
                            :had_inf="$store.state.user_data && $store.state.user_data.is_motom_internal_user">
                            <template v-slot:head>
                                <div class="right">
                                    <div v-if="data_info.anchor_page_status === -10" class="resend"
                                        @click="resendInvite">
                                        Resend</div>
                                    <!-- <div class="store-date" v-else>{{ $filters.momentFormat(data_info.disabled_at,'ll') }}</div> -->
                                    <div class="value value-act"
                                        :class="[data_info.anchor_page_status_text.toLowerCase()]" @click="clickSwitch">
                                        ● {{ data_info.anchor_page_status_text }}
                                    </div>
                                </div>
                            </template>
                            <template v-slot:item>
                                <div class="item" v-if="$store.state.user_data.is_motom_internal_user">
                                    <div class="content">{{ data_info.status_text }} ● <div class="link-box"
                                            v-if="data_info.status_text == 'Joined'"
                                            @click.stop="GoMedia('mo', data_info.motom_user_id)"><svg-icon
                                                name="link"></svg-icon></div>
                                    </div>
                                    <div class="name">Motom Status</div>
                                </div>
                            </template>
                        </CreatorHeaderSmallBox>
                        <!--tiktok -->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="data_info.tiktok_handle"
                            v-if="data_info.tiktok_handle && $store.state.user_data.is_motom_internal_user"
                            :content_value="header_content_value.tiktok" />
                        <!--ig -->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="data_info.instagram_handle"
                            v-if="data_info.instagram_handle && $store.state.user_data.is_motom_internal_user"
                            :content_value="header_content_value.ig" />

                    </div>
                </div>
            </div>
            <div class="hint" v-if="is_dis_user">
                <!-- v-if="data_statistic.short_link_click_sum_trend !== 'same'" -->
                <SvgIcon name="alert" class="disabled-alert" />
                This storefront is not currently active and cannot generate any performance metrics including sales
                while disabled.
            </div>

            <Navi :menu="menu" :type="1" @selected-tab="selectedTab" v-if="data_info" />

            <div id="scroll-content-left" v-if="selected_tab_index === 0">
                <StorefrontSummary :key="storefrontSummary_refrash" :show_date_picker="true" :user_id="data.id"
                    :last_update="$filters.momentFormat(this.data_info.show_status_time, 'll')" :is_show_posts="true" :date_range="date_range"
                    :products_num="data_products" @update-creator-modal-date="updateModalDate"
                    :api_url="summary_api_url" />

                <div class="short-link"
                    v-if="data_statistic && (data_statistic.short_link_count + data_statistic.short_link_click_sum + data_statistic.short_link_sales_sum + data_statistic.short_link_sales_value_sum) > 0">
                    <header class="title-box">
                        <h2 class="title">Affiliate Shortlink Summary</h2>
                    </header>
                    <div class="report-box">
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_count === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_count">{{
        data_statistic.short_link_count.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.page_views_trend !== 'same'"
                                    :class="data_statistic.page_views_trend" />
                            </div>
                            <div class="text">Shortlink(s)</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_click_sum === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_click_sum">{{
        data_statistic.short_link_click_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_click_sum_trend !== 'same'"
                                    :class="data_statistic.short_link_click_sum_trend" />
                            </div>
                            <div class="text">Clicks</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_sales_sum === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_sales_sum">{{
        data_statistic.short_link_sales_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_sales_trend !== 'same'"
                                    :class="data_statistic.short_link_sales_trend" />
                            </div>
                            <div class="text">Sales</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_sales_value_sum === 0">---</div>
                            <div class="value" v-else>${{ formatMoney(data_statistic.short_link_sales_value_sum) }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_sales_value_trend !== 'same'"
                                    :class="data_statistic.short_link_sales_value_trend" />
                            </div>
                            <div class="text">Sales Value</div>
                        </div>
                    </div>
                </div>
                <div class="doughnuts">
                    <div class="doughnut-box">
                        <div class="title">
                            <span>Traffic Source</span>
                            <Dropdown :items="doughnut_select" :direction_type="'right'" :icon_direction_type="'right'"
                                @update-dropdown="getDoughnutSource" ref="dropdown_source" />
                        </div>
                        <Loading class="static" v-if="is_loading_source" />
                        <Doughnut v-else :color="source_doughnut_color" ref="source" :key="is_loading_source" />
                    </div>
                    <div class="doughnut-box">
                        <div class="title">
                            <span>Operating Systems</span>
                            <Dropdown :items="doughnut_select" :direction_type="'right'" :icon_direction_type="'right'"
                                @update-dropdown="getDoughnutDevice" ref="dropdown_device" />
                        </div>
                        <Loading class="static" v-if="is_loading_device" />
                        <Doughnut v-else :color="device_doughnut_color" ref="device" :key="is_loading_device" />
                    </div>
                </div>

                <ProductList :title="top_performing.title" :data="data_products" :date_range="date_range"
                    :sort_index="product_list_sort_index" :is_loading="is_loading_products"
                    :is_loading_page="is_loading_products_page" @update-sort="getProductList"
                    :no_data_context="{ title: top_performing.context.title, content: top_performing.context.content }"
                    :data_info="data_info" :is_swiper="true" />
            </div>

            <div id="scroll-content-right" v-if="selected_tab_index === 1">
                <div class="short-link">
                    <Loading class="static" v-if="!data_statistic" />
                    <div class="report-box"
                        v-if="data_statistic && (data_statistic.page_views_sum + data_statistic.click_sum + data_statistic.sales_sum + data_statistic.sales_value_sum) > 0">
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.page_views_sum === 0">---</div>
                            <div class="value" v-else-if="data_statistic.page_views_sum">{{
        data_statistic.page_views_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.page_views_trend !== 'same'"
                                    :class="data_statistic.page_views_trend" />
                            </div>
                            <div class="text">Page Views</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.click_sum === 0">---</div>
                            <div class="value" v-else>{{ data_statistic.click_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.click_trend !== 'same'"
                                    :class="data_statistic.click_trend" />
                            </div>
                            <div class="text">Clicks</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.sales_sum === 0">---</div>
                            <div class="value" v-else>{{ data_statistic.sales_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.sales_trend !== 'same'"
                                    :class="data_statistic.sales_trend" />
                            </div>
                            <div class="text">Sales</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.sales_value_sum === 0">---</div>
                            <div class="value" v-else>${{ formatMoney(data_statistic.sales_value_sum) }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.sales_value_trend !== 'same'"
                                    :class="data_statistic.sales_value_trend" />
                            </div>
                            <div class="text">Sales Value</div>
                        </div>
                    </div>
                </div>
                <ProductList :title="`All Products (${data_products.length})`" :show_date_picker="true"
                    :data="data_products" :date_range="date_range" :sort_index="product_list_sort_index"
                    :is_loading="is_loading_products" @update-sort="getProductList" :data_info="data_info"
                    :no_data_context="{ title: 'No product data available', content: 'Once this creator adds items to their storefront, their product analytics will appear here' }" />

            </div>

            <div id="scroll-content-collection" v-if="selected_tab_index === 2">
                <CollectionList :date_range="date_range" :data_info="data_info" :key="date_range"
                    @update-creator-modal-date="updateModalDate" :is_show_hint="is_dis_user" />
            </div>

            <div id="scroll-content-content" v-if="selected_tab_index === 3">
                <BrandPosts :user_data="data_info" :date_range="date_range" :ref="brand_posts" />
            </div>

            <div id="scroll-content-social" v-if="selected_tab_index === 4" :key="selected_tab_index">
                <SocialStats :date_range="date_range" :data_info="data_info" :key="date_range" :user_data="data_info"
                    :last_update="last_update" @update-creator-modal-date="updateModalDate"
                    :is_show_hint="is_dis_user" />
            </div>
        </div>
    </ModalSide>
    <EditCreator ref="edit" @save="saveEdit" />
    <Modal :is_show="is_show_alert_modal" class="alert-modal" @click-bg="is_show_alert_modal = false">
        <div class="box">
            <header class="delete-header" v-if="data_info.anchor_page_status_text === 'Active'">Deactivate
                storefront(s)?
            </header>
            <header class="delete-header" v-else>Enable storefront(s)?</header>
            <p v-if="data_info.anchor_page_status_text === 'Active'">The storefront link(s) will no longer be active,
                but
                all of the data will remain in the Anchor portal.</p>
            <p v-else>1 selected creator ready to enable storefront, we are exclusive 3 error selection</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.native="is_show_alert_modal = false">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.native="toggleStatus(2)">Yes</Button>
            </div>
        </div>
    </Modal>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Switch from '@/components/ui/Switch.vue';
import Navi from '@/components/Navi.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import ProductList from '@/components/product/ProductList.vue';
import EditCreator from '@/components/creator/EditCreator.vue';
import Modal from '@/components/ui/Modal.vue';
import Button from '@/components/ui/Button.vue';
import formatMoney from '@/utils/formatMoney';
import Loading from '@/components/ui/Loading.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import Doughnut from '@/components/summary/Doughnut.vue';
import BrandPosts from '@/components/BrandPosts.vue';
import { HandleNum, HandleKNum } from '@/utils/SwitchFun';
import { goMedia } from '@/utils/goMedia';
import moment from 'moment';
import CreatorHeaderSmallBox from '@/components/creator/CreatorHeaderSmallBox.vue';
import CollectionList from '@/components/creator/CollectionList.vue';
import SocialStats from '@/components/creator/SocialStats.vue';

export default {
    name: 'CreatorModalSide',

    components: {
        Dropdown,
        Doughnut,
        SvgIcon,
        Switch,
        Navi,
        ModalSide,
        DatePicker,
        StorefrontSummary,
        ProductList,
        EditCreator,
        Button,
        Modal,
        Loading,
        CreatorHeaderSmallBox,
        CollectionList,
        SocialStats,
        BrandPosts,
    },

    data() {
        return {
            editing_name: false,
            is_show_alert_modal: false,
            is_loading_products: true,
            last_update: '',
            start_date: '',
            data_products: [],
            data: null,
            data_info: null,
            data_statistic: null,
            menu: [
                {
                    text: 'Storefront',
                    to: '/analytics/overview',
                },
                {
                    text: 'Products',
                    to: '/analytics/product',
                },
                {
                    text: 'Collections',
                    to: '/analytics/collections',
                },
                {
                    text: 'Content',
                    to: '/analytics/content',
                    for_in: true,
                },
                {
                    text: 'Social Insights',
                    to: '/analytics/social-stats',
                    for_in: true,
                },
            ],
            selected_tab_index: 0,
            total_views: 0,
            total_clicks: 0,
            total_sales: 0,
            total_transactions: 0,

            source_doughnut_color: ['#C32AA3', '#27364B', '#FAA378', '#1DB954', '#FF0000'],
            device_doughnut_color: ['#1DA1F2', '#4F4F4F', '#1DB954'],
            source_doughnut_data: [],
            device_doughnut_data: [],
            doughnut_select: [
                { id: 1, text: 'Views' },
                { id: 2, text: 'Clicks' },
                { id: 3, text: 'Sales' },
                { id: 4, text: 'Sales value' },
            ],
            is_loading_source: true,
            is_loading_device: true,
            top_performing: {
                title: "Top Performing Products",
                context: {
                    title: "No data available",
                    content: "There's not enough performance data to determine top performing products yet."
                }
            },
            sourceList: [
                'instagram',
                'tiktok',
                'youtube',
                'motom',
                'search',
                'other',
                'linktree',
                'beacons',
            ],
            deivceList: [
                'android',
                'ios',
                'windows',
                'other',
            ],
            summary_api_url: '/creator/creator-detail-statistic',
            storefrontSummary_refrash: 0,
            header_content_value: {
                email: {
                    icon: 'email',
                },
                tiktok: {
                    icon: 'tiktok-pure',
                    show_val: 'tt',
                    go_val: 'tk',
                    under: [
                        {
                            name: 'Followers',
                            content: '--'
                        },
                        {
                            name: 'Posts',
                            content: '--'
                        },
                        {
                            name: 'Engagement',
                            content: '--'
                        }
                    ]
                },
                ig: {
                    icon: 'instagram',
                    show_val: 'ins',
                    go_val: 'ig',
                    under: [
                        {
                            name: 'Followers',
                            content: '--'
                        },
                        {
                            name: 'Posts',
                            content: '--'
                        },
                        {
                            name: 'Engagement',
                            content: '--'
                        }
                    ]
                },
                storefront: {
                    icon: 'storefront',
                    show_val: '',
                    go_val: 'mo',
                    under: [
                        {
                            name: 'Last Update',
                            content: '--'
                        },
                        {
                            name: 'Active Date',
                            content: '--'
                        },
                    ]
                },
            }
        }
    },

    computed: {
        date_range() {
            return {
                start_date: this.start_date,
                end_date: this.end_date
            }
        },

        is_dis_user() {
            return this.data_info && this.data_info.anchor_page_status_text === 'Disabled';
        },

        user_handle_value() {
            return {
                only_one: !this.data_info.tiktok_handle || !this.data_info.instagram_handle,
                no_one: (!this.data_info.tiktok_handle && !this.data_info.instagram_handle) || !this.$store.state.user_data.is_motom_internal_user,
                all_had: this.data_info.tiktok_handle && this.data_info.instagram_handle,
            }
        }
    },

    methods: {
        formatMoney: formatMoney,
        GoMedia: goMedia,
        start() {
            if (this.data_info) {

                this.header_content_value.storefront.under[0].content = this.$filters.momentFormat(this.data_info.show_status_time, 'll');//this.$filters.momentFormat(this.data_info.last_store_front_update_at, 'll');
                this.header_content_value.storefront.under[1].content = this.$filters.momentFormat(this.data_info.show_status_time, 'll');

                this.header_content_value.tiktok.under[0].content = HandleKNum(this.data_info.tiktok_follower_count) ;
                this.header_content_value.tiktok.under[1].content = HandleNum(this.data_info.tiktok_post_count) ;
                this.header_content_value.tiktok.under[2].content = this.data_info.tiktok_engagement ? HandleNum(this.data_info.tiktok_engagement) : '---';

                this.header_content_value.ig.under[0].content = HandleKNum(this.data_info.instagram_follower_count);
                this.header_content_value.ig.under[1].content = HandleNum(this.data_info.instagram_post_count);
                this.header_content_value.ig.under[2].content = this.data_info.instagram_engagement ? HandleNum(this.data_info.instagram_engagement) : '---';

                this.last_update = this.$filters.momentFormat(this.data_info.last_store_front_update_at, 'll');
                // console.log(this.last_update)

            }
        },

        getDoughnutDevice(obj = false) {

            const device = [
                Math.round(Math.random() * (80 - 72)) + 72,
                Math.round(Math.random() * (28 - 14)) + 14,
                Math.round(Math.random() * (5 - 1)) + 1,
            ];

            let sum = device.reduce((ps, a) => ps + a, 0);

            for (let index = 0; index < 20; index++) {
                const inx = Math.round(Math.random() * (2 - 0)) + 0;
                sum = device.reduce((ps, a) => ps + a, 0);
                if (device[inx] !== 0 && sum !== 100) {
                    if (sum < 100) {
                        device[inx]++;
                    } else {
                        device[inx]--;
                    }
                }
            }

            let data = 0;
            let sort_key = 'page_views';

            switch (obj && obj.id) {
                case 2:
                    data = this.data_statistic.sales_value_sum;
                    break;
                case 3:
                    data = this.data_statistic.click_sum;
                    break;
                case 4:
                    data = this.data_statistic.page_views_sum;
                    sort_key = 'sales_value';
                    break;
                default:
                    data = this.data_statistic.sales_sum;
                    break;
            }

            const res = [
                { value: Number((data * (device[0] / 100)).toFixed(0)), name: 'iOS' },
                { value: Number((data * (device[1] / 100)).toFixed(0)), name: 'Android' },
                { value: Number((data * (device[2] / 100)).toFixed(0)), name: 'Other' },
            ]


            this.$nextTick(() => {
                if (this.$refs.device) this.$refs.device.update(
                    {
                        type: sort_key,
                        data: res
                    }
                );
            })
        },

        getDoughnutSource(obj = false) {
            const source = [
                Math.round(Math.random() * (69 - 63)) + 63,
                Math.round(Math.random() * (21 - 17)) + 17,
                Math.round(Math.random() * (9 - 6)) + 6,
                Math.round(Math.random() * (3 - 0)) + 0,
                Math.round(Math.random() * (5 - 1)) + 1,
            ];

            let sum = source.reduce((ps, a) => ps + a, 0);

            for (let index = 0; index < 10; index++) {
                const inx = Math.round(Math.random() * (4 - 0)) + 0;
                sum = source.reduce((ps, a) => ps + a, 0);
                if (source[inx] !== 0 && sum !== 100) {
                    if (sum < 100) {
                        source[inx]++;
                    } else {
                        source[inx]--;
                    }
                }
            }


            let data = 0;
            let sort_key = 'page_views';

            switch (obj.id) {
                case 2:
                    data = this.data_statistic.sales_value_sum;
                    break;
                case 3:
                    data = this.data_statistic.click_sum;
                    break;
                case 4:
                    data = this.data_statistic.page_views_sum;
                    sort_key = 'sales_value';
                    break;
                default:
                    data = this.data_statistic.sales_sum;
                    break;
            }

            const res = [
                { value: Number((data * (source[1] / 100)).toFixed(0)), name: 'Instagram' },
                { value: Number((data * (source[0] / 100)).toFixed(0)), name: 'TikTok' },
                { value: Number((data * (source[2] / 100)).toFixed(0)), name: 'Other' },
                { value: Number((data * (source[3] / 100)).toFixed(0)), name: 'Search' },
                { value: Number((data * (source[4] / 100)).toFixed(0)), name: 'YouTube' },
            ]

            this.$nextTick(() => {
                if(this.$refs.source){
                    this.$refs.source.update({
                        type: sort_key,
                        data: res
                    });
                }
            })
        },

        formatMoney: formatMoney,

        toggleStatus(type) {
            this.is_show_alert_modal = false;
            if (this.data_info.anchor_page_status === 100) {
                this.data_info.anchor_page_status_text = 'Disabled';
                this.data_info.anchor_page_status = 0;
                this.data_info.show_status_time = new Date();
            } else if (this.data_info.anchor_page_status === 0) {
                this.data_info.anchor_page_status_text = 'Active';
                this.data_info.anchor_page_status = 100;
                this.data_info.show_status_time = new Date();
            }
        },

        getUserImage(avatar) {
            return require(`@/assets/images/user/${avatar}`);
        },

        updateDate(obj) {
            console.log(obj);
        },

        editText(type) {
            console.log(type);
        },

        editInfo() {
            this.$refs.edit.open(this.data_info);
        },

        saveEdit(vals) {
            this.data_info.storefront_state = vals.state,
            this.data_info.first_name = vals.first_name,
            this.data_info.last_name = vals.last_name,
            this.data_info.anchor_page_status = vals.anchor_page_status,
            this.data_info.anchor_page_status_text = vals.anchor_page_status_text,
            this.data_info.tiktok_handle = vals.tiktok_handle ? vals.tiktok_handle : '',
            this.data_info.instagram_handle = vals.instagram_handle ? vals.instagram_handle : '',
            this.data_info.is_allow_customize_collection_cover = vals.is_allow_customize_collection_cover;
            this.$notify('<svg><use xlink:href="#yes--sprite"></use></svg> Saved successfully.');
        },

        selectedTab(index) {
            this.selected_tab_index = index;
            if (index === 0) {
                setTimeout(() => {
                    this.current_page = 1;
                    this.getProductList();

                }, 10);
            } else if (index === 1) {
                this.getProductList();
            } else {
                console.log('here')
            }
        },

        clickSwitch() {
            if (this.data_info.anchor_page_status_text === 'Active')
                this.is_show_alert_modal = true;
            else
                this.toggleStatus(2);
        },

        getDoughnut() {
            setTimeout(() => {
                if (this.$refs.dropdown_source)
                    this.$refs.dropdown_source.selected_index = 0;
                if (this.$refs.dropdown_device)
                    this.$refs.dropdown_device.selected_index = 0;
                this.getDoughnutSource();
                this.getDoughnutDevice();
            }, 10);
        },

        getProductList(obj) {
            // console.log(this.data)
            this.is_loading_products = true;
            this.is_loading_device = true;
            this.is_loading_source = true;
            if(obj){
                this.start_date = obj.date.start;
                this.end_date = obj.date.end;
            }

            let api = `/api/get-user-products?user_id=${this.data.id}&instagram=${this.data.instagram}`;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_products = res.data.list;
                this.is_loading_products = false;
            });

            let api2 = `/api/get-all-data?type=view&user_id=${this.data.id}`;

            this.$ajax(api2, {
                method: 'get',
                withCredentials: true,
            }).then(res => {

                this.data_statistic = {
                    page_views_sum: res.data.total_views,
                    click_sum: res.data.total_clicks,
                    click_trend: 'down',
                    sales_sum: res.data.total_transactions,
                    sales_value_sum: res.data.total_sales,
                    short_link_count: 20,
                    short_link_click_sum: 30
                }

                this.is_loading_device = false;
                this.is_loading_source = false;

                setTimeout(() => {
                    this.getDoughnut();

                }, 10);
            });

        },

        nextProductListPage(type) {
            if (!this.is_loading_products && this.current_page < this.last_page) {
                this.current_page++;
                this.getProductList();
            }
        },

        open() {
            this.selected_tab_index= 0;
            this.getProductList();
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        updateModalDate(obj){
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            //this.storefrontSummary_refrash+=1;
            if (this.selected_tab_index !== 2) {
                this.getDoughnut();
                this.getProductList();
            }
        },

        share() {
            const obj = {};
            obj.url = 'test';
            obj.storefront_url = 'https://journeys.qa.motom.me/oyoyyolla';
            this.EventBus.emit('open-share', obj);
            
        },

        viewStorefront() {
            window.open('https://journeys.qa.motom.me/oyoyyolla', '_blank');
        },

        emailTo(email) {
            window.location.href = `mailto:${email}`;
        },

        tempBtnForFb() {
            window.location.reload();
            this.temp_loading = false;
        }
    },

    mounted() {
        this.EventBus.off('open-creator-modal');

        this.EventBus.on('open-creator-modal', (obj) => {
            this.user_products = [];
            this.data = obj.row;
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            const objs = obj.row;
            console.log('obj',objs)

            this.data_info = {
                email: objs.email,
                share_link: objs.share_link,
                first_name: objs.first_name,
                last_name: objs.last_name,
                anchor_page_status: objs.anchor_page_status,
                anchor_page_status_text: objs.anchor_page_status_text,
                show_status_time: new Date(),
                userProfile: {
                    avatar_full_url: objs.avatar
                },
                instagram: objs.instagram,
                instagram_handle: objs.instagram_handle,
                instagram_follower_count:objs.instagram_follower_count,
                instagram_post_count:objs.instagram_post_count,
                instagram_engagement:objs.instagram_engagements,
                instagram_engagement_rate:objs.instagram_engagement_rate,
                instagram_impressions:objs.instagram_impressions,
                
                last_update: objs.last_update,
                status: objs.status,
                status_text: objs.status_text,
                tiktok: objs.tiktok,
                tiktok_handle: objs.tiktok_handle,
                tiktok_follower_count:objs.tiktok_follower_count,
                tiktok_post_count:objs.tiktok_post_count,
                tiktok_engagement:objs.tiktok_engagements,
                tiktok_engagement_rate:objs.tiktok_engagement_rate,
                tiktok_impressions:objs.tiktok_impressions,
                motom_user_id: objs.motom_user_id,
                id:objs.id
            }
            this.start();
            this.open();
        });
    },
}
</script>
<style lang="scss">
.creator-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    .text-ellipsis {
        white-space: nowrap;
        width: 100%;
        max-width: 200px;
        min-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }

    .text-ellipsis-link {
        cursor: pointer;

        a {
            color: var(--black-90) !important;
        }
    }

    .text-ellipsis-link:hover {
        text-decoration: underline;
    }

    .ui-modal-side--container {

        header {
            .icon-box .icon {
                padding: 12px 8px;
                margin-left: 8px;

                &.close {
                    padding: 12px;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .ui-navi {
            margin-top: 16px;
        }

        .title {
            &.editable {
                cursor: pointer;

                &:hover {
                    background: var(--black-10);
                }
            }

            .edit-input {
                display: flex;

                input {
                    padding: 5px 8px;
                    border: 1px solid var(--black-20);
                    background-color: white;
                    width: 100%;
                    border-radius: 6px;
                    color: var(--black-90);
                    transition: .15s border-color ease;
                    font-weight: 500;
                    font-family: var(--font);

                    &:hover {
                        border-color: var(--black-50);
                    }

                    &:focus {
                        outline: none;
                        border-color: var(--dark-blue);
                    }

                    &::placeholder {
                        color: var(--black-50);
                        font-weight: 500;
                    }
                }

                .button {
                    padding: 0 8px;
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: var(--black-60);
                    }

                    &:hover>svg {
                        fill: var(--black-80);
                    }
                }
            }
        }

        .creator-info-box {
            margin-top: 16px;
            padding: 16px 0;
            display: flex;
            //background: var(--bg-02);

            .avatar {
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                    border: 1px solid var(--black-40);
                }
            }

            .creator-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;

                .creator-data-box {
                    display: flex;
                    position: relative;
                    margin: 0 -8px;

                    .creator-data {
                        width: calc(33.33% - 16px);
                        margin: 0 8px;
                    }

                    &.creator-data-box-only-one {
                        .creator-data {
                            width: calc(50% - 16px);
                            margin: 0 8px;
                        }
                    }

                    &.creator-data-box-no-one {
                        .creator-data {
                            width: 100%;
                            margin: 0;
                        }
                    }

                }
            }
        }

        .hint {
            margin-top: 16px;
            padding: 8px;
            font: var(--font-med-12);
            background: var(--bg-11);
            color: var(--black-75);
            display: flex;
            align-items: center;

            .disabled-alert {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }

        .storefront-summary {
            margin-top: 20px;
        }
    }

    .doughnuts {
        display: flex;
        margin-bottom: 24px;

        .doughnut-box {
            padding: 12px;
            border: 1px solid var(--border);
            border-radius: 10px;
            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            width: calc(50% - 8px);

            &:nth-child(1) {
                margin-right: 8px;
            }

            &:nth-child(2) {
                margin-left: 8px;
            }

            .title {
                display: flex;
                align-items: center;
                font: var(--font-demi-16);
                color: var(--black-90);

                span {
                    flex: 1;
                }

                .dropdown {
                    width: fit-content;
                }
            }

        }
    }

    .short-link {
        margin-top: 32px;

        .title-box {
            display: flex;

            .title {
                font: var(--font-fg-16);
                color: var(--black-90);
                flex: 1;
            }
        }

        .report-box {
            margin-top: 24px;
            margin-bottom: 24px;
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.08);

            .report-item {
                padding: 24px 16px;
                width: 25%;
                background: white;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-right: 1px solid rgba(0, 0, 0, 0.08);
                }

                .value {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: var(--dark-blue);
                    display: flex;
                    align-items: center;


                    svg {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }

                .text {
                    margin-top: 4px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    color: var(--black-60);
                }
            }
        }
    }

    .ui-product-list {
        margin-top: 20px;
    }


    .text-ellipsis-link {
        display: block !important;
    }

    .temp-css-in-title {
        display: flex;
        align-items: center;

        .ui-button {
            margin-left: 8px;
        }
    }
}
</style>