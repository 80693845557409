<template>
    <div class="top-brand-post-box">
        <div class="title" v-if="had_title"><span>{{ title }}</span></div>

        <div :class="['tab-box', !had_navi && 'tab-box-one']" v-if="had_title">
            <Navi v-if="had_navi" :type="1" :menu="posts_value.top_menu" :type_one_index="posts_value.top_menu_index"
                :key="posts_value.top_menu_index" @selected-tab="selectTop" />

            <TagDropdown class="tag-drop"
                v-if="had_tag && (tags.length > 0 || (tags.length === 0 && $store.state.user_data.contract_status))"
                :items="tags" :selected_items="selected_tags" :key="tags.length" :direction_type="'right'"
                :disabled="!selected_tags.length && posts.length === 0" :default_text="tag_text"
                @update-select="updateTag" :icon_direction_type="'right'" @editTag="editTag">

                <div v-if="tags.length !== 0 && $store.state.user_data.contract_status">
                    <div class="tag-button">
                        <Button :style="'border'" size="24" :color="'black-90'" @click.stop="editTag"> Edit Tags
                            <svg-icon name="edit" />
                        </Button>
                    </div>
                </div>
            </TagDropdown>
            <Dropdown :items="posts_value.sort_select" :direction_type="'right'" class="edit-button" :icon="'filter'"
                :disabled="!posts || posts.length === 0" :default_value="posts_value.sort_index"
                @update-dropdown="updateSort" :key="posts_value.drop_key" />

            <slot name="button"></slot>
        </div>
        <div class="sticker-tab-box-inside" v-if="now_tab === 0">
            <Button type="button" color="dark-blue" size="32" v-for="(v, k) in posts_value.ig_menu" :key="k"
                :style="'no-border-square'" @click="clickIgTab(v.index)"
                :class="{ 'actived': posts_value.ig_post_type === v.index }">
                {{ v.text }}({{ v.value }})
            </Button>
        </div>

        <slot name="tabs"></slot>

        <Loading v-if="posts_value.loading" class="static" />

        <div class="post-list-box" v-else-if="posts.length > 0 && !posts_value.loading">
            <div class="list-item" v-for="(  v, k ) in  posts " :key="k">
                <CreatorSmallCard :data="v" @click.stop.native="$emit('clickPost', v)" :tag_select="tags"
                    @edit-tag="editTag" :no_plus="no_plus" :now_ig_type="posts_value.ig_post_type" :creator_show="creator_show"/>
            </div>
        </div>

        <NoData v-if="posts.length === 0 && !posts_value.loading"
            :image="!had_m_ht ? require('@/assets/images/pc.png') : require('@/assets/images/data.png')"
            @title-function="goSetting" @text-function="goSetting"
            :title="!had_m_ht && !is_soc ? `Content will become available when you add Instagram and TikTok #hashtags and @mentions to track in your <span class='link' >settings</span>.` : `No available posts yet`">
            <Button size="32" color="dark-blue" @click="goSetting" v-if="!had_m_ht && !is_soc">
                <svg-icon name="plus" /> Update Settings
            </Button>
        </NoData>


        <Modal :is_show="is_show_modal" @click-bg="is_show_modal = false" class="hashtag-modal">
            <header class="tag-header">Update Tags <i @click="is_show_modal = false"><svg-icon name="close" /></i>
            </header>
            <Hashtag :only_tag="true" @update="updateEditTags" />
        </Modal>
    </div>
</template>

<script>
import Dropdown from '@/components/ui/Dropdown.vue';
import TagDropdown from '@/components/ui/TagDropdown.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import { Navigation } from 'swiper/modules';
import NoData from '@/components/creator/NoData.vue';
import Navi from '@/components/Navi.vue';
// import moment from 'moment';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import Hashtag from '@/components/Hashtag.vue';
import Loading from '@/components/ui/Loading.vue';
// import { handleSelectText, compareArrays } from '@/utils/ContentSwitchFun.js';

export default {
    name: 'top-brand-post',

    data() {
        return {
            is_show_modal: false,
            tags: [],
            had_m_ht: true,
        };
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    components: {
        Swiper,
        SwiperSlide,
        CreatorSmallCard,
        NoData,
        Dropdown,
        Navi,
        TagDropdown,
        Button,
        Modal,
        Hashtag,
        Loading,
    },

    props: {
        posts: {
            type: Array,
            default: []
        },

        posts_value: {
            type: Object,
            default: {}
        },

        title: {
            type: String,
            default: 'Top 20 Branded Posts'
        },

        had_title: {
            type: Boolean,
            default: true,
        },

        had_navi: {
            type: Boolean,
            default: true,
        },

        had_tag: {
            type: Boolean,
            default: true,
        },

        tag_text: {
            type: String,
            default: 'Tag:All'
        },

        selected_tags: {
            type: Array,
            default: []
        },

        no_plus: {
            type: Boolean,
            default: false,
        },
        is_soc: {
            type: Boolean,
            default: false,
        },
        now_tab: {
            type: Number,
            default: 0,//0=>ig,1=>tk
        },
        creator_show:{
            type: Boolean,
            default: false,
        }

    },

    // watch: {
    // },
    methods: {
        updateSort(obj) {
            this.$emit('updateSort', obj);
        },

        selectTop(id) {
            this.$emit('selectTop', id);
        },

        updateTag(arr) {
            //this.tag_text = handleSelectText(arr);
            this.$emit('updateTag', arr);
        },


        updateEditTags(o) {
            this.$emit('callApiAgain');
        },

        editTag(obj) {
            // console.log('editTag', obj)
            this.is_show_modal = true;
        },

        getTags() {
            //client api not yet
            if (this.$store.state.user_data) {
                this.$ajax(`creator/get-social-post-filter`, {
                    method: 'get',
                }).then(res => {
                    const data = res.data.data;
                    if (!data) return;

                    this.tags = data.social_post_tag_texts;
                    this.had_m_ht = (data.social_post_hashtag_texts.length === 0 && data.social_post_mention_texts.length === 0) ? false : true;

                }).catch(err => {
                    console.log(err)
                })
            }
        },

        clickIgTab(index) {
            this.$emit('changeIgTag', index);
        },

        goSetting() {
            //this.$store.state.user_data.contract_status && 
            if (!this.had_m_ht) {
                window.location.href = '/settings/content-listening';
            }
        }

    },
    mounted() {
        this.EventBus.on('updated-all-post-tag', (tags) => {
            this.tags = tags;
        });

        this.getTags();
    }
};
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.top-brand-post-box {
    border-radius: 8px;

    .sticker-tab-box-inside {
        display: flex;
        align-items: center;
        margin: 8px;

        .ui-button {
            margin-right: 16px;
            display: inline-flex;
            background-color: unset;
            text-wrap: nowrap;
            border: none;

            &:hover {
                background-color: var(--bg-02);
            }

            &.actived,
            &.actived:hover {
                border: 1px solid var(--dark-blue) !important;
                color: var(--dark-blue);
                background-color: white;
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        font: var(--font-demi-16);
        color: var(--black-90);

        span {
            flex: 1;
        }
    }

    .tag-drop {
        margin-right: 8px;

        .edit-button {
            margin: 6px 8px;
        }

    }

    .tab-box {
        @include flexCenter();
        margin: 12px 0;

        &.tab-box-one {
            justify-content: flex-end;
            margin-top: -28px;
        }

        .ui-navi {
            flex: 1;
            margin-top: 0 !important;
        }
    }

    .post-list-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        overflow-x: hidden;
        background-color: var(--light-neutral);
        padding: 8px;
        border-radius: 8px;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .list-item {
            overflow: hidden;
        }
    }

    .creator-no-data {
        .ui-button {
            margin: 16px auto;
        }
    }
}
</style>
