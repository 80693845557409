<template>
    <div class="share-popup-menu" ref="share">
        <div class="share-popup-menu--wrapper">
            <div class="share-popup-menu--container">
                <header class="share-popup-menu--header">
                    <h2 class="share-popup-menu--title" >Share</h2>
                    <div class="share-popup-menu--skip" @click="close(true)"><SvgIcon name="close" /></div>
                </header>

                <div class="share-popup-menu--body" @click="copy('link')">
                    <span class="link"> {{ share_url }}</span>
                    <span class="btn">Copy</span>
                </div>

                <footer :class="['share-popup-menu--footer', {'no-caption': description === ''}]">
                    <div class="icons">
                        <ul>
                            <li v-if="is_can_share"><span @click="shareTo('share')" class="icon icon-link"><SvgIcon name="shareto" /></span><span class="text">Share</span></li>
                            <li><span @click="copy('link')" class="icon icon-link"><SvgIcon name="copy" /></span><span class="text">Copy URL</span></li>
                            <li><span @click="shareTo('email')" class="icon icon-email"><SvgIcon name="email" /></span><span class="text">Email</span></li>
                            <!-- <li><span @click="shareTo('twitter')" class="icon icon-twitter"><svg><use xlink:href="#twitter-no-circle--sprite"></use></svg></span><span class="text">Twitter</span></li> -->
                            <!-- <li><span @click="shareTo('facebook')" class="icon icon-facebook"><svg><use xlink:href="#facebook--sprite"></use></svg></span><span class="text">Facebook</span></li> -->
                            <!-- <li><span @click="shareTo('pinterest')" class="icon icon-pinterest"><svg><use xlink:href="#pinterest-no-circle--sprite"></use></svg></span><span class="text">Pinterest</span></li> -->
                        </ul>
                    </div>
                </footer>
            </div>
            <div class="share-popup-menu--bg" @click="close(false)"></div>
        </div>
    </div>
        
</template>

<script>
import copyToClipboard from 'copy-to-clipboard';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import device from '@/utils/CheckDevice';

export default {
    name: 'Share',
    components:{
        SvgIcon 
    },

    // props: {
    //     title: {
    //         type: String,
    //         default: '',
    //     },

    //     image:  {
    //         type: String,
    //         default: '',
    //     },

    //     description: {
    //         type: String,
    //         default: '',
    //     },

    //     share_url: {
    //         type: String,
    //         default: '',
    //     },
    // },

    data() {
        return {
            title: 'Share title',
            image: '',
            description: 'Share description',
            share_url: '',
            is_can_share: false,
            hostname: 'https://motom.me',
            storefront_url: '',
            is_invite: false,
        }
    },

    methods: {
        open() {
            if (this.storefront_url !== '')
                this.share_url = this.storefront_url;
            else if (window.location.host === 'www.anchor.motom.me' || window.location.host === 'anchor.motom.me')
                this.share_url = `https://www.motom.me/rs/51D216R`;
            else
                this.share_url = `https://www.qa.motom.me/rs/51D216R`;

            // if ((device('tablet') || device('mobile')) && this.is_can_share) {
            //     this.shareTo('share')
            // } else {
                this.$refs.share.classList.add('open');

                setTimeout(() => {
                    this.$refs.share.classList.add('open-bg');
                }, 10);

                setTimeout(() => {
                    this.$refs.share.classList.add('open-container');
                }, 150);
            //}
        },

        close(is_click_button_close) {            
            if (this.$refs.share) this.$refs.share.classList.remove('open-container');
            
            setTimeout(() => {
                if (this.$refs.share) this.$refs.share.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (this.$refs.share) this.$refs.share.classList.remove('open');
                this.$emit('closeCallback');

                if (is_click_button_close) {
                    this.$emit('callback');
                }
            }, 300);
        },

        shareTo(type) {
            let link = this.share_url;
            let share_url = link;

            switch (type) {
                case 'share':
                    navigator.share({
                        title: this.title,
                        text: this.description,
                        url: link
                    })
                    break;

                case 'email':
                    if (this.is_invite) {
                        share_url = 'mailto:?subject=' + this.title + '&body='+ this.description;

                    } else {
                        share_url = 'mailto:?subject=' + this.title + '&body='+ this.description +' %0A%0A Check out this link at: ' + link;

                    }
                    break;
                case 'twitter':
                    share_url = 'https://twitter.com/intent/tweet?url=&text=' + this.description + '&original_referer=' + link;
                    break;
                case 'facebook':
                    share_url = 'https://www.facebook.com/sharer/sharer.php?u=' + link;
                    break;
                case 'pinterest':
                    share_url = 'https://pinterest.com/pin/create/link/?url=' + link;
                    break;
            }

            if (type !== 'share') window.open(share_url, '_blank');

        },

        copy(type) {
            let text = this.$refs.text ? this.$refs.text.innerHTML : '';
            let link = this.share_url;

            
            switch (type) {
                case 'text':
                    copyToClipboard(text, {format:'text/plain'});
                    break;
                case 'link':
                    copyToClipboard(link, {format:'text/plain'});
                    break;
            }

            this.close();
            this.$notify(`<svg><use xlink:href="#yes--sprite"></use></svg> Link copied`);

        },
    },

    mounted() {
        if (navigator.share) this.is_can_share = true;
        this.hostname = window.location.host;
        this.EventBus.off('open-share');
        this.EventBus.on('open-share', (obj) => {
            
            if (obj.storefront_url) this.storefront_url = obj.storefront_url;
            this.open();

            // console.log(this.share_url)

            if (obj.type && obj.type === 'invite') {
                const brand_name = this.$store.state.user_data.merchant_account.merchant_display_name;
                this.is_invite = true;
                this.title = `Invitation to launch ${brand_name} storefront`;
                this.description = `
Hi there,
%0A
We're so excited to begin our collaboration! You will manage your ${brand_name} storefront via Motom's platform. If you do not already have a Motom account, please create one account using this link: ${this.share_url}
%0A%0A
Once you've created or logged into your Motom account and connected your public social profiles, please complete the following steps:
%0A
1. Upload a profile picture in your Motom settings https://www.motom.me/me/settings/info.
%0A
2. Accept our storefront request on Motom. This will appear as a pop-up after you open your account and can be found in your Activity Center for 7 days. If you accidentally decline the request, please let me know ASAP and I will resend it.
%0A
3. Once you've accepted the storefront request, you can begin adding products directly to your Storefront (found in the Storefront tab of your Motom profile https://www.motom.me/me/storefront). Simply press the "%2B" button by your ${brand_name} storefront to add each item using a link.
%0A%0A
You'll be connected with a Motom creator success manager once you register.  They can help answer any questions you have about Motom or your ${brand_name} storefront. If you need assistance before then, please reach out to creators@motom.me.
%0A%0A%0A
Best,%0A
${this.$store.state.user_data.first_name}%0A%0A
`;
            } else {
                this.is_invite = false;
                this.title = 'Share title';
                this.description = '';
            }
            
        });
    },
}
</script>

<style lang="scss">
    .share-popup-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 50%;
            bottom: 0;
            width: 100%;
            transition: transform .3s ease;
            transform: translate3d(-50%, -50%, 0);
            background: white;
            border-radius:  16px;
            max-width: 420px;
            box-shadow: var(--bot-1dp);
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(#000, .8);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--header {
            padding: 0 8px 0 16px;
            display: flex;
            height: 48px;
            box-sizing: content-box;
            align-items: center;
            border-bottom: 1px solid var(--black-20);
        }

        &--title {
            font: var(--font-demi-20);
            color: var(--black-90);
            flex: 1;
        }

        &--body {
            color: var(--black-90);
            background: white;
            overflow: hidden;
            padding: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
        

            .link {
                display: block;
                font-weight: 500;
                color: var(--black-75);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                border: 1px solid var(--black-20);
                padding: 0 12px;
                height: 36px;
                line-height: 34px;
                flex: 1;
                border-radius: 8px;
                box-sizing: content-box;
                color: var(--black-90);
            }

            .btn {
                font: var(--font-fg-16);
                padding: 0 16px;
                height: 36px;
                line-height: 34px;
                border: 1px solid var(--black-40);
                border-radius: 20px;
                box-sizing: content-box;
                color: var(--black-50);
                margin-left: 16px;
            }
        }

        &--footer {
            padding: 16px 16px;

            h3 {
                font-size: 18px;
                color: var(--black-90);
                margin: 0 0 12px;
            }

            .icons {
                overflow: auto;
                width: 100%;
            }

            ul {
                display: flex;
            }

            li {
                width: 100%;
            }

            .icon {
                height: 48px;
                width: 48px;
                position: relative;
                background: var(--dark-blue);
                border-radius: 50%;
                display: block;
                margin: 0 auto 5px auto;
                cursor: pointer;

                svg {
                    height: 22px;
                    width: 22px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: white;
                }

                &-link {
                    background: var(--dark-blue);

                    svg {
                        fill: white;
                    }
                }

                &-email {
                    background: vvar(--dark-blue);
                }

                &-facebook {
                    background-color: var(--soc-fb);

                    svg {
                        height: 24px;
                        width: 24px;
                        fill: var(--soc-fb);
                        color: white;
                    }
                }

                &-twitter {
                    background-color: var(--soc-twit);
                }

                &-pinterest {
                    background-color: var(--soc-pinterest);
                }
            }

            .text {
                text-align: center;
                display: block;
                font-size: 12px;
                font-weight: 500;
                margin: 6px 0 0;
                color: var(--black-75);
                cursor: pointer;
            }

            .btn-wrap {
                padding: 24px 16px 0;
                
                .ui-button {
                    display: block;
                    height: 38px;
                    border-width: 1px;
                    text-align: center;
                    font-weight: var(--semibold);
                }
            }

            // @at-root {
            //     @media (max-width: 395px) {
            //         & {
            //             padding-left: 4px;
            //             padding-right: 4px;

            //             ul {
            //                 width: 100%;
            //                 justify-content: space-between;
            //             }

            //             /*li {
            //                 width: 56px;
            //             }*/

            //             .icon {
            //                 height: 40px;
            //                 width: 40px;

            //                 svg {
            //                     height: 20px;
            //                     width: 20px;
            //                 }
            //             }

            //             .text {
            //                 font-size: 10px;
            //                 width: 100%;
            //                 overflow: hidden;
            //                 text-overflow: ellipsis;
            //                 white-space: nowrap;
            //             }


            //         }
            //     }
            // }
        }

        &--skip {        
            color: white;

            svg {
                cursor: pointer;
                height: 12px;
                width: 12px;
                display: block;
                position: relative;
                right: 10px;
                fill: var(--black-50);
            }

            &:hover svg {
                fill: var(--dark-blue);
            }
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(-50%, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: 520px) {
        .share-popup-menu {

            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }


            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }
</style>
