import { createStore } from 'vuex';

export default createStore({
    state: {
        user_data: {
            is_motom_internal_user: true,
            name: '',
            id: '',
            first_name:'',
            last_name:'',
            avatar: '',
            unique_key: '6PxDqP',
            merchant_account_id: 40,
            merchant_domain_name: 'chewy',
            product_merchant_id: 5170,
            email: '',
            created_at: '2023-05-23T01:40:03.000000Z',
            creator_url:'/creator/list',
            contract_status:true,
            companies: [
                {
                    "id": 5,
                    "name": "Motom",
                    "clean_name": "motom",
                    "merchant_account_id": null,
                    "created_by_cms_user_id": 0,
                    "created_at": "2023-06-05T04:34:23.000000Z",
                    "updated_at": "2023-06-05T04:34:23.000000Z",
                    "pivot": {
                        "portal_user_id": 12,
                        "company_id": 5
                    }
                }
            ],
            department_id: 6,
            departments: [
                {
                    "id": 6,
                    "name": "finance"
                },
                {
                    "id": 5,
                    "name": "client services"
                },
                {
                    "id": 4,
                    "name": "tech"
                },
                {
                    "id": 3,
                    "name": "product"
                },
                {
                    "id": 2,
                    "name": "marketing"
                },
                {
                    "id": 1,
                    "name": "owner"
                }
            ],
            roles: [
                {
                    id: 1,
                    name: 'Admin',
                    description: 'Admin',
                },
                {
                    id: 2,
                    name: 'Manager',
                    description: 'Manager',
                },
                {
                    id: 3,
                    name: 'Analyst',
                    description: 'Analyst',
                },
            ],
            merchant_accounts: [
              {
                merchant_display_name: 'JB Home',
                brand_logo: require('@/assets/images/logo.jpg'),
              },
            //   {
            //     merchant_display_name: 'ND Home',
            //     brand_logo: require('@/assets/images/logo.jpg'),
            //   }
            ],
            merchant_account: {
              merchant_display_name: 'JB Home',
              brand_logo: require('@/assets/images/logo.jpg'),
              store_front_logo: require('@/assets/images/logo.jpg'),
              favicon_url: require('@/assets/images/logo.jpg'),
              id: 1,
              status: 100,
              is_pulling_social_data: 1,
              redirect_url: 'www.joannabuchanan.com/',
              cname_record: '676ab34fc2ac459.motom.me',
              creator_max_count: 10,
                portal_user_max_count: 5,
                is_enable_merchant_host: 1,
                is_force_redirect_to_merchant_host: 0,
                is_allow_customize_collection_cover: 1,
                is_show_store_front_hero_image: 0,
                og_title: null,
                og_description: null,
                contract_status:true,
                contract_start_time: '2023-06-01 00:00:00',
                contract_end_time: '1970-01-01 00:00:00',
                note: null,
                created_by_cms_user_id: 30,
                last_updated_by_cms_user_id: 15,
                created_at: '2023-04-11T08:52:10.000000Z',
                updated_at: '2024-05-20T05:23:01.000000Z',
                anchor_page_subdomain_status_text: 'Configuring',
                anchor_page_subdomain: 'https:\/\/www.joannabuchanan.com/',
                anchor_page_subdomain_status: 10,
                anchor_page_subdomain_updated_at: '2024-05-20 05:23:01',
            },
            
        },
        disabled_scroll: false,
        upload_data: false,
        is_show_share: false,
        import_count: 0,
        department_select: [{"id":6,"name":"finance", text: 'Finance'},{"id":4,"name":"tech", text: 'Tech'},{"id":3,"name":"product", text: 'Product'},{"id":2,"name":"marketing", text: 'Marketing'},{"id":1,"name":"owner", text: 'Owner'}],
        role_select: [],    
        // role_id === 1 => admin 
        // role_id === 2 => manager 
        // role_id === 3 => analyst
        role_id: 1,
        current_creator_count: 3,
        creator_max_count: 10,
        current_portal_user_count: 1,
        portal_user_max_count: 5,
        is_loading: false,
        mail_to:'anchor.support@motom.me',
        pick_creator_user_id:'',
        brand_posts_key:0,
        now_pick_creator:{},
    },
    // getters: {
    // },
    mutations: {
        // check_limit(state, self) {
        //     state.is_loading = true;
        //     self.$ajax.get(`/settings/merchant-account-current-use-rate?t=${new Date().getTime()}`).then((res) => {
        //         const res_data = res.data.data; 
        //         state.creator_max_count = res_data.creator_max_count;
        //         state.current_creator_count = res_data.current_creator_count;
        //         state.portal_user_max_count = res_data.portal_user_max_count;
        //         state.current_portal_user_count = res_data.current_portal_user_count;
        //         state.is_loading = false;
        //     });
        // },
        setUser(state, value) {
            state.user_data.name = value.name;
            state.user_data.first_name = value.name.split(' ')[0];
            state.user_data.last_name = value.name.split(' ')[1];
            state.user_data.avatar = value.avatar;
            state.user_data.id = Number(value.user_id);
            state.user_data.email = value.email;
            // state.user_data.merchant_account = {unique_key:'51D216R'}
        },
    },
    // actions: {
    // },
    // modules: {
    // }
})
