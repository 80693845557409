<template>
    <aside class="storefront-summary">
        <div class="head" v-if="last_update">
            <div class="top">
                <time>Updated: {{ last_update }}</time>
                <DatePicker v-if="!is_hide_date" v-show="!is_loading_data && show_date_picker" @update-date="updateDate"
                    :date_range="date_range" ref="date_picker" />
            </div>
            <div class="bot">
                <span>{{ title.title }}</span>
            </div>
        </div>
        <div class="title" v-else>
            <span>{{ title.title }}</span>
            <DatePicker v-if="!is_hide_date" v-show="show_date_picker" @update-date="updateDate"
                :date_range="date_range" ref="date_picker" />
        </div>
        <div class="chart-box" :class="{ 'is-loading': is_loading_data }">
            <div class="type-box">
                <div class="type" :class="{ 'selected': chart_type === 'view' }" @click="clickType('view')">
                    <div class="value">{{ total_views.toLocaleString('en-US') }}
                        <SvgIcon name="arrow-up" />
                    </div>
                    <div class="text">{{ title.view }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'click' }" @click="clickType('click')">
                    <div class="value">{{ total_clicks.toLocaleString('en-US') }}
                        <SvgIcon name="arrow-up" class="down" />
                    </div>
                    <div class="text">{{ title.click }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'tran' }" @click="clickType('tran')">
                    <div class="value">{{ total_transactions.toLocaleString('en-US') }}
                        <SvgIcon name="arrow-up" />
                    </div>
                    <div class="text">{{ title.sale }}</div>
                </div>
                <div class="type" :class="{ 'selected': chart_type === 'sales' }" @click="clickType('sales')">
                    <div class="value">${{ formatMoney(total_sales) }}
                        <SvgIcon name="arrow-up" />
                    </div>
                    <div class="text">{{ title.sale_value }}</div>
                </div>
            </div>

            <Loading v-if="is_loading_data" class="static" />

            <div :class="['chart-box', is_loading_data && 'hide-chart']">
                <div :class="['chart']" ref="chart" v-show="(chart_type === 'view' && data.total_views !== 0) ||
                (chart_type === 'click' && total_clicks !== 0) ||
                (chart_type === 'tran' && total_transactions !== 0) ||
                (chart_type === 'sales' && total_sales !== 0)
                "></div>
            </div>


            <NoData v-if="!is_loading_data
            && ((chart_type === 'view' && total_views === 0) ||
                (chart_type === 'click' && total_clicks === 0) ||
                (chart_type === 'tran' && total_transactions === 0) ||
                (chart_type === 'sales' && total_sales === 0))
            " :image="require('@/assets/images/data.png')" title="No data available" />

        </div>
    </aside>

    <EngagementPosts ref="posts" :is_show_crt="is_show_crt" />
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import NoData from '@/components/creator/NoData.vue';
import Loading from '../ui/Loading.vue';
import * as echarts from 'echarts';
import EngagementPosts from '@/components/EngagementPosts.vue';

export default {
    name: 'StorefrontSummary',

    components: {
        SvgIcon,
        DatePicker,
        echarts,
        NoData,
        Loading,
        EngagementPosts,
    },

    data() {
        return {
            option: null,
            chart: null,
            is_loading_data: false,
            data: false,
            data_clicks: [],
            data_sales: [],
            data_transactions: [],
            data_views: [],
            chart_type: 'view',
            total_views: 0,
            total_clicks: 0,
            total_sales: 0,
            total_transactions: 0,
            is_change: false,
            start_date: null,
            end_date: null,
        }
    },

    props: {
        is_hide_date: {
            type: Boolean,
            default: false,
        },

        is_show_posts: {
            type: Boolean,
            default: true,
        },

        last_update: {
            type: String,
            default: '',
        },

        user_id: {
            type: String,
            default: '',
        },

        show_date_picker: {
            type: Boolean,
            default: false,
        },

        date_range: {
            type: Object,
            default: null,
        },

        title: {
            type: Object,
            default: {
                title: 'Storefront Summary',
                view: 'Page Views',
                click: 'Clicks',
                sale: 'Sales',
                sale_value: 'Sales Value'
            },
        },
        product_id: {
            type: String,
            default: ''
        },
        is_show_crt: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        formatMoney: formatMoney,

        setOption() {
            this.option = {};
        },


        getData(type = 'view') {
            let vi = this;
            let api = `/api/get-all-data?type=${type}`;
            if (this.user_id) api = `${api}&user_id=${this.user_id}`;
            if (this.product_id) api = `${api}&product_id=${this.product_id}`;

            this.is_loading = true;
            this.is_loading_data = true;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data = res.data.list;
                let data = [];
                let date = [];
                this.total_views = res.data.total_views;
                this.total_clicks = res.data.total_clicks;
                this.total_sales = res.data.total_sales;
                this.total_transactions = res.data.total_transactions;

                this.data_clicks = res.data.clicks;
                this.data_sales = res.data.sales;
                this.data_transactions = res.data.transactions;
                this.data_views = res.data.views;

                res.data.list.forEach(item => {
                    data.push(item.value);
                    date.push(item.date);
                });
                this.option.xAxis[0].data = date;
                this.option.series[0].data = data;
                this.is_loading_data = false;

                const chart = echarts.init(this.$refs.chart);
                chart.setOption(this.option);
                this.is_loading = false;

                this.$emit('update-data', res.data);
            });
        },

        initOption() {
            let vi = this;
            this.option = {
                color: ['#000000'],
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); padding: 5px 10px; font-size: 12px; line-height: 20px; border-radius: 4px;',
                    axisPointer: {
                        type: 'line',
                        snap: true,
                        lineStyle: {
                            width: 1,
                            color: '#232A60',
                            type: 'solid',
                        },
                        label: {
                            componentType: 'series',
                            show: true,
                            backgroundColor: '#78CBFA'
                        },
                    },
                    formatter: function (params) {
                        let content = moment(params[0].axisValue).format('MMM DD, YYYY') + '<br>';
                        let title = '●';
                        if (vi.chart_type == 'sales')
                            title = '$';
                        params.forEach(function (item) {
                            content += `${title} ${item.value} <br>`;
                        });
                        return content;
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLabel: {
                            formatter: (params) => {
                                return moment(params).format('MMM DD')
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        alignTicks: false,
                    }
                ],
                series: [
                    {
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 20,
                        itemStyle: {
                            color: '#232A60',
                            borderWidth: 6,
                            borderColor: 'rgba(176, 216, 246)',
                        },
                        triggerLineEvent: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            // opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#5789EB'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(120, 203, 250, 0.7)'//'#78CBFA'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            };
        },

        resizeChart() {
            const chart = echarts.init(this.$refs.chart);
            chart.setOption(this.option);
            chart.resize();
        },

        clickType(type) {
            this.chart_type = type;
            this.option.xAxis[0].data = [];
            this.option.series[0].data = [];
            const chart = echarts.init(this.$refs.chart);
            chart.setOption(this.option);
            this.getData(type);

        },

        updateDate(obj) {
            this.start_date = obj.start_date;
            this.end_date = obj.end_date;
            this.chart_type = 'view';
            this.getData();
            this.$emit('update-creator-modal-date', obj);
        },
    },

    mounted() {
        this.initOption();
        this.getData();

        if (this.date_range) {
            this.start_date = this.date_range.start_date;
            this.end_date = this.date_range.end_date;
        }

        this.EventBus.off('update-date-at-analytics');
        this.EventBus.on('update-date-at-analytics', (obj) => {
            this.updateDate(obj);
        });
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss">
.storefront-summary {
    margin-bottom: 24px;

    .hide-chart {
        height: 0;
        overflow: hidden;
    }

    .chart {
        height: unset;
    }

    .head {
        display: block;

        .top {
            display: flex;
            align-items: center;
            margin: 16px 0;
            pointer-events: none;
            justify-content: flex-end;
            position: relative;
            margin-top: -56px;
            height: 32px;

            @media screen and (max-width: 1230px) {
                margin-top: 16px;
            }

            & * {
                pointer-events: auto;
            }

            time {
                font-size: 12px;
                color: var(--black-75);
                padding-right: 12px;
            }
        }

        .bot {
            margin: 32px 0 16px 0;
            display: flex;
            align-items: center;

            &>span {
                font: var(--font-demi-22);
                color: var(--black-90);
                flex: 1;
            }
        }
    }

    .title {
        font: var(--font-demi-22);
        color: var(--black-90);
        display: flex;
        align-items: center;
        min-height: 38px;

        span {
            flex: 1;
        }
    }

    .chart-box {
        background: var(--bg-02);
        margin-top: 20px;
        overflow: hidden;

        .creator-no-data img {
            width: 120px;
        }

        .type-box {
            display: flex;
            cursor: pointer;

            .type {
                width: 25%;
                padding: 24px 16px;
                background: white;
                border: 1px solid rgba(0, 0, 0, 0.08);

                &.selected {
                    background: var(--bg-02);
                    border: unset;
                }

                .text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    color: var(--black-75);
                }

                .value {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: var(--black-90);
                    display: flex;
                    align-items: center;

                    svg {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }
            }
        }

        .chart {
            width: auto;
            height: 300px;
            padding-bottom: 60px;
        }

    }

}
</style>