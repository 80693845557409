<template>
    <div class="ui-date-picker">
        <label>
            <i><svg-icon name="date" /></i>
            <span>{{ current_text }}:</span>

            <time>
                {{ display_date }}
                <DatePickerNext :value="custom_date" type="date" format="MMM DD, YYYY" range :clearable="false" @close="alertFn"
                    :shortcuts="shortcuts" :disabled="disabled" :disabled-date="cantPickDate" @change="calendarChange"
                    :popup-class="maxDate || minDate ? 'mx-datepicker-main-spec' : ''" />
            </time>

        </label>

    </div>
</template>

<script>
import DatePickerNext from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import moment from 'moment';

export default {
    name: 'DatePicker',

    components: {
        DatePickerNext,
        SvgIcon,
        Button,
    },

    computed: {
        display_date() {
            if (this.custom_date) {
                if (moment(this.custom_date[0]).format('YYYYMMDD') === moment(this.custom_date[1]).format('YYYYMMDD')) {
                    return moment(this.custom_date[0]).format('MMM DD, YYYY');
                }
                return `${moment(this.custom_date[0]).format('MMM DD, YYYY')} - ${moment(this.custom_date[1]).format('MMM DD, YYYY')}`;
            }
            return '';
        },
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        date_range: {
            type: Object,
            default: null,
        },

        maxDate: {
            type: String,
            default: null,
        },

        minDate: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            custom_date: null,
            current_text: 'Last 30 days',
            shortcuts: [
                {
                    text: 'Yesterday',
                    onClick() {
                        let start = moment().add(-1, 'days');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Yesterday';
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 7 days',
                    onClick() {
                        let start = moment().add(-7, 'days');
                        let end = moment().add(-1, 'days');
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 30 days',
                    onClick() {
                        let start = moment().add(-30, 'days');
                        let end = moment().add(-1, 'days');
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 3 months',
                    onClick() {
                        let start = moment().add(-3, 'month');
                        let end = moment().add(-1, 'days');
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 365 days',
                    onClick() {
                        let start = moment().add(-1, 'year');
                        let end = moment().add(-1, 'days');
                        return [start.toDate(), end.toDate()];
                    },
                },
            ],
            open:false,
        }
    },

    methods: {
        selectType(type) {
            this.selected_type = type;
            let start = null;
            let end = null;

            switch (type) {
                case 1:
                    start = moment().add(-1, 'days');
                    end = moment().add(-1, 'days');
                    break;

                case 2:
                    start = moment().add(-7, 'days');
                    end = moment().add(-1, 'days');
                    break;

                case 3:
                    start = moment().add(-30, 'days');
                    end = moment().add(-1, 'days');
                    break;

                case 4:
                    start = moment().add(-3, 'month');
                    end = moment().add(-1, 'days');
                    break;

                case 5:
                    start = moment().add(-1, 'year');
                    end = moment().add(-1, 'days');
                    break;

                case 6:
                    start = this.custom_date[0];
                    end = this.custom_date[1];
                    break;

                default:
                    break;
            }

            this.start_date = moment(start).toDate();
            this.end_date = moment(end).toDate();
            if (type < 6) this.is_show_select = false;

            // console.log({ start_date: this.start_date, end_date: this.end_date })

            this.$emit('update-date', { start_date: this.start_date, end_date: this.end_date });
        },

        calendarChange(date) {
            this.custom_date = date;
            this.start_date = date[0];
            this.end_date = date[1];
            this.$emit('update-date',
                { start_date: this.start_date, end_date: this.end_date }
            );

            this.setText();

        },

        setDate(date) {
            // console.log('setDate', date, [moment().toDate(date[0]), moment(date[1]).toDate()])
            this.start_date = date[0];
            this.end_date = date[1];

            this.custom_date = [moment(date[0]).toDate(), moment(date[1]).toDate()];
        },

        setText() {
            const i = Number(moment().add(-1, 'days').format('YYYYMMDD'));
            const e = Number(moment(this.custom_date[1]).format('YYYYMMDD'));

            if (i === e) {
                const diff = moment(this.custom_date[1]).diff(moment(this.custom_date[0]), 'days');
                const mdiff = moment(this.custom_date[1]).diff(moment(this.custom_date[0]), 'months');

                // console.log(mdiff, diff);

                if (diff === 0) {
                    this.current_text = 'Yesterday';
                } else if (diff === 6) {
                    this.current_text = 'Last 7 days';
                } else if (diff === 29) {
                    this.current_text = 'Last 30 days';
                } else if (mdiff === 2) {
                    this.current_text = 'Last 3 months';
                } else if (diff === 364) {
                    this.current_text = 'Last 365 days';
                } else {
                    this.current_text = 'Custom';
                }
            } else {
                this.current_text = 'Custom';
            }

        },

        cantPickDate(date) {
            if (this.maxDate && this.minDate) {
                let min = new Date(new Date(this.minDate).getTime() - 86400000).toISOString().slice(0, 10);
                return date < new Date(min) || date > new Date(this.maxDate);
            } else {
                return false;
            }
        },

        start() {
            if (this.maxDate || this.minDate) {
                this.shortcuts = null;
            }
        },

        alertFn(){
            this.$emit('close')
        }
    },

    mounted() {
        if (this.date_range) {
            this.custom_date = [moment(this.date_range.start_date).toDate(), moment(this.date_range.end_date).toDate()]
        } else {
            this.custom_date = [moment().add(-30, 'days').toDate(), moment().add(-1, 'days').toDate()];
        }

        this.setText();
        this.start();
    },
}
</script>
<style lang="scss">
.ui-date-picker {
    &>label {
        display: flex;
        align-items: center;
        border: 1px solid var(--border);
        height: 32px;
        border-radius: 6px;
        cursor: pointer;
        background-color: #fff;
        // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

        &:hover {
            border-color: var(--blue);
        }

        &>i {
            height: 14px;
            width: 14px;
            margin: 0 10px;

            svg {
                height: 100%;
                width: 100%;
                fill: var(--black-90);
            }
        }

        &>span {
            color: var(--black-60);
            font: var(--font-fg-12);
        }

        .mx-datepicker .mx-icon-calendar {
            display: none !important;
        }

        &>time {
            position: relative;
            color: var(--black-90);
            font: var(--font-fg-12);
            padding: 0 10px 0 5px;
        }

        .mx-datepicker .mx-input {
            color: var(--black-60);
            font: var(--font-fg-12);
            //position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            padding: 0;
            opacity: 0;
        }

        .mx-datepicker {
            width: auto;
        }

    }

}

.mx-datepicker-main {
    .mx-datepicker-sidebar {
        width: unset !important;
    }

    .mx-datepicker-content {
        margin-left: 120px;

        td:hover {
            background: #dbedfb;
        }
    }
}

.mx-datepicker-main-spec {
    .mx-datepicker-content {
        margin-left: 0px;
    }
}
</style>
