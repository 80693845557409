<template>
    <!-- <div class="ui-table-header" v-if="$slots.top">
        <slot name="top">
        </slot>
    </div> -->

    <table class="ui-table">
        <thead class="thead">
            <slot name="head">
            </slot>
        </thead>
        <tbody class="tbody">
            <slot name="body">
            </slot>
        </tbody>
        <tfoot class="tfoot" v-if="$slots.foot">
            <slot name="foot">
            </slot>
        </tfoot>
    </table>
</template>

<script>
export default {
    name: 'table-content',

    setup(props, { slots }) {
        const hasSlot = name => !!slots[name]
        return { hasSlot }
    }
    // props: {
    //     columns: {
    //         default: [],
    //         type: Array,
    //     },
    //     rows: {
    //         default: [],
    //         type: Array,
    //     },
    // },
};
</script>

<style lang="scss">
.ui-table {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    color: var(--black-90);

    &.is-loading {
        pointer-events: none;
        position: relative;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            height: 100%;
            width: 100%;
            background-color: rgba(#000, .45);
            content: '';
        }

        &::before {
            position: absolute;
            content: 'Loading...';
            color: white;
            left: 0%;
            width: 100%;
            text-align: center;
            top: 5%;
            z-index: 11;
            font-size: 14px;
        }
    }

    th {
        padding: 10px 12px;
        background: var(--bg-10);
        // color: var(--black-60);
        border: unset;
        // border-bottom: 1px solid var(--black-20);
        font: var(--font-fg-14);
        text-align: left;
        color: var(--black-90);

        .ui-checkbox {
            &.checked svg {
                fill: var(--black-90);
            }
        }

        .ico {
            display: flex;
            align-items: center;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-40);
                margin-left: 6px;
            }

            &.sort {
                cursor: pointer;

                svg {
                    height: 12px;
                    width: 12px;
                }

                &:hover {
                    color: var(--black-90);

                    svg {
                        fill: var(--black-90);
                    }

                    .svg-icon--question {
                        fill: var(--black-40);
                    }
                }

                &.asc,
                &.desc {
                    color: var(--dark-blue);

                    svg {
                        fill: var(--dark-blue);
                    }

                    .svg-icon--question {
                        fill: var(--dark-blue);
                    }
                }

                &.desc svg {
                    &.svg-icon--sort {
                        transform: rotate(-180deg);
                    }
                }

                .svg-icon--question:hover {
                    fill: var(--dark-blue);
                }
            }
        }
    }

    thead {
        position: sticky;
        top: var(--top);
        z-index: 3;

        &:after {
            height: 1px;
            content: '';
            display: block;
            background-color: var(--black-20);
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0px;
        }
    }

    tfoot {
        position: sticky;
        bottom: 0;
        z-index: 3;

        td {
            justify-content: center;
            padding: 10px 12px;
            background: var(--bg-10);
            border: unset;
            border-bottom: 1px solid var(--black-20);
            font: var(--font-fg-12);
        }

    }

    .up-down {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            display: block;
            height: 14px;
            width: 14px;
            margin-left: 8px;
            fill: var(--black-50);
            position: relative;
            transition: .3s ease transform;
            transform: rotate(0deg);
        }

        &.show svg {
            transform: rotate(-180deg);
        }
    }

    td {
        padding: 8px 12px;
        border: unset;
        border-bottom: 1px solid var(--black-20);
        vertical-align: middle;
        color: var(--black-90);

        &.social {
            &>span>span {
                //display: block;
                position: relative;
                height: 16px;
                line-height: 14px;
                padding-left: 20px;
                max-width: 180px;

                &:first-child {
                    margin-bottom: 8px;
                }

                svg {
                    width: 12px;
                    height: 12px;
                    fill: var(--black-75);
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
            }

            span {
                color: var(--black-75);

                &.link {
                    color: var(--dark-blue);

                    b {
                        font-size: 12px;
                        font-weight: 400;
                        color: var(--green);
                    }
                }
            }

            br {
                display: none;
            }
        }

        &.name,
        .name-text-box {

            .name-text{
                padding-bottom: 3px;
            }

            .email-box {
                display: flex;
                align-items: center;

                .link {
                    height: 14px;
                }

                svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    margin-top: 1px;
                }
            }

            .email-box-spec{
                margin-left: 24px;
            }

            br {
                display: none;
            }
        };

        .profile {
            display: flex;
            align-items: center;

            img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                object-fit: cover;
            }

            .name-text-box{
                padding-left: 4px;
                
                .name{
                    text-transform: capitalize;
                }
            }

            .email-box {
                margin-top: 3px;
            }
            
            span {
                //padding-left: 4px;
                color: var(--black-90);
                font-weight: 500;
            }
        }

        .status_text_time{
            margin: 3px 0 0;
        }

        .resend-link {
            color: var(--blue);
            cursor: pointer;
            display: block;
            margin: 3px 0 0;
        }

        .storefront-state {
            display: inline-flex;
            align-items: center;
            color: var(--black-90);
            text-transform: capitalize;



            &::before {
                height: 10px;
                width: 10px;
                content: '';
                display: block;
                border-radius: 5px;
                background-color: var(--yellow);
                margin-right: 5px;
            }

            &.class-100,
            &-active {

                &::before {
                    background-color: var(--green);
                }
            }

            &.class--10,
            &-declined {

                &::before {
                    background-color: var(--alert);
                }
            }

            &-not-available {

                &::before {
                    background-color: var(--gray-blue);
                }
            }
        }

        a {
            color: var(--dark-blue);

            &:hover {
                text-decoration: underline;
            }
        }

        .share {
            cursor: pointer;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-75);
                margin: 0 auto;
            }
        }
    }

    tr.error td {
        background-color: var(--bg-11) !important;
    }

    tbody tr:hover td {
        background-color: var(--bg-10);
    }

    tbody tr.selected td {
        background-color: var(--bg-02);
    }

    .error-item {
        font: var(--font-fg-12);
        color: var(--alert);
    }

    .text-ellipsis {
        white-space: nowrap;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }

    .ui-checkbox {
        color: var(--black-75);
        margin: 0 auto;
        display: block;
        height: 12px;
        width: 12px;

        svg {
            margin: 0;
            height: 100%;
            width: 100%;
            display: block;
            fill: var(--black-50);
        }

        &.checked svg {
            fill: var(--dark-blue);
        }
    }

    // strong {
    //     font: var(--font-demi-12);
    // }

    .is-new {
        display: block;
        font: var(--font-fg-10);
        color: #FF7036;
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            height: 12px;
            width: 12px;
            margin: 0 4px;
            cursor: pointer;

            svg {
                height: 100%;
                width: 100%;
                display: block;
                fill: var(--dark-blue);
            }
        }
    }
}</style>