<template>
    <div :class="classnames" @click="openPost()">

        <div class="show-card">
            <div :class="['img-box', (whichPost.reel || whichPost.story || whichPost.feed) && 'img-box-long']">
                <div class="icon">
                    <SvgIcon :name="data.social_platform === 'tikTok' ? 'tiktok-pure' : 'instagram'" />
                </div>
                <img :src="require('@/assets/images/Posts/' + data.thumbnail_url)" @error="imageError($event)"
                    alt="avatar" />
                <Badage class="img-bagage" v-if="badageText" :value="badageText" :type="'black'" :border="'4'" />
            </div>


            <div class="card">
                <div class="it date">
                    {{ $filters.momentFormat(data.date, 'll') }}
                    <span v-if="data.is_public">Live</span>
                    <span v-else class="unav">Unavilable</span>
                </div>
                <div class="it-vi avatar-box" v-if="creator_show">
                    <figure class="avatar">
                        <img v-if="$store.state.now_pick_creator.avatar" :src="getUserImage($store.state.now_pick_creator.avatar)"
                            @error="imageError($event)" />
                        <img v-else src="@/assets/images/user.png" />
                    </figure>
                    {{ $store.state.now_pick_creator.first_name }} {{ $store.state.now_pick_creator.last_name }}
                </div>
                <div class="it hr"></div>

                <div class="it it-vi" v-if="data.social_platform !== 'tikTok'">{{ Number(data.impressions).toLocaleString('en-US') }}<span>Impressions</span></div>
                <div class="it it-vi" v-if="data.social_platform !== 'tikTok'">{{ Number(data.reach).toLocaleString('en-US') }}<span>Reach</span></div>
                <div class="it it-vi views" v-if="!whichPost.img">{{ Number(data.views).toLocaleString('en-US') }}
                    <span>{{ whichPost.vedio && data.social_platform !== 'tikTok' ? 'Video Views' : 'Views' }}</span>
                </div>
                <div class="it it-vi eng">{{ Number(data.engagement).toLocaleString('en-US') }}<span>Engagement</span></div>
                <div class="it it-vi">{{ handleNumSpec(data.engagement_rate) !== '---' ?
        `${handleNumSpec(data.engagement_rate)}%` : data.engagement_rate }}<span>Engagement Rate</span></div>

                <div class="it icon-box">
                    <div class="icon-item" v-for="(v, k) in icon_list" :key="k">
                        <SvgIcon :name="v.icon" />
                        {{ v.value }}
                    </div>
                </div>
                <div class="it hr"
                    v-if="((!no_plus || data.is_brand_post) && $store.state.user_data.contract_status) || (!$store.state.user_data.contract_status && data.tags.length > 0)">
                </div>
                <div class="tagging-list-box">

                    <TagDropdown class="tag-drop tag-drop-samll"
                        v-if="!add_value.loading && (!no_plus || data.is_brand_post) && ($store.state.user_data.contract_status)"
                        :items="tag_select" :selected_items="data.tags" :icon_direction_type="'center'"
                        :direction_type="'left'" @update-select="updateTag" :key="tag_select.length" :icon="'plus'"
                        @editTag="editTag">
                        <div v-if="tag_select.length !== 0">
                            <div class="tag-button">
                                <Button :style="'border'" size="24" :color="'black-90'" @click.stop="editTag"> Edit Tags
                                    <svg-icon name="edit" />
                                </Button>
                            </div>
                        </div>
                    </TagDropdown>

                    <swiper class="it swiper-box label-box" :modules="modules" :slides-per-view="'auto'" ref="swiper"
                        :slides-per-group="3">
                        <swiper-slide v-for="(v, k) in data.tags" :key="k">
                            <Badage :value="v.text" :type="'gray'" />
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Badage from '@/components/ui/Badage.vue';
import Button from '@/components/ui/Button.vue';
import { ImageError } from '@/utils/SwitchFun';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import { Navigation } from 'swiper/modules';
import TagDropdown from '@/components/ui/TagDropdown.vue';
import { HandleNumSpec } from '@/utils/SwitchFun';

export default {
    name: 'creator-small-card',

    data() {
        return {
            add_value: {
                loading: false,
            }
        };
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },


    components: {
        SvgIcon,
        Badage,
        Button,
        Swiper,
        SwiperSlide,
        TagDropdown,
    },

    computed: {
        classnames() {
            return `post-item ${this.classname}`
        },
        icon_list() {
            let list = [
                { icon: 'card-like', value: Number(this.data.likes).toLocaleString('en-US') },
                { icon: 'card-repeat', value: Number(this.data.comments).toLocaleString('en-US') },
                { icon: 'card-mark', value: Number(this.data.saved).toLocaleString('en-US') },
                { icon: 'card-share', value: Number(this.data.shares).toLocaleString('en-US') },
            ]
            if (this.data.social_platform === 'tikTok' || this.whichPost.img) {
                return list.filter((e, k) => { return k !== 2 })
            }
            else if (this.data.social_platform !== 'tikTok' && this.whichPost.story) {
                return list.filter((e, k) => { return k <= 1 })
            }
            else {
                return list
            }
        },
        whichPost() {
            return {
                img: this.data.type === 1,
                vedio: this.data.type === 2,
                reel: this.now_ig_type === 1,
                story: false,
                feed: false,
            }
        },
        badageText() {
            if (this.whichPost.feed) {
                return 'Feed'
            } else if (this.whichPost.reel) {
                return 'Reel'
            } else if (this.whichPost.story) {
                return 'Story'
            } else {
                return '';
            }

        }
    },

    props: {
        classname: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {}
        },

        tag_select: {
            type: Array,
            default: {}
        },

        no_plus: {
            type: Boolean,
            default: false
        },
        now_ig_type:{
            type: Number,
            default: 0,//0=>post 1=>reels 2=>story
        },
        creator_show:{
            type: Boolean,
            default: false,
        }

    },
    methods: {
        imageError: ImageError,
        handleNumSpec: HandleNumSpec,

        getUserImage(avatar) {
            return require(`@/assets/images/user/${avatar}`);
        },

        openPost() {
            this.EventBus.emit('open-post-modal', { post: this.data, whichPost:this.whichPost,badageText:this.badageText });
        },

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        updateTag(item) {
            if (this.data.tags.filter((o) => o.id === item.id).length !== 0) {
                const index = this.data.tags.findIndex(tag => tag.id === item.id);
                this.data.tags.splice(index, 1);

                // console.log('remove => ' + index);
            } else {
                this.data.tags.push(item);
            }

            const tag_ids = this.data.tags.map((o) => o.id);

            // this.$ajax(`creator/update-social-post-tag`, {
            //     method: 'post',
            //     data: {
            //         post_ids: [this.data.id],
            //         tag_ids: tag_ids,
            //     }
            // });

        },

        editTag(obj) {
            this.$emit('edit-tag', obj);
        }
    },

    mounted() {
        this.EventBus.on('update-post-tags', (data) => {
            if (this.data.id === data.post_id) {
                this.data.tags = data.tags;
            }
        });

        this.EventBus.on('updated-all-post-tag', (tags) => {
            if (this.data.tags) this.data.tags.forEach((tag, tag_index) => {
                const index = tags.findIndex((o) => o.id === tag.id);
                if (index === -1) {
                    // remove this tag
                    this.data.tags.splice(tag_index, 1);
                }
            });
        });
    }

};
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}


.post-item {
    position: relative;
    border-radius: 4px;
    border: solid 1px var(--border);
    overflow: hidden;
    cursor: pointer;
    background-color: white;

    .icon {
        position: absolute;
        border-radius: 0 0 4px 0;
        background-color: var(--bg-01);
        width: 18px;
        height: 18px;
        @include flexCenter;
        justify-content: center;

        .svg-icon {
            width: 10px;
            height: 10px;
        }
    }

    img {
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;
        object-fit: cover;
    }

    .img-box-long img {
        aspect-ratio: 9/16;
    }

    .card {
        padding: 6px;
        color: var(--black-90);

        .it {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: unset;
            }
        }
    }

    .avatar-box {
        @include flexCenter();
        margin-bottom: 4px;

        .avatar {
            padding-right: 4px;

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid var(--black-40);
            }
        }
    }

    .name-box {
        @include flexCenter();
        font: var(--font-med-14);

        .avatar {
            margin-right: 4px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .date {
        color: var(--black-50);
        font-size: 10px;
        @include flexCenter();
        justify-content: space-between;

        span {
            color: var(--green);

            &.unav {
                color: var(--red);
            }
        }
    }

    .brand {
        color: var(--black-50);
        font: var(--font-med-12);
    }

    .hr {
        background-color: var(--border);
        height: 1px;
        width: 100%;
        border: 0;
    }

    .views {
        margin-bottom: 4px;
    }

    .it-vi {
        font: var(--font-med-14);

        span {
            margin-left: 4px;
            font: var(--font-med-10);
        }
    }

    .icon-box {
        @include flexCenter();
        align-items: center;

        .icon-item {
            @include flexCenter();
            margin-right: 12px;
            font: var(--font-med-10);

            .svg-icon {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                fill: var(--black-75);
            }
        }

    }

    .col {
        @include flexCenter();
        justify-content: flex-end;

        span {
            height: 24px;
            width: 24px;
            cursor: pointer;
            @include flexCenter();
            justify-content: center;
            position: relative;
            margin-left: 10px;
            border-radius: 2px;
            transition: all .2s ease-in-out;
            color: var(--black-90);

            &:hover {
                background-color: var(--bg-03);
            }

            svg {
                height: 10px;
                width: 10px;
                position: relative;
                transform: rotate(90deg);
            }

            &.swiper-button-next {
                svg {
                    transform: rotate(-90deg);
                }
            }

            &.swiper-button-disabled {
                cursor: not-allowed;
                color: var(--black-40);
                pointer-events: none;
            }
        }
    }

    .show-card {

        .img-box {
            position: relative;
        }

        .img-bagage {
            position: absolute;
            bottom: 15px;
            right: 8px;
        }
    }

    .label-box {
        //height: 76px;

        .swiper-slide {

            width: auto !important;
            min-width: 26px;
            margin-right: 6px;
            overflow: hidden;
            box-sizing: content-box;
        }

        .my-badges-iden {
            margin-right: 0;
            margin-bottom: 0;
            font: var(--font-med-12);
            color: var(--black-90);
            border-radius: 4px;
        }
    }

    .tag-drop-samll .ui-button {
        padding: 0 5px;
        height: 24px;
        border-radius: 6px;
        margin-right: 4px;
        border: solid 1px var(--border);

        svg {
            fill: var(--black-100);
        }
    }

    .ui-dropdown--inner .item-box {
        bottom: 26px;
    }

    .tagging-list-box {
        @include flexCenter();
    }

}
</style>
