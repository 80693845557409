<template>
    <router-view />
    <ProductModalSide />
    <CreatorModalSide />
    <CollectionProductSide />
    <PostModalSide />
    <Share />
    <notifications class="center-center" group="center" position="center center" />
    <notifications class="bottom-right" position="bottom right" />
</template>
<script>
import '@/assets/svg/alert.svg?sprite';
import '@/assets/svg/yes.svg?sprite';
import Share from '@/components/ui/Share.vue';
import ProductModalSide from '@/components/product/ProductModalSide.vue';
import CreatorModalSide from '@/components/creator/CreatorModalSide.vue';
import CollectionProductSide from '@/components/creator/CollectionProduct.vue';
import PostModalSide from '@/components/creator/PostModalSide.vue';

export default {
    watch: {

        '$route.params': {
            handler(params) {
                this.updateClass(params);
            },

            immediate: true,
        },
    },

    components: {
        Share,
        ProductModalSide,
        CreatorModalSide,
        CollectionProductSide,
        PostModalSide
    },

    methods: {
        updateClass(params) {
            let body_class = '';
            if (this.$route.name !== 'home') body_class = 'not-home ';
            if (typeof this.$route.name === 'string') body_class = body_class + 'page-' + this.$route.name;
            document.body.className = body_class;
            if (this.$store.state.disabled_scroll) {
                this.$store.state.disabled_scroll = false;
            } else {
                window.scrollTo(0, 0);
            }
        },
    },

    mounted() {
        if (window.localStorage.getItem('user')) {
            this.$store.commit('setUser', JSON.parse(window.localStorage.getItem('user')));
        }
    },

}
</script>

<style lang="scss">
@import '@/assets/sass/_fonts.scss';
@import '@/assets/sass/_reset.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    min-height: 100vh;
    background-color: var(--dark-blue);
}

body {
    font-family: var(--font);
    line-height: 1.42857;

    /* Font smoothing */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: normal;
    background-color: var(--dark-blue);
    min-height: 100%;

    &.disabled-scroll {
        overflow: hidden !important;
    }
}

* {
    box-sizing: border-box;
}

:root {
    --font: 'Franklin Gothic Book', sans-serif;
    --font-fg: 'Franklin Gothic', sans-serif;
    --font-demi: 'Franklin Gothic Demi', sans-serif;
    --font-med: 'Franklin Gothic Medium', sans-serif;
    --font-ms: 'Montserrat', sans-serif;
    --font-dm: 'DM Serif Display', sans-serif;

    --font-fg-10: 500 10px/14px var(--font-fg);
    --font-fg-12: 500 12px/16px var(--font-fg);
    --font-fg-14: 500 14px/18px var(--font-fg);
    --font-fg-16: 500 16px/20px var(--font-fg);
    --font-fg-18: 500 18px/22px var(--font-fg);
    --font-fg-20: 500 20px/24px var(--font-fg);
    --font-fg-22: 500 22px/26px var(--font-fg);
    --font-fg-24: 500 24px/28px var(--font-fg);
    --font-fg-26: 500 26px/30px var(--font-fg);
    --font-fg-28: 500 28px/32px var(--font-fg);
    --font-fg-30: 500 30px/34px var(--font-fg);
    --font-fg-32: 500 32px/36px var(--font-fg);

    --font-demi-10: 500 10px/14px var(--font-demi);
    --font-demi-12: 500 12px/16px var(--font-demi);
    --font-demi-14: 500 14px/18px var(--font-demi);
    --font-demi-16: 500 16px/20px var(--font-demi);
    --font-demi-18: 500 18px/22px var(--font-demi);
    --font-demi-20: 500 20px/24px var(--font-demi);
    --font-demi-22: 500 22px/26px var(--font-demi);
    --font-demi-24: 500 24px/28px var(--font-demi);
    --font-demi-26: 500 26px/30px var(--font-demi);
    --font-demi-28: 500 28px/32px var(--font-demi);
    --font-demi-30: 500 30px/34px var(--font-demi);
    --font-demi-32: 500 32px/36px var(--font-demi);
    --font-demi-34: 500 34px/38px var(--font-demi);
    --font-demi-36: 500 36px/40px var(--font-demi);
    --font-demi-38: 500 38px/42px var(--font-demi);
    --font-demi-40: 500 40px/44px var(--font-demi);

    --font-med-10: 500 10px/14px var(--font-med);
    --font-med-12: 500 12px/16px var(--font-med);
    --font-med-14: 500 14px/18px var(--font-med);
    --font-med-16: 500 16px/20px var(--font-med);
    --font-med-18: 500 18px/22px var(--font-med);
    --font-med-20: 500 20px/24px var(--font-med);
    --font-med-22: 500 22px/26px var(--font-med);
    --font-med-24: 500 24px/28px var(--font-med);
    --font-med-26: 500 26px/30px var(--font-med);
    --font-med-28: 500 28px/32px var(--font-med);
    --font-med-30: 500 30px/34px var(--font-med);
    --font-med-32: 500 32px/36px var(--font-med);

    --sidebar-width: 310px;
    --collapsed-sidebar-width: 74px;

    --white: #fff;
    --red: #ED1C24;
    --dark-red: #D83232;
    --alert: #F22028;
    --yellow: #efb008;
    --blue: #007BFF;
    --dark-blue: #232A60;
    --gray-blue: #94A3B8;
    --dark-gray-blue: #475569;
    --green: #00A870;
    --pink: #FE7A82;
    --orange: #F15A29;
    --neureal: #0F1A2A;
    --light-neutral: #F1F4F9;
    --light-yellow: #FFF5D5;
    --border: #E2E8F0;
    --light-blue: #D8F4FF;
    --light-green: #E8FCF1;

    --bg-01: #fff;
    --bg-02: #F6F8FC;
    --bg-03: #f2f2f2;
    --bg-04: #FEFAF6;
    --bg-05: #FDFAF8;
    --bg-06: #FEF8F2;
    --bg-07: #FBCEBF;
    --bg-08: #F6F0E7;
    --bg-09: #FAA378;
    --bg-10: #FAFAFA;
    --bg-11: #FFEBEB;

    --black-100: #000000;
    --black-90: #333333;
    --black-75: #58595B;
    --black-60: #666666;
    --black-50: #828282;
    --black-40: #BDBDBD;
    --black-20: #E0E0E0;
    --black-10: #F2F2F2;

    // shadow https://www.figma.com/file/OZYT5l0h3pSMxgtBh722ff/%F0%9F%A7%A7-%E3%80%90v-2.0.0%E3%80%91-Design-Library---Basic?node-id=891%3A10101
    --bot-1dp: 0px 1px 2px 0px rgba(0, 0, 0, .1);
    --bot-2dp: 0px 2px 4px 0px rgba(0, 0, 0, .1);
    --bot-4dp: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    --bot-6dp: 0px 6px 8px 0px rgba(0, 0, 0, .1);

    --top-1dp: 0px -1px 2px 0px rgba(0, 0, 0, .1);
    --top-2dp: 0px -2px 4px 0px rgba(0, 0, 0, .1);
    --top-4dp: 0px -4px 8px 0px rgba(0, 0, 0, .1);
    --top-6dp: 0px -6px 8px 0px rgba(0, 0, 0, .1);

    --right-1dp: 1px 0px 2px 0px rgba(0, 0, 0, .1);
    --right-2dp: 2px 0px 4px 0px rgba(0, 0, 0, .1);
    --right-4dp: 4px 0px 8px 0px rgba(0, 0, 0, .1);
    --right-6dp: 6px 0px 8px 0px rgba(0, 0, 0, .1);

    --left-1dp: -1px 0px 2px 0px rgba(0, 0, 0, .1);
    --left-2dp: -2px 0px 4px 0px rgba(0, 0, 0, .1);
    --left-4dp: -4px 0px 8px 0px rgba(0, 0, 0, .1);
    --left-6dp: -6px 0px 8px 0px rgba(0, 0, 0, .1);

    --soc-twit: #1DA1F2;
    --soc-fb: #1877F2;
    --soc-fb2001: #3B5998;
    --soc-yt: #FF0000;
    --soc-google: #0F9D58;
    --soc-instagram-color: $instagram;
    --soc-instagram: linear-gradient(215.8deg, #A844A1 18.29%, #AB429A 23.73%, #B43C88 31.48%, #C33269 40.67%, #D7243F 50.72%, #D7243E 50.95%, #F9A326 79.54%, #F9DD26 94.95%);
    --soc-snapchat: #FFFC00;
    --soc-pinterest2001: #BD081C;
    --soc-pinterest: #E60023;
    --soc-tiktok: #333333;
    --soc-apple: #000000;
}

@mixin flexCenter(){
    display: flex;
    align-items: center;
}

.wrap {
    height: 100vh;
    display: flex;

    &>.content {
        flex: 1;
        background-color: white;
    }

    a {
        color: var(--blue);
        text-decoration: none;
        transition: .15s color ease;

        &:hover {
            color: var(--dark-blue);
        }
    }
}

.black-90-text {
    color: var(--black-90);
}

// .notifications-bottom-right {

// }


.vue-notification-group {
    z-index: 100000000 !important;

    &.bottom-right {
        right: 8px !important;
        bottom: 8px !important;
        width: 320px !important;
        top: auto !important;
        transform: unset !important;

    }

    &.center-center {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50% !important;
        width: auto !important;
        max-width: 320px;
    }

    .vue-notification-wrapper {
        overflow: unset;
    }

    .vue-notification {
        font-size: 14px;
        padding: 16px;
        background: #A5E1BF;
        border: none !important;
        font-family: var(--font);
        line-height: 1.42857;
        color: var(--dark-blue);
        border-radius: 6px;
        box-shadow: 2px 4px 30px 1px rgba(0, 0, 0, 0.15);
    }

    .vue-notification:has(.err-notify) {
        background: var(--alert);
        color: var(--white);

        svg {
            fill: var(--white);
        }
    }

    .notification-content,
    .notification-title {
        display: flex;
        align-items: center;
        font: var(--font-fg-12);

        svg {
            height: 16px;
            width: 16px;
            margin-right: 8px;
            display: block;
            fill: var(--dark-blue);
        }
    }
}

.hashtag-modal {
    .tag-header {
        padding: 0 24px;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--black-20);
        font-size: 16px;
        color: var(--black-90);
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -16px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-50);
            }

            &:hover svg {
                fill: var(--dark-blue);
            }
        }
    }

    & > .ui-modal--container {
        width: 720px;
    }

    .ui-hashtag {
        max-height: 80vh;
        overflow: auto;
        padding: 0 24px;
    }
}

.import-modal {

    & > .ui-modal--container {
        width: 375px;
    }

    .tag-header{
        display: flex;
        align-items: center;
        font: var(--font-demi-20);
        color: var(--black-90);

        .ico-title{
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
    }

    .tag-menus {
        display: flex;
        padding: 24px 16px 0;

        & + .ui-import {
            padding-top: 12px;
        }


        li {
            margin-right: 10px;

            .ui-button.disabled {
                cursor: pointer;
                pointer-events: auto;
                border-color: var(--border);
                color: var(--black-75);
            }
        }
    }
}

.add-creator-modal {
    .ui-modal--container {
        width: 960px;
    }

    header {
        padding: 0 24px;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--black-20);
        font-size: 16px;
        color: var(--black-90);
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -16px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-50);
            }

            &:hover svg {
                fill: var(--dark-blue);
            }
        }
    }

    .box-wrap {
        padding: 24px;
        display: flex;
        align-items: flex-start;
        max-height: 80vh;
        flex: 1;

        .add-partner {
            padding: 0;
            flex: 1;

            .form-wrap {
                margin: 0;
                background-color: var(--bg-02);
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23232A60' stroke-width='2' stroke-dasharray='8%2c 12' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");

                // &.upload-form {
                //     margin-top: 24px;
                // }
            }
        }

        &>ul {
            width: 360px;
            margin-left: 24px;

            li {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

#app {

    .tippy-box {
        background-color: var(--dark-gray-blue);
        border-radius: 6px;
        font-size: 12px;
        // padding: 0 !important;
    }

    .tippy-arrow {
        color: var(--dark-gray-blue);
    }

    // .tippy-box[data-theme~=light] {

    // }

}

span.flex {
    display: flex;
    align-items: center;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
    opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.alert-modal {
    z-index: 9999 !important;

    .ui-modal--container {
        width: 330px;
    }


    .box {
        padding: 24px;

        header {
            font: var(--font-demi-20);
            color: var(--black-90);
            letter-spacing: 1px;
            margin-bottom: 8px;
        }

        p {
            font-size: 16px;
            color: var(--black-60);
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 32px 0 0 !important;


            .ui-button {
                min-width: 70px;
                text-align: center;
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }

}

.overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.sticker-tab-box {
    @include flexCenter();
    margin-top: 24px;
    margin-bottom: 16px;

    .ui-button {
        margin-right: 16px;
        border-radius: 6px !important;
        display: inline-flex;
        align-items: center;
        border: 1px solid white !important;
        // background-color: var(--black-10);
        line-height: 1;
        box-sizing: border-box;
        text-wrap: nowrap;

        &:hover {
            background-color: var(--bg-02);
            border-color: var(--bg-02) !important; 
        }

        &.actived,
        &.actived:hover {
            border: 1px solid var(--dark-blue) !important;
            color: var(--dark-blue);
            background-color: white;
        }

        svg {
            margin-right: 6px;
        }
    }
}

.swiper{
    width: 100%;
}

.analytics-big-box{
    width: calc(100% - 320px);
    //min-width: 1200px;
}


.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .ui-modal--container,
.modal-leave-to .ui-modal--container {
    transform: scale(1.1);
}
</style>

